import React, { Component } from "react";
import AppContext from "../../AppContext";
import { Accordion, Badge, Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import ReactSelect from 'react-select';
import InputMask from 'react-input-mask';
import moment from "moment";
import AppMap from "../componentes/AppMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import SigningPad from "../componentes/SigningPad";
import ConfirmacaoModal from "./ConfirmacaoModal";

class AtribuicaExecucaoForm extends Component {
    static contextType = AppContext;

    uploadRef = React.createRef();

    state = {

        id: this.props.atribuicao?.id ?? "",
        datahora_empenho: this.props.atribuicao?.datahora_empenho ? moment(this.props.atribuicao.datahora_empenho) : moment(),
        datahora_agendamento: this.props.atribuicao?.datahora_agendamento ? moment(this.props.atribuicao.datahora_agendamento) : moment(),
        datahora_execucao: this.props.atribuicao?.datahora_execucao ? moment(this.props.atribuicao.datahora_execucao) : moment(),
        datahora_lancamento: this.props.atribuicao?.datahora_lancamento ? moment(this.props.atribuicao.datahora_lancamento) : null,
        aprovado: this.props.atribuicao?.aprovado ?? "",
        cancelado: this.props.atribuicao?.cancelado ?? "",
        observacao: this.props.atribuicao?.observacao ?? "",

        observacao_protocolo: this.props.atribuicao?.Protocolo?.observacao_protocolo ?? "",
        observacao_certificado: this.props.atribuicao?.Protocolo?.observacao_certificado ?? "",

        responsavel_nome: this.props.atribuicao?.responsavel_nome ?? "",
        responsavel_rg: this.props.atribuicao?.responsavel_rg ?? "",
        responsavel_contato: this.props.atribuicao?.responsavel_contato ?? "",
        responsavel_funcao: this.props.atribuicao?.responsavel_funcao ?? "",

        Protocolo: this.props.atribuicao?.Protocolo ?? { Cidade: {}, Bairro: {} },
        Cadastro: this.props.atribuicao?.Protocolo?.Cadastro ?? {},
        Unidade: this.props.atribuicao?.Protocolo?.Unidade ?? {},
        Usuario: this.props.atribuicao?.Usuario ?? {},
        Funcao: this.props.atribuicao?.Funcao ?? {},

        ProtocoloId: this.props.atribuicao?.ProtocoloId ?? "",

        Pendencias: this.props.atribuicao?.Pendencias ?? [],

        Anexos: this.props.atribuicao?.Anexos ?? [],
        AnexosProtocolo: [],

        Atribuicoes: [],
        AtribuicoesOriginal: [],
        pendenciaKey: undefined,
        exigencias: [],
        urls: []
    }

    downloadAnexo(anexo) {
        new Promise(resolve => {
            if (anexo.id) {
                this.context.request("GET", `/anexos/${anexo.id}/download`).then(resolve)
            } else {
                const leitor = new FileReader();
                leitor.onload = event => resolve(new Blob([event.target.result], { type: anexo.arquivo.type }));
                leitor.onerror = () => this.context.addToast({ header: "Erro", body: "Anexo não encontrado neste protocolo." });
                leitor.readAsArrayBuffer(anexo.arquivo);
            }
        }).then(blob => this.download(blob, anexo.nome));
    }

    copiarPendencias(atribuicao) {
        this.context.request(`GET`, `/atribuicoes/${atribuicao.id}/pendencias`)
            .then(pendencias => this.setState(state => ({
                Pendencias: [
                    ...state.Pendencias,
                    ...pendencias.map(pendencia => ({
                        ExigenciaId: pendencia.ExigenciaId,
                        quantidade: pendencia.quantidade,
                        observacao: pendencia.observacao,
                    }))
                ]
            })));
    }

    onUploadAnexo(event) {
        const { files } = event.target;
        this.setState(state => ({
            Anexos: [
                ...state.Anexos,
                ...Array.from(files).map(file => ({
                    AtribuicaoId: this.state.id,
                    nome: file.name,
                    descricao: "Registro de Atribuição",
                    arquivo: file
                }))]
        }));
    }

    onSign(blob) {
        this.setState(state => ({
            Anexos: [
                ...state.Anexos,
                {
                    AtribuicaoId: this.state.id,
                    nome: "Assinatura.png",
                    descricao: "Assinatura do Responsável",
                    arquivo: blob
                }]
        }));
    }

    deleteAnexo(anexo) {
        if (window.confirm("Deseja realmente excluir este anexo?")) {
            if (anexo.id) {
                this.context.request("DELETE", "/anexos/" + anexo.id)
                    .then(() => {
                        this.setState(state => ({ Anexos: state.Anexos.filter(a => a !== anexo) }))
                    });
            } else {
                this.setState(state => ({ Anexos: state.Anexos.filter(a => a !== anexo) }));
            }
        }
    }

    componentDidMount() {

        const { ProtocoloId } = this.state;

        this.context.load([
            new Promise((resolve, reject) => this.context.request("GET", `/exigencias/options`).then(exigencias => this.setState({ exigencias }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${ProtocoloId}/atribuicoes`).then(Atribuicoes => this.setState({ Atribuicoes: Atribuicoes.filter(a => a.id !== this.state.id) }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${ProtocoloId}/atribuicoesOriginal`).then(AtribuicoesOriginal => this.setState({ AtribuicoesOriginal }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${ProtocoloId}/anexos`).then(AnexosProtocolo => this.setState({ AnexosProtocolo }, resolve)).catch(reject)),
        ]);
    }

    salvar(event) {
        event.preventDefault();
        this.submit(false, false);
    }
    finalizar() {
        this.submit(true, false);
    }
    cancelar() {
        this.submit(true, true);
    }

    submit(finalizado, cancelado) {

        if (this.state.Pendencias.find(p => !p.ExigenciaId)) {
            window.alert("Verifique se todas as Pendências possuem exigência");
            return;
        }

        this.context.openModal({
            title: "Confirmação",
            body: <ConfirmacaoModal
                onCancel={() => this.context.closeModal()}
                onConfirm={() => {

                    this.context.closeModal();

                    const {
                        id,
                        datahora_execucao,
                        responsavel_nome,
                        responsavel_rg,
                        responsavel_contato,
                        responsavel_funcao,
                        observacao,
                        observacao_protocolo,
                        observacao_certificado,
                        Pendencias,
                        Anexos
                    } = this.state;

                    const acao = finalizado ? (cancelado ? 'cancelar' : 'finalizar') : 'salvar';

                    this.context.request("PUT", `/atribuicoes/${id}/${acao}`, {
                        datahora_execucao,
                        observacao,
                        cancelado,
                        responsavel_nome,
                        responsavel_rg,
                        responsavel_contato,
                        responsavel_funcao,
                        observacao_protocolo,
                        observacao_certificado,
                        Pendencias
                    })
                        .then(async atribuicao => {
                            await Promise.all(Anexos.filter(anexo => !anexo.id).map(anexo => {
                                const data = new FormData();
                                data.append("AtribuicaoId", atribuicao.id);
                                data.append("nome", anexo.nome);
                                data.append("descricao", anexo.descricao);
                                data.append("arquivo", anexo.arquivo);
                                return this.context.request("POST", "/anexos", data);
                            }));
                        })
                        .then(() => {
                            this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                            this.props.onExit();
                        })
                        .catch(error => {
                            this.context.addToast({ header: "Erro", body: "Falha na gravação dos dados: " + error.toString() });
                        })
                }} />
        });
    }

    downloadAnexo(anexo) {
        new Promise(resolve => {
            if (anexo.id) {
                this.context.request("GET", `/anexos/${anexo.id}/download`).then(resolve)
            } else {
                const leitor = new FileReader();
                leitor.onload = event => resolve(new Blob([event.target.result], { type: anexo.arquivo.type }));
                leitor.onerror = this.context.addToast({ header: "Erro", body: "Anexo não encontrado neste protocolo." });
                leitor.readAsArrayBuffer(anexo.arquivo);
            }
        }).then(blob => this.download(blob, anexo.nome));
    }

    download(blob, filename) {
        return new Promise(resolve => {
            const url = URL.createObjectURL(blob);
            this.setState(state => ({ urls: [...state.urls, url] }), () => {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                resolve();
            });
        });
    }

    componentWillUnmount() {
        this.state.urls.forEach(url => URL.revokeObjectURL(url));
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    <FontAwesomeIcon icon={Icons.faStar} />&nbsp;Formulário de Execução de Atribuição
                </h2>
                <hr />
                <h4 className="mb-3">
                    Dados Gerais
                </h4>
                <Row>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Nº do Protocolo</b></Form.Label>
                            <Form.Control disabled value={this.state.ProtocoloId.toString().padStart(6, '0')} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Tipo de Protocolo</b></Form.Label>
                            <Form.Control disabled value={this.state.Protocolo.tipo} />

                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>CNPJ/CPF</b></Form.Label>
                            <Form.Control disabled value={this.state.Cadastro.cnpj_cpf} />
                        </Form.Group>
                    </Col>
                    <Col lg={8} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Nome do Estabelecimento (conforme certificado de licenciamento)</b></Form.Label>
                            <Form.Control disabled value={this.state.Protocolo.nome_estabelecimento} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Unidade de Atendimento</b></Form.Label>
                            <Form.Control disabled value={this.state.Unidade.sigla} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Endereço Completo</b></Form.Label>
                            <Form.Control rows={4} as="textarea" disabled value={this.state.Protocolo.endereco} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Cidade</b></Form.Label>
                            <Form.Control disabled value={this.state.Protocolo.Cidade?.nome} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Bairro</b></Form.Label>
                            <Form.Control disabled value={this.state.Protocolo.Bairro?.nome} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Georreferenciamento</b></Form.Label>
                            <div>
                                <AppMap latitude={this.state.Protocolo.latitude} longitude={this.state.Protocolo.longitude} />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Latitude</b></Form.Label>
                            <Form.Control disabled type="number" step="0.000001" value={this.state.Protocolo.latitude} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Longitude</b></Form.Label>
                            <Form.Control disabled type="number" step="0.000001" value={this.state.Protocolo.longitude} />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    Dados da Atribuição
                </h4>
                <Row>
                    <Col lg={8} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Encarregado</b></Form.Label>
                            <Form.Control disabled value={this.state.Usuario.nome} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Função</b></Form.Label>
                            <Form.Control disabled value={this.state.Funcao.descricao} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Data/Hora do Agendamento</b></Form.Label>
                            <Form.Control disabled value={this.state.datahora_agendamento.format("LLL")} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Data/Hora do Lançamento</b></Form.Label>
                            <Form.Control type="datetime-local" disabled value={moment().format("YYYY-MM-DDTHH:mm")} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Data/Hora da Execução</b></Form.Label>
                            <Form.Control required type="datetime-local" value={this.state.datahora_execucao?.format("YYYY-MM-DDTHH:mm")} onChange={e => this.setState({ datahora_execucao: e.target.value ? moment(e.target.value) : null })} />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    Anexos do Protocolo
                </h4>
                <Row>
                    <Col lg={12} className="mb-1">
                        <Table responsive hover size="sm">
                            <thead>
                                <tr>
                                    <th>Nome do Arquivo</th>
                                    <th>Descrição</th>
                                    <th style={{ width: 1 }} className="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.AnexosProtocolo.map((anexo, key) =>
                                    <tr key={key}>
                                        <td>{anexo.nome}</td>
                                        <td>{anexo.descricao}</td>
                                        <td className="text-nowrap text-center">
                                            <Button variant="primary" size="sm" onClick={() => this.downloadAnexo(anexo)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Button>
                                        </td>
                                    </tr>)}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <h4 className="mb-3">
                    Pendências Levantadas
                </h4>
                <Row>
                    <Col lg={12} className="mb-1">
                        <Accordion className="mb-2" activeKey={this.state.pendenciaKey} onSelect={pendenciaKey => this.setState({ pendenciaKey })}>
                            {this.state.Pendencias.map((pendencia, key) =>
                                <Accordion.Item key={key} eventKey={key}>
                                    <Accordion.Header>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div className="flex-grow-1 text-nowrap" style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                                {this.state.exigencias.find(option => option.value === pendencia.ExigenciaId)?.label ?? "(Selecione uma exigência)"}
                                            </div>
                                            <div className="text-nowrap">
                                                <Button size="sm" className="me-1" variant="danger" onClick={event => { event.stopPropagation(); this.setState(state => ({ Pendencias: state.Pendencias.filter(ae => ae !== pendencia) })) }}>
                                                    <FontAwesomeIcon icon={Icons.faTrash} /> Excluir
                                                </Button>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={9}>
                                                <Form.Group>
                                                    <Form.Label className="required"><b>Exigência</b></Form.Label>
                                                    <ReactSelect
                                                        options={this.state.exigencias.filter(option => (option.tipo === this.state.Funcao.tipo && !option.desativado) || option.value === pendencia.ExigenciaId)}
                                                        value={this.state.exigencias.find(option => option.value === pendencia.ExigenciaId)}
                                                        onChange={option => this.setState(state => ({ Pendencias: state.Pendencias.map(ae => ae === pendencia ? { ...pendencia, ExigenciaId: option.value } : ae) }))}
                                                        placeholder="Selecione uma exigência..."
                                                        noOptionsMessage={() => "Sem opções"}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label className="required"><b>Quantidade</b></Form.Label>
                                                    <Form.Control required type="number" min="1" step="1" value={pendencia.quantidade} onChange={event => this.setState(state => ({ Pendencias: state.Pendencias.map(ae => ae === pendencia ? { ...pendencia, quantidade: event.target.value } : ae) }))} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group>
                                                    <Form.Label><b>Observação</b></Form.Label>
                                                    <Form.Control as="textarea" value={pendencia.observacao} onChange={event => this.setState(state => ({ Pendencias: state.Pendencias.map(ae => ae === pendencia ? { ...pendencia, observacao: event.target.value } : ae) }))} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>)}
                        </Accordion>
                        <Button className="w-100" variant="warning" onClick={() => this.setState(state => ({ pendenciaKey: state.Pendencias.length, Pendencias: [...state.Pendencias, { quantidade: 1, observacao: ""}] }))}>
                            <FontAwesomeIcon icon={faPlus} /> Adicionar Pendência
                        </Button>
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    Outras Atribuições do Protocolo
                </h4>
                <Row>
                    <Col lg={12} className="mb-1">
                        {(!this.state.Atribuicoes.length && "Nenhuma atribuição registrada.") || <Accordion>
                            {this.state.Atribuicoes.map((atribuicao, key) =>
                                <Accordion.Item key={key} eventKey={key + 1}>
                                    <Accordion.Header>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div>
                                                {atribuicao.Funcao?.descricao} - {atribuicao.Usuario?.nome}<br />
                                                Agendamento: {moment(atribuicao.datahora_agendamento).format("DD/MM/YYYY HH:mm")} -
                                                Execução: {atribuicao.datahora_execucao ? moment(atribuicao.datahora_execucao).format("DD/MM/YYYY HH:mm") : "Pendente"}
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <Badge className={`me-1 bg-${{
                                                    Pendente: "info",
                                                    Atrasado: "warning",
                                                    Cancelado: "secondary",
                                                    Aprovado: "success",
                                                    Reprovado: "danger",
                                                }[atribuicao.status]}`}>
                                                    {atribuicao.status}
                                                </Badge>
                                                <Button size="sm" className="me-1" variant="success" onClick={event => { event.stopPropagation(); this.copiarPendencias(atribuicao) }}>
                                                    <FontAwesomeIcon icon={Icons.faCopy} />
                                                    &nbsp;Copiar Exigências
                                                </Button>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {(!atribuicao.Pendencias.length && "Nenhuma exigência registrada.") || <Table>
                                            <thead>
                                                <tr>
                                                    <th>Exigência</th>
                                                    <th style={{ width: "1%" }}>Quantidade</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {atribuicao.Pendencias.map((pendencia, key) =>
                                                    <tr key={key}>
                                                        <td>
                                                            <b>{pendencia.Exigencia?.descricao}</b><br />
                                                            <small>{pendencia.observacao}</small>
                                                        </td>
                                                        <td>{pendencia.quantidade}</td>
                                                    </tr>)}
                                            </tbody>
                                        </Table>}
                                        {atribuicao.observacao && <Form.Group>
                                            <Form.Label><b>Observação</b></Form.Label>
                                            <Form.Control as="textarea" value={atribuicao.observacao} disabled readOnly />
                                        </Form.Group>}
                                    </Accordion.Body>
                                </Accordion.Item>)}
                        </Accordion>}
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    Atribuições do Protocolo Original
                </h4>
                <Row>
                    <Col lg={12} className="mb-1">
                        {(!this.state.AtribuicoesOriginal.length && "Nenhuma atribuição registrada.") || <Accordion>
                            {this.state.AtribuicoesOriginal.map((atribuicao, key) =>
                                <Accordion.Item key={key} eventKey={key + 1}>
                                    <Accordion.Header>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div>
                                                {atribuicao.Funcao?.descricao} - {atribuicao.Usuario?.nome}<br />
                                                Agendamento: {moment(atribuicao.datahora_agendamento).format("DD/MM/YYYY HH:mm")} -
                                                Execução: {atribuicao.datahora_execucao ? moment(atribuicao.datahora_execucao).format("DD/MM/YYYY HH:mm") : "Pendente"}
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <Badge className={`me-1 bg-${{
                                                    Pendente: "info",
                                                    Atrasado: "warning",
                                                    Cancelado: "secondary",
                                                    Aprovado: "success",
                                                    Reprovado: "danger",
                                                }[atribuicao.status]}`}>
                                                    {atribuicao.status}
                                                </Badge>
                                                <Button size="sm" className="me-1" variant="success" onClick={event => { event.stopPropagation(); this.copiarPendencias(atribuicao) }}>
                                                    <FontAwesomeIcon icon={Icons.faCopy} />
                                                    &nbsp;Copiar Exigências
                                                </Button>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {(!atribuicao.Pendencias.length && "Nenhuma exigência registrada.") || <Table>
                                            <thead>
                                                <tr>
                                                    <th>Exigência</th>
                                                    <th style={{ width: "1%" }}>Quantidade</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {atribuicao.Pendencias.map((pendencia, key) =>
                                                    <tr key={key}>
                                                        <td>
                                                            <b>{pendencia.Exigencia?.descricao}</b><br />
                                                            <small>{pendencia.observacao}</small>
                                                        </td>
                                                        <td>{pendencia.quantidade}</td>
                                                    </tr>)}
                                            </tbody>
                                        </Table>}
                                        {atribuicao.observacao && <Form.Group>
                                            <Form.Label><b>Observação</b></Form.Label>
                                            <Form.Control as="textarea" value={atribuicao.observacao} disabled readOnly />
                                        </Form.Group>}
                                    </Accordion.Body>
                                </Accordion.Item>)}
                        </Accordion>}
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    Anexos da Atribuição
                </h4>
                <Row>
                    <Col lg={12} className="mb-1">
                        <Table responsive hover size="sm">
                            <thead>
                                <tr>
                                    <th>Nome do Arquivo</th>
                                    <th>Descrição</th>
                                    <th style={{ width: 1 }} className="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Anexos.map((anexo, key) =>
                                    <tr key={key}>
                                        <td>{anexo.nome}</td>
                                        <td>{anexo.descricao}</td>
                                        <td className="text-nowrap text-center">
                                            <Button variant="primary" size="sm" onClick={() => this.downloadAnexo(anexo)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Button>
                                            &nbsp;
                                            <Button variant="danger" size="sm" onClick={() => this.deleteAnexo(anexo)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </td>
                                    </tr>)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3}>
                                        <Button size="sm" variant="success" className="form-control" onClick={() => this.uploadRef.current.click()}>
                                            <FontAwesomeIcon icon={faPlus} /> Adicionar Arquivos
                                        </Button>
                                        <input type="file" multiple className="d-none" ref={this.uploadRef} onChange={e => this.onUploadAnexo(e)} />
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    Observações
                </h4>
                <Row>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Observações da Atribuição</b></Form.Label>
                            <Form.Control as="textarea" rows={4} value={this.state.observacao} onChange={e => this.setState({ observacao: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Observações do Protocolo</b><br /><small><i className="text-danger">As alterações neste campo irão sobrescrever as informações do protocolo</i></small></Form.Label>
                            <Form.Control rows={4} as="textarea" value={this.state.observacao_protocolo} onChange={e => this.setState({ observacao_protocolo: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Observações do Certificado</b><br /><small><i className="text-danger">As alterações neste campo irão sobrescrever as informações do protocolo</i></small></Form.Label>
                            <Form.Control rows={4} as="textarea" value={this.state.observacao_certificado} onChange={e => this.setState({ observacao_certificado: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    Dados do Responsável no Local
                </h4>
                <Row>
                    <Col lg={9} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Nome do Responsável</b></Form.Label>
                            <Form.Control required value={this.state.responsavel_nome} onChange={e => this.setState({ responsavel_nome: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>RG do Responsável</b></Form.Label>
                            <Form.Control value={this.state.responsavel_rg} onChange={e => this.setState({ responsavel_rg: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Contato do Responsável</b></Form.Label>
                            <InputMask className="form-control" mask="(99) 99999-9999" maskChar="_" value={this.state.responsavel_contato} onChange={e => this.setState({ responsavel_contato: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Função do Responsável</b></Form.Label>
                            <Form.Control value={this.state.responsavel_funcao} onChange={e => this.setState({ responsavel_funcao: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="mb-1">
                        <Form.Label><b>Assinatura do Responsável</b></Form.Label>
                        <SigningPad width={600} height={200} onConfirm={blob => this.onSign(blob)} />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="d-flex align-items-end">
                        <Button variant="secondary" className="me-auto" onClick={() => this.props.onExit()}>
                            <FontAwesomeIcon icon={Icons.faFastBackward} /> Voltar
                        </Button>
                        {!this.state.datahora_lancamento && this.state.Usuario?.id === this.context.usuario.id && <>
                        <Button className="ms-1" variant="dark" onClick={(event) => this.salvar(event)}>
                            <FontAwesomeIcon type="submit" icon={Icons.faFloppyDisk} /> Salvar Rascunho
                        </Button>
                        {!this.state.Pendencias.length &&
                            <Button className="ms-1" variant="warning" onClick={() => this.cancelar()}>
                                <FontAwesomeIcon icon={Icons.faBan} /> Cancelar Atribuição
                            </Button>}
                        <Button className="ms-1" variant={this.state.Pendencias.length > 0 ? "danger" : "success"} onClick={() => this.finalizar()}>
                            <FontAwesomeIcon icon={this.state.Pendencias.length > 0 ? Icons.faTimes : Icons.faCheck} /> {this.state.Pendencias.length > 0 ? "Reprovar" : "Aprovar"} Atribuição
                        </Button></>}
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default AtribuicaExecucaoForm;