import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import OcupacaoForm from "../forms/OcupacaoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

class OcupacaoList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/ocupacoes/" + action.id).then(ocupacao =>
                    this.context.setContent(<OcupacaoForm ocupacao={ocupacao} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/ocupacoes/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faHouse} /> Pesquisa de Ocupações</div>}
            filters={[
                { name: "descricao", label: "Ocupação", span: 6 },
                { name: "risco", label: "Risco", span: 6, options: ["Nulo", "Baixo", "Médio", "Alto"] },
                { name: "divisao", label: "Divisão", span: 8 },
            ]}
            endpoint="/ocupacoes"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<OcupacaoForm />)} />;
    }
}

export default OcupacaoList;