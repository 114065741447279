import { Component } from "react";
import AppContext from "../AppContext";
import { Container } from "react-bootstrap";

class MessagesPage extends Component {

    static contextType = AppContext;

    render() {
        return <Container>
            <h2>Mensagens</h2>
        </Container>;
    }
}

export default MessagesPage;