import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import CidadeForm from "../forms/CidadeForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";

class CidadeList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/cidades/" + action.id).then(cidade =>
                    this.context.setContent(<CidadeForm cidade={cidade} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/cidades/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faGlobeAmericas} /> Pesquisa de Cidades e Bairros</div>}
            filters={[
                { name: "nome", label: "Cidade", span: 8 },
                { name: "uf", label: "UF", span: 4 },
                { name: "risp_nome", label: "Nome da RISP", span: 4 },
                { name: "risp_numero", label: "Número da RISP", span: 4 },
            ]}
            endpoint="/cidades"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<CidadeForm />)} />;
    }
}

export default CidadeList;