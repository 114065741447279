import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import AvisoList from "../lists/AvisoList";
import HomePage from "../../HomePage";
import AdministracaoPage from "../AdministracaoPage";
import AppEditor from '../../componentes/AppEditor';
import moment from 'moment';

class AvisoForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.aviso?.id ?? null,
        titulo: this.props.aviso?.titulo ?? "",
        texto: this.props.aviso?.texto ?? "",
        data_atualizacao: this.props.aviso?.data_atualizacao ? moment(this.props.aviso?.data_atualizacao) : moment(),
        data_vencimento: this.props.aviso?.data_vencimento ? moment(this.props.aviso?.data_vencimento) : moment().add({days: 30}),
        UsuarioId: this.props.aviso?.UsuarioId ?? this.context.usuario.id,
        Usuario: this.props.aviso?.Usuario ?? { nome_curto: this.context.usuario.nome_curto },
    }

    submit(e) {
        e.preventDefault();
        
        const method = this.state.id ? "PUT" : "POST";
        const url = `/avisos/${this.state.id ?? ''}`;
        const data = this.state;
        this.context.request(method, url, data)
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<AvisoList />, [{ title: "Início", content: <HomePage /> }, { title: "Administração", content: <AdministracaoPage /> }, { title: "Avisos" }]);
            })
            .catch(error => {
                this.context.addToast({ header: "Erro", body: "Houve um erro na operação: " + error.toString() });
            });
    }

    render() {
        return <Container>
            <Form className="app-form mt-3" onSubmit={(e) => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Aviso
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<AvisoList />, [{ title: "Início", content: <HomePage /> }, { title: "Administração", content: <AdministracaoPage /> }, { title: "Avisos" }])}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Título</b></Form.Label>
                            <Form.Control required value={this.state.titulo} onChange={e => this.setState({ titulo: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Texto</b></Form.Label>
                            <AppEditor value={this.state.texto} onChange={texto => this.setState({ texto })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Usuário</b></Form.Label>
                            <Form.Control disabled value={this.state.Usuario?.nome ?? this.context.usuario.nome} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Atualização</b></Form.Label>
                            <Form.Control disabled type="date" value={this.state.data_atualizacao.format("YYYY-MM-DD")} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Vencimento</b></Form.Label>
                            <Form.Control required type="date" min={this.state.data_atualizacao.format("YYYY-MM-DD")} value={this.state.data_vencimento.format("YYYY-MM-DD")} onChange={e => this.setState({ data_vencimento: moment(e.target.value) })} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default AvisoForm;