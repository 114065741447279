import { Component } from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import Calendar from 'react-calendar';
import AppContext from '../../AppContext';
import moment from "moment";

import 'react-calendar/dist/Calendar.css';

class AppCalendar extends Component {

    static contextType = AppContext;

    openModal = (date, events) => {
        this.context.openModal({
            props: {},
            title: "Eventos do dia " + moment(date).format("DD/MM/YYYY"),
            body: <ListGroup>
                {events.map(({ text, status, onClick }, key) => {
                    return <ListGroup.Item key={key} onClick={onClick ?? void 0} role='button'>
                        {text} {status && <Badge
                            className={`me-1 bg-${{
                                Pendente: "info",
                                Atrasado: "warning",
                                Cancelado: "secondary",
                                Aprovado: "success",
                                Reprovado: "danger",
                            }[status]}`}>
                            {status}
                        </Badge>}
                        
                    </ListGroup.Item>
                })}
            </ListGroup>
        });
    };

    format(date) {
        return date.toISOString().slice(1, 10).split('-').reverse().join('/');
    }

    render() {
        return (
            <Calendar
                className="app-calendar"
                formatShortWeekday={(locale, date) => moment(date).format("ddd")}
                tileClassName={({ date }) => this.props.events.find(event => moment(event.date).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY")) ?
                    "bg-primary" : ""
                }
                onChange={date => {
                    const todayEvents = this.props.events.filter(event => moment(event.date).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY"));
                    if (todayEvents.length > 0) {
                        this.openModal(date, todayEvents)
                    }
                }}
            />
        );
    }
};

export default AppCalendar;
