import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import UsuarioForm from "../forms/UsuarioForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

class UsuarioList extends Component {

    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Ver Detalhes":
                this.context.request("GET", "/usuarios/" + action.id).then(usuario =>
                    this.context.openModal({
                        title: "Usuário", body: <UsuarioForm usuario={usuario} onCancel={() => this.context.closeModal()} onSave={() => {
                            this.context.closeModal();
                            callback();
                        }} />
                    })
                )
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faUsers} /> Pesquisa de Usuários</div>}
            filters={[
                { name: "nome", label: "Nome", span: 8, order: "nome" },
                { name: "$Unidade.sigla$", label: "Unidade", span: 4, order: "Unidade.sigla" },
                { name: "login", label: "Login", span: 4, order: "login" },
                { name: "email", label: "Email", span: 4, order: "email" }
            ]}
            endpoint="/usuarios"
            onAction={(action, callback) => this.onAction(action, callback)}
        />;
    }
}

export default UsuarioList;