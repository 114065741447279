import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ModeloList from "../lists/ModeloList";
import AppEditor from "../../componentes/AppEditor";

class ModeloForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.modelo?.id ?? null,
        descricao: this.props.modelo?.descricao ?? "",
        titulo: this.props.modelo?.titulo ?? "",
        subtitulo: this.props.modelo?.subtitulo ?? "",
        modelo: this.props.modelo?.modelo ?? "",
        bordas: this.props.modelo?.bordas ?? "",
        dizeres: this.props.modelo?.dizeres ?? "",
        notas: this.props.modelo?.notas ?? "",
        validade: this.props.modelo?.validade ?? "0"        
    }

    submit(event) {

        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/modelos/${this.state.id ?? ''}`;

        const {
            id,
            descricao,
            titulo,
            subtitulo,
            modelo,
            bordas,
            dizeres,
            notas,
            validade,
        } = this.state;

        this.context.request(method, url, {
            id,
            descricao,
            titulo,
            subtitulo,
            modelo,
            bordas,
            dizeres,
            notas,
            validade,
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<ModeloList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Modelo de Certificado
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<ModeloList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={9} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Descrição</b></Form.Label>
                            <Form.Control required value={this.state.descricao} onChange={e => this.setState({ descricao: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Validade</b></Form.Label>
                            <Form.Control required type="number" min="0" step="1" value={this.state.validade} onChange={e => this.setState({ validade: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Título</b></Form.Label>
                            <Form.Control required value={this.state.titulo} onChange={e => this.setState({ titulo: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Subtítulo</b></Form.Label>
                            <Form.Control required value={this.state.subtitulo} onChange={e => this.setState({ subtitulo: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Modelo</b></Form.Label>
                            <Form.Control required value={this.state.modelo} onChange={e => this.setState({ modelo: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Bordas</b></Form.Label>
                            <Form.Control required value={this.state.bordas} onChange={e => this.setState({ bordas: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Dizeres</b></Form.Label>
                            <AppEditor value={this.state.dizeres} onChange={value => this.setState({ dizeres: value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Notas</b></Form.Label>
                            <AppEditor value={this.state.notas} onChange={value => this.setState({ notas: value })} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default ModeloForm;