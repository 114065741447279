import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ModalidadeList from "../lists/ModalidadeList";

class ModalidadeForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.modalidade?.id ?? null,
        descricao: this.props.modalidade?.descricao ?? "",
        tipo: this.props.modalidade?.tipo ?? "",
        execucao: this.props.modalidade?.execucao ?? false,
        projeto: this.props.modalidade?.projeto ?? false,
        instrucao: this.props.modalidade?.instrucao ?? false,
    }

    submit(event) {

        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/modalidades/${this.state.id ?? ''}`;

        const {
            id,
            descricao,
            tipo,
            execucao,
            projeto,
            instrucao
        } = this.state;

        this.context.request(method, url, {
            id,
            descricao,
            tipo,
            execucao: tipo === "Pessoa Física" ? execucao : false,
            projeto: tipo === "Pessoa Física" ? projeto : false,
            instrucao: tipo === "Pessoa Física" ? instrucao : false
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<ModalidadeList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Modalidade de Credenciamento
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<ModalidadeList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={8} className="mb-3">
                        <Form.Group>
                            <Form.Label className="required"><b>Descrição</b></Form.Label>
                            <Form.Control required value={this.state.descricao} onChange={e => this.setState({ descricao: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-3">
                        <Form.Group>
                            <Form.Label className="required"><b>Tipo de Pessoa</b></Form.Label>
                            <Form.Select required value={this.state.tipo} onChange={e => this.setState({ tipo: e.target.value })}>
                                <option value="">Selecione...</option>
                                <option>Pessoa Física</option>
                                <option>Pessoa Jurídica</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <Form.Check checked={this.state.execucao} onChange={e => this.setState({ execucao: e.target.checked })} label={<b>Serviços de Execução e Instalação de Medidas de Segurança</b>} />
                        </Col>
                        <Col lg={12} className="mb-3">
                            <Form.Check checked={this.state.projeto} onChange={e => this.setState({ projeto: e.target.checked })} label={<b>Elaboração de Projetos de Segurança contra Incêndio e Pânico</b>} />
                        </Col>
                        <Col lg={12} className="mb-3">
                            <Form.Check checked={this.state.instrucao} onChange={e => this.setState({ instrucao: e.target.checked })} label={<b>Ensino, Instrução e Formação de Profissionais</b>} />
                        </Col>
                    </Row>
                </Row>
            </Form >
        </Container >;
    }
}

export default ModalidadeForm;