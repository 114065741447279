import { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import ReactSelect from 'react-select';
import VinculoList from "./VinculoList";
import InputMask from 'react-input-mask';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faFloppyDisk, faSearch } from "@fortawesome/free-solid-svg-icons";

class VinculoForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.vinculo?.id ?? null,

        VinculanteId: this.props.vinculo?.VinculanteId ?? "",
        VinculadoId: this.props.vinculo?.VinculadoId ?? "",
        ModalidadeId: this.props.vinculo?.ModalidadeId ?? "",
        datahora_cadastro: this.props.vinculo?.datahora_cadastro ? moment(this.props.vinculo?.datahora_cadastro) : moment(),
        datahora_confirmacao: this.props.vinculo?.datahora_confirmacao ? moment(this.props.vinculo?.datahora_confirmacao) : null,
        datahora_revogacao: this.props.vinculo?.datahora_revogacao ? moment(this.props.vinculo?.datahora_revogacao) : null,
        Vinculante: this.props.vinculo?.Vinculante ?? null,
        Vinculado: this.props.vinculo?.Vinculado ?? null,

        modalidades: [],
        vinculante_cnpjcpf: this.props.vinculo?.Vinculante?.cnpj_cpf ?? "",
        vinculado_cnpjcpf: this.props.vinculo?.Vinculado?.cnpj_cpf ?? "",
        vinculante_tipo: this.props.vinculo?.Vinculante?.tipo ?? "",
        vinculado_tipo: this.props.vinculo?.Vinculado?.tipo ?? ""
    }

    searchVinculante(event) {
        event?.preventDefault();
        this.context.request(
            "GET",
            `/cadastros/vinculante/?cnpj_cpf=${this.state.vinculante_cnpjcpf}`,
            null,
            () => {
                this.context.addToast({ header: "Não encontrado", body: "Cadastro não encontrado!" });
            })
            .then(Vinculante => this.setState({ Vinculante, VinculanteId: Vinculante.id }))
    }

    componentDidMount() {
        if (this.context.usuario.classe === "cadastro" && !this.state.id) {
            this.setState({
                vinculante_tipo: this.context.usuario.tipoPessoa,
                vinculante_cnpjcpf: this.context.usuario.cnpj_cpf
            }, () => this.searchVinculante());
        }
        if (this.state.vinculado_cnpjcpf) {
            this.searchVinculado();
        }
    }

    searchVinculado(event) {
        event?.preventDefault();
        this.context.request("GET", `/cadastros/vinculado/?cnpj_cpf=${this.state.vinculado_cnpjcpf}`,
            null,
            () => {
                this.context.addToast({ header: "Não encontrado", body: "Cadastro não encontrado!" });
            })
            .then(Vinculado => this.setState({
                Vinculado,
                VinculadoId: Vinculado.id,
                modalidades: Vinculado.Certificados?.map(certificado => ({
                    label: `${certificado.Modalidade?.descricao} - Validade: ${moment(certificado.data_validade).format("DD/MM/YYYY")}`,
                    value: certificado.Modalidade?.id,
                    data_validade: certificado.data_validade,
                    data_cassacao: certificado.data_cassacao
                })) ?? []
            }))
    }

    submit() {
        const {
            VinculanteId,
            VinculadoId,
            ModalidadeId,
        } = this.state;

        if (!VinculanteId || !VinculadoId || !ModalidadeId) {
            this.context.addToast({ header: "Campos Obrigatórios", body: "Preencha todos os campos para gravar os dados." });
            return;
        }

        this.context.request("POST", `/vinculos`, {
            VinculanteId,
            VinculadoId,
            ModalidadeId,
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<VinculoList />);
            });
    }

    render() {
        return <Container>
            <h2 className="d-flex">
                Fomulário de Vínculo
                <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<VinculoList />)}>
                    <FontAwesomeIcon icon={faFastBackward} /> Voltar
                </Button>
                {!this.props.vinculo?.id &&
                    <Button variant="primary" className="me-2" onClick={() => this.submit()}>
                        <FontAwesomeIcon icon={faFloppyDisk} /> Gravar
                    </Button>}
            </h2>
            <hr />
            <Form onSubmit={event => this.searchVinculante(event)}>
                <Row>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Tipo de Vinculante</b></Form.Label>
                            <Form.Select required disabled={this.state.Vinculante} value={this.state.vinculante_tipo} onChange={e => this.setState({ vinculante_tipo: e.target.value })}>
                                <option value="">Selecione um tipo...</option>
                                <option>Pessoa Física</option>
                                <option>Pessoa Jurídica</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>CNPJ/CPF do Vinculante</b></Form.Label>
                            <InputGroup>
                                <InputMask disabled={this.state.Vinculante || !this.state.vinculante_tipo} className="form-control" mask={this.state.vinculante_tipo === "Pessoa Jurídica" ? "99.999.999/9999-99" : "999.999.999-99"} maskChar={'_'} required value={this.state.vinculante_cnpjcpf} onChange={e => this.setState({ vinculante_cnpjcpf: e.target.value })} />
                                <Button type="submit" disabled={this.state.Vinculante}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Nome/Razão Social do Vinculante</b></Form.Label>
                            <Form.Control required disabled value={this.state.Vinculante?.nome_razaosocial ?? ""} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Form onSubmit={event => this.searchVinculado(event)}>
                <Row>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Tipo de Vinculado</b></Form.Label>
                            <Form.Select disabled={this.state.Vinculado || this.state.vinculado_tipo} required value={this.state.vinculado_tipo} onChange={e => this.setState({ vinculado_tipo: e.target.value })}>
                                <option value="">Selecione um tipo...</option>
                                <option>Pessoa Física</option>
                                <option>Pessoa Jurídica</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>CNPJ/CPF do Vinculado</b></Form.Label>
                            <InputGroup>
                                <InputMask disabled={this.state.Vinculado || !this.state.vinculado_tipo} className="form-control" mask={this.state.vinculado_tipo === "Pessoa Jurídica" ? "99.999.999/9999-99" : "999.999.999-99"} maskChar={'_'} required value={this.state.vinculado_cnpjcpf} onChange={e => this.setState({ vinculado_cnpjcpf: e.target.value })} />
                                <Button type="submit" disabled={this.state.Vinculado}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Nome/Razão Social do Vinculado</b></Form.Label>
                            <Form.Control required disabled value={this.state.Vinculado?.nome_razaosocial ?? ""} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col lg={12} className="mb-1">
                    <Form.Group>
                        <Form.Label className="required"><b>Credenciamento do Vinculado</b></Form.Label>
                        <ReactSelect
                            options={this.state.modalidades}
                            isOptionDisabled={option => moment(option.data_validade) < moment() || option.data_cassacao}
                            value={this.state.modalidades.find(option => moment(option.data_validade) >= moment() && !option.data_cassacao && option.value === this.state.ModalidadeId)}
                            onChange={option => this.setState({ ModalidadeId: option.value ?? null })}
                            placeholder="Selecione um credenciamento válido..."
                            noOptionsMessage={() => "Sem opções"}
                            required
                            isDisabled={this.props.vinculo?.id || !this.state.Vinculado}
                        />
                    </Form.Group>
                </Col>
                <Col lg={4} className="mb-1">
                    <Form.Group>
                        <Form.Label><b>Data/Hora do Cadastro</b></Form.Label>
                        <Form.Control disabled value={this.state.datahora_cadastro.format("LLL")} />
                    </Form.Group>
                </Col>
                <Col lg={4} className="mb-1">
                    <Form.Group>
                        <Form.Label><b>Data/Hora da Confirmação</b></Form.Label>
                        <Form.Control disabled value={this.state.datahora_confirmacao?.format("LLL") ?? ""} />
                    </Form.Group>
                </Col>
                <Col lg={4} className="mb-1">
                    <Form.Group>
                        <Form.Label><b>Data/Hora da Revogação</b></Form.Label>
                        <Form.Control disabled value={this.state.datahora_revogacao?.format("LLL") ?? ""} />
                    </Form.Group>
                </Col>
            </Row>
        </Container >;
    }
}

export default VinculoForm;