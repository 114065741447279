import React, { Component } from "react";
import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


class ValoresModal extends Component {

    render() {
        return <Container>
            <Row>
                <Col lg={12} className="mb-3">
                    <ListGroup>
                        {this.props.valores.map(({tipo, valor}, key) =>
                        <ListGroup.Item key={key}>
                            <b>{tipo}:</b> R$ {valor.toFixed(2).toString().replace('.', ',')}
                        </ListGroup.Item>)}
                    </ListGroup>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" lg={{ offset: 4, span: 4 }}>
                    <Button className="form-control" variant="light" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={faTimes} /> Fechar
                    </Button>
                </Col>
            </Row>
        </Container>
    }
}

export default ValoresModal;