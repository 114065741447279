import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import AdministracaoPage from "../AdministracaoPage";
import AvisoForm from "../forms/AvisoForm";
import HomePage from "../../HomePage";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AvisoList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/avisos/" + action.id).then(aviso =>
                    this.context.setContent(<AvisoForm aviso={aviso} />, [{ title: "Início", content: <HomePage /> }, { title: "Administração", content: <AdministracaoPage /> }, { title: "Avisos", content: <AvisoList /> }, { title: "Editar Aviso nº " + action.id }])
                )
                break;
            case "Renovar":
                if (window.confirm("Deseja realmente renovar o item com ID " + action.id + "?"))
                    this.context.request("PATCH", "/avisos/" + action.id).finally(callback);
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/avisos/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faExclamationTriangle} /> Pesquisa de Avisos</div>}
            filters={[
                { name: "titulo", label: "Título", span: 6 },
                { name: "texto", label: "Texto", span: 6 },
                { name: "data_atualizacao", label: "Atualização", span: 4, type: "date" },
                { name: "data_vencimento", label: "Vencimento", span: 4, type: "date" },
            ]}
            endpoint="/avisos"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<AvisoForm />, [{ title: "Início", content: <HomePage /> }, { title: "Administração", content: <AdministracaoPage /> }, { title: "Avisos", content: <AvisoList /> }, { title: "Novo Aviso" }])}
        />;
    }
}

export default AvisoList;