import { Component, useEffect } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faKey, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppToasts from "../AppToasts";
import AppModal from "../AppModal";

const UUID = props => {
    const { uuid } = useParams();
    useEffect(() => props.onChange(uuid), [uuid, props]);
}

const Navigate = props => {
    const navigate = useNavigate();
    useEffect(() => props.onChange(url => navigate(url)), [navigate, props]);
}

class RecoverForm extends Component {

    static contextType = AppContext;

    state = { uuid: "", senha: "", senha2: "", navigate: () => undefined }

    async submit(event) {
        event.preventDefault();
        
        const { uuid, senha, senha2 } = this.state;

        const captcha = await this.context.getCaptcha();

        if (!uuid) {
            window.alert("UUID não configurado corretamente!");
        } if (senha !== senha2) {
            window.alert("As senhas devem ser iguais!");
        } else if (senha.length < 8) {
            window.alert("A senha deve possuir ao menos 8 caracteres!");
        } else if (!/[\d]/.test(senha)) {
            window.alert("A senha deve possuir ao menos 1 dígito!");
        } else if (!/[^\d]/.test(senha)) {
            window.alert("A senha deve possuir ao menos 1 letra!");
        } else {
            this.context.request("POST", "/cadastros/setPassword", { uuid, senha, captcha })
                .then(() => {
                    this.context.addToast({ header: "Sucesso", body: "Senha recuperada com sucesso! Realize o login com o usuário e senha informados." });
                    this.state.navigate("/");
                })
        }
    }

    render() {
        return <Container className="pt-3">
            <UUID onChange={uuid => this.setState({ uuid })} />
            <Navigate onChange={navigate => this.setState({ navigate })} />
            <Form onSubmit={event => this.submit(event)}>
                <Row>
                    <Col lg={{ offset: 2, span: 8 }}>
                        <h3>Recuperação de Senha</h3>
                        <hr />
                        <div className="card card-body bg-light mb-3">
                            Digite uma nova senha abaixo, com no mínimo 8 caracteres, 1 letra e 1 dígito.
                        </div>
                        <Form.Group className="mb-3">
                            <Form.Label><b>Senha</b></Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                                <Form.Control required type="password" value={this.state.senha} onChange={event => this.setState({ senha: event.target.value })} placeholder="Digite a senha" />
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                                <Form.Control required type="password" value={this.state.senha2} onChange={event => this.setState({ senha2: event.target.value })} placeholder="Digite novamente" />
                            </InputGroup>
                        </Form.Group>
                        <div className="d-flex justify-content-between">
                            <Link to="/" as="button" className="btn btn-secondary">
                                <FontAwesomeIcon icon={faFastBackward} /> Voltar
                            </Link>
                            <Button variant="primary" type="submit">
                                <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
            <AppToasts />
            <AppModal />
        </Container>;
    }
}

export default RecoverForm;