import { faCheck, faEraser, faFileSignature, faSign, faSigning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component, createRef } from "react";
import { Button, Col, Row } from "react-bootstrap";

class SigningPad extends Component {

    canvasRef = createRef();
    canvasContext = null;

    state = {
        drawing: false,
        x0: 0, y0: 0, x1: 0, y1: 0,
        confirmado: false,
        url: null
    }

    componentDidMount() {
        this.canvasContext = this.canvasRef.current.getContext('2d');
    }

    startDraw(event) {

        event.preventDefault();

        const x0 = event.pageX - event.target.offsetLeft;
        const y0 = event.pageY - event.target.offsetTop;
        const x1 = event.pageX - event.target.offsetLeft;
        const y1 = event.pageY - event.target.offsetTop;
        this.setState({ drawing: true, x0, y0, x1, y1 });
    }

    draw(event) {
        
        event.preventDefault();

        this.setState(state => {
            if (state.drawing) {
                const x2 = event.pageX - event.target.offsetLeft;
                const y2 = event.pageY - event.target.offsetTop;
                const x1 = state.x1;
                const y1 = state.y1;
                const x0 = state.x0;
                const y0 = state.y0;

                const xm = x1 + (x1 - x0) / 4;
                const ym = y1 + (y1 - y0) / 4;

                this.canvasContext.beginPath();
                this.canvasContext.moveTo(state.lastX, state.lastY);
                //this.canvasContext.lineTo(lastX, lastY);
                this.canvasContext.bezierCurveTo(x1, y1, xm, ym, x2, y2);
                this.canvasContext.strokeStyle = '#000';
                this.canvasContext.lineWidth = 4;
                this.canvasContext.lineCap = 'round'; // Define a ponta da linha como redonda
                this.canvasContext.shadowBlur = 2; // Define a ponta da linha como redonda
                this.canvasContext.shadowColor = 'black'; // Define a ponta da linha como redonda

                this.canvasContext.stroke();
                return { x0: x1, y0: y1, x1: x2, y1: y2 }
            } else return {};
        });
    }

    finishDraw(event) {
        event.preventDefault();

        this.setState({
            drawing: false
        });
    }

    erase() {
        this.canvasContext.clearRect(0, 0, this.canvasRef.current.width, this.canvasRef.current.height);
    }

    confirm() {
        this.canvasRef.current.toBlob(blob => {
            this.setState({url: URL.createObjectURL(blob), confirmado: true}, () => this.props.onConfirm(blob));
        }, 'image/png');
    }

    componentWillUnmount(){
        if (this.state.url) URL.revokeObjectURL(this.state.url);
    }

    render() {
        return <Row>
            <Col className="d-flex justify-content-center mb-3">
                {this.state.url?
                <img alt="assinatura" src={this.state.url} width={this.props.width} height={this.props.height} /> :
                <canvas
                    ref={this.canvasRef}
                    width={this.props.width}
                    height={this.props.height}
                    onPointerDown={(event) => this.startDraw(event)}
                    onPointerMove={(event) => this.draw(event)}
                    onPointerUp={(event) => this.finishDraw(event)}
                    onPointerLeave={(event) => this.finishDraw(event)}
                    style={{ border: "dotted 1px black", cursor: 'crosshair', touchAction: "none" }}
                />}
            </Col>
            {!this.state.url? <Col className="d-flex flex-column justify-content-center mb-3">
                <Button className="form-control mb-3" size="lg" variant="secondary" onClick={() => this.erase()}>
                    <FontAwesomeIcon icon={faEraser} /> Limpar</Button>
                <Button className="form-control mb-3" size="lg" variant="primary" onClick={() => this.confirm()}>
                    <FontAwesomeIcon icon={faFileSignature} /> Confirmar Assinatura</Button>
            </Col>:null}
        </Row>
    }
}

export default SigningPad;