import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import MedidaForm from "../forms/MedidaForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFireExtinguisher } from "@fortawesome/free-solid-svg-icons";

class MedidaList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/medidas/" + action.id).then(medida =>
                    this.context.setContent(<MedidaForm medida={medida} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/medidas/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faFireExtinguisher} /> Pesquisa de Medidas de Proteção</div>}
            filters={[
                { name: "descricao", label: "Medida", span: 8 }
            ]}
            endpoint="/medidas"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<MedidaForm />)} />;
    }
}

export default MedidaList;