import { Component } from "react";
import AppContext from "../AppContext";
import Datatable from "./componentes/Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import AtribuicaoExecucaoForm from "./atribuicoes/AtribuicaoExecucaoForm";
import AtribuicaoRelatorioForm from "./atribuicoes/AtribuicaoRelatorioForm";

class NotificationsPage extends Component {
    static contextType = AppContext;

    state = {
        urls: []
    }

    download(blob, filename) {
        return new Promise(resolve => {
            const url = URL.createObjectURL(blob);
            this.setState(state => ({ urls: [...state.urls, url] }), () => {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                resolve();
            });
        });
    }


    onAction(action, callback) {
        switch (action.name) {
            case "Ver Detalhes":
            case "Executar":
                this.context.request("GET", `/atribuicoes/${action.id}/execucao`).then(atribuicao =>
                    this.context.setContent(<AtribuicaoExecucaoForm atribuicao={atribuicao}
                        onExit={() => this.context.setContent(<NotificationsPage />)}
                    />));
                break;
            case "Preencher Relatório":
                this.context.request("GET", `/atribuicoes/${action.id}/execucao`).then(atribuicao =>
                    this.context.setContent(<AtribuicaoRelatorioForm atribuicao={atribuicao}
                        onExit={() => this.context.setContent(<NotificationsPage />)}
                    />));
                break;
            case "Baixar Relatório":
                this.context.request("GET", `/anexos/${action.AnexoRelatorioId}/download`)
                    .then(blob => this.download(blob, `Relatório-${action.id.toString().padStart(6, '0')}.pdf`));
                break;
            case "Baixar Notificação":
                this.context.request("GET", `/atribuicoes/${action.id}/notificacao`)
                    .then(blob => this.download(blob, `Notificação-${action.id.toString().padStart(6, '0')}.pdf`));
                break;
            case "Baixar Auto de Infração":
                this.context.request("GET", `/atribuicoes/${action.id}/auto`)
                    .then(blob => this.download(blob, `Auto-${action.id.toString().padStart(6, '0')}.pdf`));
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faStar} /> Minhas Atribuições</div>}
            filters={[
                { name: "ProtocoloId", label: "Protocolo", span: 3 },
                { name: "$Funcao.descricao$", label: "Função", span: 9 },
                { name: "datahora_agendamento", label: "Agendamento Inicial", span: 3, type: "date", op: "gte" },
                { name: "datahora_agendamento", label: "Agendamento Final", span: 3, type: "date", op: "lte" },
            ]}
            endpoint={`/usuarios/${this.context.usuario.id}/atribuicoes`}
            onAction={(action, callback) => this.onAction(action, callback)} />;
    }
}

export default NotificationsPage;