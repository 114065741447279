import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ExigenciaList from "../lists/ExigenciaList";
import ReactSelect from "react-select";


class ExigenciaForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.exigencia?.id ?? null,
        descricao: this.props.exigencia?.descricao ?? "",
        codigo: this.props.exigencia?.codigo ?? "",
        norma: this.props.exigencia?.norma ?? "",
        tipo: this.props.exigencia?.tipo ?? "",
        desativado: this.props.exigencia?.desativado ?? false,
        InfracaoId: this.props.exigencia?.InfracaoId ?? null,

        infracoes: []

    }

    componentDidMount() {
        this.context.request("GET", "/infracoes/options").then(infracoes => this.setState({ infracoes }));
    }

    submit(event) {

        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/exigencias/${this.state.id ?? ''}`;

        const {
            id,
            descricao,
            codigo,
            norma,
            tipo,
            InfracaoId,
            desativado
        } = this.state;

        this.context.request(method, url, {
            id,
            descricao,
            codigo,
            norma,
            tipo,
            InfracaoId,
            desativado
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<ExigenciaList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Exigência
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<ExigenciaList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={9} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Descrição</b></Form.Label>
                            <Form.Control required value={this.state.descricao} onChange={e => this.setState({ descricao: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Código</b></Form.Label>
                            <Form.Control required value={this.state.codigo} onChange={e => this.setState({ codigo: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Norma</b></Form.Label>
                            <Form.Control required value={this.state.norma} onChange={e => this.setState({ norma: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Tipo</b></Form.Label>
                            <Form.Control required value={this.state.tipo} onChange={e => this.setState({ tipo: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Infração</b></Form.Label>
                            <ReactSelect
                                options={this.state.infracoes}
                                value={this.state.infracoes.find(option => this.state.InfracaoId === option.value)}
                                onChange={option => this.setState({ InfracaoId: option?.value ?? null })}
                                placeholder="Selecione uma infração ..."
                                noOptionsMessage={() => "Sem opções"}
                                isClearable
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Desativado?</b></Form.Label>
                            <Form.Switch className="switch-danger" checked={this.state.desativado} onChange={() => this.setState({ desativado: !this.state.desativado })} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default ExigenciaForm;