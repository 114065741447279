import { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faFastBackward, faUserTie } from "@fortawesome/free-solid-svg-icons";
import SubmitButton from "../componentes/SubmitButton";


class CadastroForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.cadastro?.id ?? null,
        tipo: this.props.cadastro?.tipo ?? "",
        cnpj_cpf: this.props.cadastro?.cnpj_cpf ?? "",
        nome_razaosocial: this.props.cadastro?.nome_razaosocial ?? "",
        nome_fantasia: this.props.cadastro?.nome_fantasia ?? "",
        titular_nome: this.props.cadastro?.titular_nome ?? "",
        telefone: this.props.cadastro?.telefone ?? "",
        email: this.props.cadastro?.email ?? "",
        endereco: this.props.cadastro?.endereco ?? "",
        CidadeId: this.props.cadastro?.CidadeId ?? null,
        BairroId: this.props.cadastro?.BairroId ?? null,
        AtividadeId: this.props.cadastro?.AtividadeId ?? null,
        bairro: this.props.cadastro?.bairro ?? null,
        atividades: [],
        cidades: [],
        bairros: [],

        submitting: false
    }

    componentDidMount() {
        this.context.load([
            this.context.request("GET", "/atividades/options").then(atividades => this.setState({ atividades })),
            this.context.request("GET", "/cidades/options").then(cidades => this.setState({ cidades })),
            this.state.CidadeId ? this.context.request("GET", `/cidades/${this.state.CidadeId}/bairros/options`).then(bairros => this.setState(state => ({ bairros: [...bairros, {CidadeId: state.CidadeId, label: state.bairro, value: null}] }))) : Promise.resolve()
        ]);
    }

    changeCidade(option) {
        this.setState({ BairroId: null, CidadeId: option.value ?? null, bairro: null }, () => {
            if (this.state.CidadeId) {
                this.context.request("GET", `/cidades/${this.state.CidadeId}/bairros/options`).then(bairros => this.setState({ bairros }));
            }
        });
    }

    submit(event) {

        event.preventDefault();

        this.setState({ submitting: true }, async () => {

            const method = this.state.id ? "PUT" : "POST";
            const url = `/cadastros/${this.state.id ?? ''}`;

            const {
                cnpj_cpf,
                tipo,
                nome_razaosocial,
                nome_fantasia,
                titular_nome,
                telefone,
                email,
                endereco,
                CidadeId,
                BairroId,
                bairro,
                AtividadeId
            } = this.state;

            const captcha = await this.context.getCaptcha();

            this.context.request(method, url, {
                tipo,
                cnpj_cpf,
                nome_razaosocial,
                nome_fantasia,
                titular_nome,
                telefone,
                email,
                endereco,
                CidadeId,
                BairroId,
                bairro,
                AtividadeId,
                captcha
            }, () => {
                this.setState({ submitting: false });
            })
                .then(() => {
                    this.props.onExit();
                })
        })


    }

    render() {
        return <Container>
            <Form onSubmit={e => this.submit(e)}>
                {!this.state.tipo &&
                    <Row className="d-flex align-items-stretch">
                        <Col lg={6} className="mb-2 flex-grow-1 d-flex flex-column">
                            <div className="card card-vody bg-light p-3 mb-3 flex-grow-1">
                                Se você é um profissional habilitado a elaborar projetos ou atuar como instrutor e responsável técnico de estabelecimentos, selecione esta opção.
                            </div>
                            <Button variant="primary" className="form-control" onClick={() => this.setState({ tipo: "Pessoa Física" })}>
                                <FontAwesomeIcon icon={faUserTie} /> Profissional
                            </Button>
                        </Col>
                        <Col lg={6} className="mb-2 flex-grow-1 d-flex flex-column">
                            <div className="card card-vody bg-light p-3 mb-3 flex-grow-1">
                                Se você deseja regularizar seu empreendimento através de Licenciamento, Credenciamento ou emissão de Anotação de Responsabilidade Profissional, selecione esta opção.
                            </div>
                            <Button variant="primary" className="form-control" onClick={() => this.setState({ tipo: "Pessoa Jurídica" })}>
                                <FontAwesomeIcon icon={faBuilding} /> Estabelecimento
                            </Button>
                        </Col>
                    </Row>}
                {this.state.tipo && <Row>
                    <Col lg={8} className="mb-2">
                        <Form.Group>
                            <Form.Label className="required"><b>Nome/Razão Social</b></Form.Label>
                            <Form.Control required value={this.state.nome_razaosocial} onChange={e => this.setState({ nome_razaosocial: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-2">
                        <Form.Group>
                            <Form.Label className="required"><b>CNPJ/CPF</b></Form.Label>
                            <InputMask className="form-control" required value={this.state.cnpj_cpf} onChange={e => this.setState({ cnpj_cpf: e.target.value })} mask={this.state.tipo === "Pessoa Jurídica" ? "99.999.999/9999-99" : "999.999.999-99"} maskChar="_" />
                        </Form.Group>
                    </Col>
                    {this.state.tipo === "Pessoa Jurídica" && <>
                        <Col lg={4} className="mb-2">
                            <Form.Group>
                                <Form.Label className="required"><b>Nome Fantasia</b></Form.Label>
                                <Form.Control required value={this.state.nome_fantasia} onChange={e => this.setState({ nome_fantasia: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={8} className="mb-2">
                            <Form.Group>
                                <Form.Label className="required"><b>Nome do Titular</b></Form.Label>
                                <Form.Control required value={this.state.titular_nome} onChange={e => this.setState({ titular_nome: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="mb-2">
                            <Form.Group>
                                <Form.Label className={this.state.AtividadeId ? "required" : "force-required"}><b>Atividade Econômica</b></Form.Label>
                                <ReactSelect
                                    options={this.state.atividades}
                                    value={this.state.atividades.find(option => option.value === this.state.AtividadeId)}
                                    onChange={option => this.setState({ AtividadeId: option?.value ?? null })}
                                    placeholder="Selecione um CNAE..."
                                    noOptionsMessage={() => "Sem opções"}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </>}
                    <Col lg={6} className="mb-2">
                        <Form.Group>
                            <Form.Label className="required"><b>Email de Contato</b></Form.Label>
                            <Form.Control required value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-2">
                        <Form.Group>
                            <Form.Label className="required"><b>Telefone de Contato</b></Form.Label>
                            <InputMask required placeholder="(__) _____-____" className="form-control" mask="(99) 99999-9999" maskChar="_" value={this.state.telefone} onChange={e => this.setState({ telefone: e.target.value })} />
                        </Form.Group>
                    </Col>

                    <Col lg={12} className="mb-2">
                        <Form.Group>
                            <Form.Label className="required"><b>Endereço Completo</b></Form.Label>
                            <Form.Control placeholder="Logradouro, Número, Complemento, Bairro, Cidade-UF, CEP e Ponto de Referência" as="textarea" rows={4} required value={this.state.endereco} onChange={e => this.setState({ endereco: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-2">
                        <Form.Group>
                            <Form.Label className={this.state.CidadeId ? "required" : "force-required"}><b>Cidade</b></Form.Label>
                            <ReactSelect
                                options={this.state.cidades}
                                value={this.state.cidades.find(option => option.value === this.state.CidadeId)}
                                onChange={option => this.changeCidade(option)}
                                placeholder="Selecione uma cidade..."
                                noOptionsMessage={() => "Sem opções"}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <Form.Group>
                            <Form.Label className={this.state.bairro? "required" : "force-required"}><b>Bairro</b></Form.Label>
                            <CreatableSelect
                                options={this.state.bairros}
                                value={this.state.bairros.find(option => option.value === this.state.BairroId)}
                                formatCreateLabel={label => "Criar Bairro: " + label}
                                onCreateOption={label => this.setState(state => ({BairroId: null, bairro: label, bairros: [{value: null, label, CidadeId: this.state.CidadeId}, ...state.bairros]}))}
                                onChange={option => this.setState({ BairroId: option?.value ?? null, bairro: option?.label })}
                                placeholder="Selecione ou adicione um bairro..."
                                noOptionsMessage={() => "Sem opções"}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="secondary" className="me-3" onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={faFastBackward} /> Voltar
                        </Button>
                        <SubmitButton
                            hidden={!this.state.tipo || (this.state.id && !this.context.usuario?.Recursos.find(r => r === "Atualizar Cadastro"))}
                            submitting={this.state.submitting} />
                    </Col>
                </Row>
                }
            </Form >
        </Container >;
    }
}

export default CadastroForm;