import { Component } from "react";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faFloppyDisk, faTimes } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../AppContext";

class UsuarioForm extends Component {

    static contextType = AppContext;

    state = {
        Perfis: this.props.usuario.Perfis.map(perfil => perfil.id) ?? [],

        perfis: []
    }

    componentDidMount() {
        this.context.request("GET", "/perfis/options").then(perfis => this.setState({ perfis }));
    }

    submit(event) {
        event.preventDefault();

        const { Perfis } = this.state;

        this.context.request("PUT", `/usuarios/${this.props.usuario.id}`, { Perfis }).then(() => {
            this.context.addToast({header: "Sucesso", body: "Dados gravados com sucesso"});
            this.props.onSave();
        });

    }

    render() {
        return <Form onSubmit={event => this.submit(event)}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Nome</strong></Form.Label>
                        <Form.Control disabled value={this.props.usuario.nome} />
                        <Form.Label className="mt-3"><strong>Unidade</strong></Form.Label>
                        <Form.Control disabled value={this.props.usuario.Unidade.sigla} />
                        <Form.Label className="mt-3"><strong>Login</strong></Form.Label>
                        <Form.Control disabled value={this.props.usuario.login} />
                        <Form.Label className="mt-3"><strong>Email</strong></Form.Label>
                        <Form.Control disabled value={this.props.usuario.email} />
                    </Form.Group>
                    <FormGroup>
                        <h5>Perfis Atribuídos</h5>
                        {this.state.perfis.map((option, key) =>
                            <Form.Check
                                key={key}
                                label={option.label}
                                checked={this.state.Perfis.indexOf(option.value) > -1}
                                onChange={event => this.setState(state => ({
                                    Perfis: event.target.checked ?
                                        [...state.Perfis, option.value] :
                                        state.Perfis.filter(p => p !== option.value)
                                }))}
                            />)}
                    </FormGroup>
                    <div className="d-flex justify-content-between mt-3">
                        <Button variant="secondary" onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={faFastBackward} /> Voltar
                        </Button>
                        <Button variant="primary" type="submit">
                            <FontAwesomeIcon icon={faFloppyDisk} /> Gravar
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>;
    }
}

export default UsuarioForm;
