import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import FuncaoForm from "../forms/FuncaoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

class FuncaoList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/funcoes/" + action.id).then(funcao =>
                    this.context.setContent(<FuncaoForm funcao={funcao} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/funcoes/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faStar} /> Pesquisa de Funções</div>}
            filters={[
                { name: "descricao", label: "Função", span: 8 },
            ]}
            endpoint="/funcoes"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<FuncaoForm />)} />;
    }
}

export default FuncaoList;