import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

class RegistroEntradaList extends Component {
    static contextType = AppContext;

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faArrowDown} /> Pesquisa de Registros de Entrada do Regin</div>}
            filters={[
                { name: "WCR_PROTOCOLO", label: "Protocolo", span: 4 },
                { name: "WCR_PRA_PROTOCOLO", label: "Viabilidade", span: 4 },
                { name: "WCR_WFS_COD_FUNCION", label: "Tipo", span: 2 },
                { name: "WCR_STATUS", label: "Status", span: 2 },
                { name: "WCR_FEC_INCORP", label: "Entrada Inicial", span: 2, type: "datetime-local", op: "gte" },
                { name: "WCR_FEC_INCORP", label: "Entrada Final", span: 2, type: "datetime-local", op: "lte" },                
                { name: "WCR_FEC_STATUS", label: "Atualização Inicial", span: 2, type: "datetime-local", op: "gte" },
                { name: "WCR_FEC_STATUS", label: "Atualização Final", span: 2, type: "datetime-local", op: "lte" }               
            ]}
            endpoint="/webservices/regin/entradas"/>;
    }
}

export default RegistroEntradaList;