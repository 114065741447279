import { Component } from "react";
import AppContext from "../AppContext";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import logo from "../image/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faKey, faSignIn, faUser } from "@fortawesome/free-solid-svg-icons";
import RecoverPassword from "./cadastros/RecoverPassword";
import CadastroForm from "./cadastros/CadastroForm";

class LandingPage extends Component {

    static contextType = AppContext;

    state = {
        classe: "",
        login: "",
        senha: "",
    }

    novoCadastro() {
        this.context.openModal({
            title: "Novo Cadastro", body: <CadastroForm onExit={() => {
                this.context.closeModal();
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso. Acesse seu email e siga as instruções para criar uma senha e realizar seu acesso." });
            }} onCancel={() => this.context.closeModal()} />,
            params: { size: "xl" }
        });
    }

    recuperarSenha() {
        this.context.openModal({
            title: "Recuperação de Acesso",
            body: <RecoverPassword onExit={() => this.context.closeModal()} />
        });
    }

    async submit(event) {
        event.preventDefault();
        const { classe, login, senha } = this.state;
        this.context.logon({ classe, login, senha });
    }

    render() {
        return <div id="lp-wrapper" >
            <div id="lp-frame">
                <div id="lp-frame-1" className="bg-dark text-white">
                    <h2>SISGAT</h2>
                    <h5>Sistema de Gerenciamento de Atividades Técnicas</h5>
                    <p className="mt-5" style={{ textAlign: 'justify' }}>
                        Bem-vindos ao SISGAT!
                        Nossa plataforma foi projetada para simplificar e aprimorar o gerenciamento de atividades técnicas
                        relacionadas à segurança contra incêndios.
                        <br /><br />
                        Faça login para acessar funcionalidades específicas de
                        sua classe de usuário e otimize seus processos de maneira inteligente e intuitiva.
                        <br /><br />
                        Estamos comprometidos em promover a segurança e a eficácia operacional,
                        unindo esforços para um ambiente mais seguro e protegido.
                        <br /><br />
                        Caso possua cadastro em antigos sistemas do CBMPA, utilize o link de recuperação de senha, ou
                        entre em contato com a SAT mais próxima.
                    </p>
                </div>
                <div id="lp-frame-2" className="bg-light">
                    <Form onSubmit={e => this.submit(e)}>
                        <Row>
                            <Col md={12} className="text-center">
                                <h5>Login do SISGAT</h5>
                                <img alt="Logo do CBMPA" height="80px" src={logo} />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={12}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faCogs} />
                                    </InputGroup.Text>
                                    <Form.Select required value={this.state.classe} onChange={e => this.setState({ classe: e.target.value })}>
                                        <option disabled value="">Selecione uma Classe de Usuário</option>
                                        <option value="cadastro1">Estabelecimento</option>
                                        <option value="cadastro2">Profissional</option>
                                        <option value="usuario">Usuário do CBMPA</option>
                                    </Form.Select>
                                </InputGroup>

                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={12}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faUser} />
                                    </InputGroup.Text>
                                    <Form.Control disabled={!this.state.classe} required placeholder={this.state.classe === "usuario" ? "Login do SIGA" : "CNPJ/CPF"} value={this.state.login} onChange={e => this.setState({ login: e.target.value })} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={12}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faKey} />
                                    </InputGroup.Text>
                                    <Form.Control disabled={!this.state.classe} required placeholder="Senha" type="password" value={this.state.senha} onChange={e => this.setState({ senha: e.target.value })} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={12}>
                                <Button disabled={!this.state.classe} type="submit" className="form-control">
                                    <FontAwesomeIcon icon={faSignIn} /> Entrar</Button>
                            </Col>
                        </Row>
                        {this.props.logoff && <Row>
                            <Col lg={12} className="text-center text-danger">
                                Usuário desconectado com sucesso!
                            </Col>
                        </Row>}
                        <Row className="mt-4">
                            <Col md={12}>
                                <Button className="text-right form-control" variant="link" onClick={() => this.novoCadastro()}>
                                    Não possui cadastro?
                                </Button>
                                <Button className="text-right form-control" variant="link" onClick={() => this.recuperarSenha()}>
                                    Recuperar acesso
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </div >;
    }
}

export default LandingPage;