import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PrefeituraList from "../lists/PrefeituraList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity } from "@fortawesome/free-solid-svg-icons";

class PrefeituraForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.prefeitura?.id ?? null,
        nome: this.props.prefeitura?.nome ?? "",
        api_key: this.props.prefeitura?.api_key ?? "",
        CidadeId: this.props.prefeitura?.CidadeId ?? "",

        cidades: [],
    }

    componentDidMount() {
        this.context.request("GET", "/cidades/options").then(cidades => this.setState({ cidades }));
    }

    submit(event) {

        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/prefeituras/${this.state.id ?? ''}`;

        const {
            id,
            nome,
            CidadeId
        } = this.state;

        this.context.request(method, url, {
            id,
            nome,
            CidadeId: CidadeId ? CidadeId : null
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<PrefeituraList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    <FontAwesomeIcon icon={faCity} />&nbsp;Cadastro de Prefeitura
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<PrefeituraList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={8} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Nome</b></Form.Label>
                            <Form.Control required value={this.state.nome} onChange={e => this.setState({ nome: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Cidade</b></Form.Label>
                            <Form.Select required value={this.state.CidadeId} onChange={e => this.setState({ CidadeId: e.target.value })}>
                                <option value="">Selecione...</option>
                                {this.state.cidades.map((cidade, key) => <option key={key} value={cidade.value}>{cidade.label}</option>)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>API KEY</b></Form.Label>
                            <Form.Control disabled value={this.state.api_key} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default PrefeituraForm;