import { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";


class BoletoComplementoForm extends Component {
    static contextType = AppContext;

    state = {
        valor: 0,
        motivo: ""
    }

    submit(event){
        event.preventDefault();
        this.props.onConfirm(this.state)
    }

    render() {
        return <Form onSubmit={event => this.submit(event)}>
            <Row>
                <Col lg={12} className="mb-3">
                    Ao complementar um boleto, o protocolo será suspenso até a confirmação do pagamento,
                    sendo retomado o processo a partir do status atual. Taxas adicionais podem ser aplicadas pela emissão do boleto.
                    Qual o valor a ser cobrado?
                </Col>
                <Col lg={12} className="mb-1">
                    <Form.Group>
                        <Form.Label><b>Motivo</b></Form.Label>
                        <textarea required className="form-control"  value={this.state.motivo} onChange={e => this.setState({motivo: e.target.value})}/>
                    </Form.Group>
                </Col>
                <Col lg={12} className="mb-1">
                    <Form.Group>
                        <Form.Label><b>Valor Adicional</b></Form.Label>
                        <InputGroup>
                            <InputGroup.Text>R$</InputGroup.Text>
                            <Form.Control type="number" min="0.01" step="0.01" value={this.state.valor} onChange={e => this.setState({valor: e.target.value})}/>
                        </InputGroup>                        
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" lg={{ offset: 4, span: 4 }}>
                    <Button className="form-control" variant="light" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                    </Button>
                </Col>
                <Col className="mb-3" lg={4}>
                    <Button className="form-control" variant="success" type="submit">
                        <FontAwesomeIcon icon={faCheck} /> Prosseguir
                    </Button>
                </Col>
            </Row>
        </Form>
    }
}

export default BoletoComplementoForm;