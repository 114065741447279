import React, { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faKey, faPaperPlane, faUser } from "@fortawesome/free-solid-svg-icons";


class ConfirmacaoModal extends Component {

    static contextType = AppContext;

    state = {
        login: "",
        senha: ""
    }

    async onSubmit(e) {
        e.preventDefault();

        const {login, senha } = this.state;
        const captcha = await this.context.getCaptcha("CONFIRMACAO");
        
        this.context.request("POST", "/auth", {classe: "usuario", login, senha, captcha}).then(({Usuario}) => {
            if (Usuario.classe === "usuario" && Usuario.id === this.context.usuario.id){
                this.props.onConfirm();
            } else {
                this.context.addToast({header: "Erro de Autenticação", body: "Usuário/Senha não encontrado ou não autorizado."});
            }
        }).catch(error => {
            this.context.addToast({header: "Erro de Autenticação", body: `Falha de autenticação: ${error.toString()}`});
        })
    }


    render() {
        return <Container>
            <Form onSubmit={e => this.onSubmit(e)}>
                <Row>
                    <Col>
                        <Col lg={12} className="mb-2">
                            <Form.Group>
                                <Form.Label className="required"><b>Login</b></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                                    <Form.Control value={this.state.login} onChange={e => this.setState({ login: e.target.value })} />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="mb-3">
                        <Form.Label className="required"><b>Senha</b></Form.Label>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                                <Form.Control type="password" value={this.state.senha} onChange={e => this.setState({ senha: e.target.value })} />
                            </InputGroup>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3 d-flex justify-content-between">
                        <Button variant="secondary" onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={faFastBackward} /> Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            <FontAwesomeIcon icon={faPaperPlane} /> Confirmar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    }
}

export default ConfirmacaoModal;