import React, { Component } from "react";
import AppContext from "../../AppContext";
import { Accordion, Button, Container, Form } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

class ProtocoloAndamentos extends Component {

    static contextType = AppContext;

    state = {
        texto: "",
        andamentos: this.props.andamentos ?? []
    }

    onSubmit(event) {

        event.preventDefault();
        const { texto } = this.state;

        this.context.request("POST", `/protocolos/${this.props.ProtocoloId}/andamentos`, { texto }).then(() => {
            this.context.addToast({ header: "Sucesso", body: "Operação realizada com sucesso!" });
            this.context.request("GET", `/protocolos/${this.props.ProtocoloId}/andamentos`).then(andamentos => {
                this.setState({andamentos});
            })
        });

    }

    render() {
        return <Container>
            <Accordion className="mb-3">
                {this.state.andamentos?.length ? this.state.andamentos.map((andamento, key) =>
                    <Accordion.Item key={key} eventKey={key}>
                        <Accordion.Header>
                            {moment(andamento.datahora).format("DD/MM/YYYY HH:mm")} - {andamento.texto}
                        </Accordion.Header>
                        <Accordion.Body>
                            <i className="small">Usuário: {andamento.Usuario?.nome ?? andamento.Cadastro?.nome_razaosocial ?? "Ação do Sistema"}</i>
                        </Accordion.Body>
                    </Accordion.Item>) :
                    <div className="text-center">Nenhum andamento registrado</div>}
            </Accordion>
            {this.context.usuario.Recursos.find(r => r === "Adicionar Andamento") && <Form onSubmit={event => this.onSubmit(event)}>
                <div className="card card-body bg-light mb-3">
                    <Form.Group className="mb-3">
                        <Form.Label><b>Novo Andamento</b></Form.Label>
                        <Form.Control required as="textarea" value={this.state.texto} onChange={e => this.setState({ texto: e.target.value })} rows={4} />
                    </Form.Group>
                </div>
                <div className="d-flex w-100 justify-content-between">
                    <Button variant="secondary" onClick={() => this.props.onExit()}>
                        <FontAwesomeIcon icon={faFastBackward} /> Voltar
                    </Button>
                    <Button type="submit" varian="primary">
                        <FontAwesomeIcon icon={faPaperPlane} /> Gravar Andamento
                    </Button>
                </div>
            </Form>}
        </Container>
    }
}

export default ProtocoloAndamentos;