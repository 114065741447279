import React, { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import moment from "moment";


class AtribuicaoForm extends Component {

    static contextType = AppContext;

    state = {
        id: null,
        UsuarioId: null,
        FuncaoId: null,
        datahora_agendamento: null,
        extraordinaria: false,
        Auxiliares: [],
        observacao: "",

        funcoes: [],
        usuarios: [],
    }


    componentDidMount() {
        this.context.request("GET", "/funcoes/options").then(funcoes => this.setState({ funcoes }));
        this.context.request("GET", "/usuarios/options").then(usuarios => this.setState({ usuarios }));
        this.context.request("GET", `/atribuicoes/${this.props.AtribuicaoId}`).then(atribuicao => this.setState(atribuicao));
    }

    onSubmit(e) {
        e.preventDefault();
        const { id, UsuarioId, FuncaoId, datahora_agendamento, extraordinaria, Auxiliares, observacao } = this.state;
        Auxiliares.forEach(auxiliar => delete auxiliar.status);
        this.props.onConfirm({ id, UsuarioId, FuncaoId, datahora_agendamento, extraordinaria, Auxiliares, observacao });
    }


    render() {
        return <Container>
            <Form onSubmit={e => this.onSubmit(e)}>
                <Row>
                    <Col>
                        <Col lg={12} className="mb-3">
                            <Form.Group>
                                <Form.Label className="required"><b>Encarregado Principal</b></Form.Label>
                                <ReactSelect
                                    options={this.state.usuarios}
                                    value={this.state.usuarios.find(option => option.value === this.state.UsuarioId)}
                                    onChange={option => this.setState({ UsuarioId: option.value ?? null })}
                                    placeholder="Selecione um encarregado..."
                                    noOptionsMessage={() => "Sem opções"}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="mb-3">
                            <Form.Group>
                                <Form.Label className="required"><b>Função</b></Form.Label>
                                <ReactSelect
                                    options={this.state.funcoes}
                                    value={this.state.funcoes.find(option => option.value === this.state.FuncaoId)}
                                    onChange={option => this.setState({ FuncaoId: option.value ?? null })}
                                    placeholder="Selecione uma função..."
                                    noOptionsMessage={() => "Sem opções"}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="mb-3">
                            <Form.Group>
                                <label className="d-flex justify-content-between">
                                    <b>Atribuição Extraordinária</b>
                                    <Form.Check type="switch" onChange={e => this.setState({ extraordinaria: e.target.checked })} checked={this.state.extraordinaria} />
                                </label>
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="mb-3">
                            <Form.Group>
                                <Form.Label><b>Agendamento</b></Form.Label>
                                <Form.Control type="datetime-local" required
                                    value={this.state.datahora_agendamento ? moment(this.state.datahora_agendamento).format("YYYY-MM-DDTHH:mm") : ""}
                                    onChange={e => this.setState({ datahora_agendamento: e.target.value ? moment(e.target.value) : null })} />
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="mb-3">
                            <Form.Group>
                                <Form.Label><b>Observação</b></Form.Label>
                                <Form.Control as="textarea"
                                    value={this.state.observacao}
                                    onChange={e => this.setState({ observacao: e.target.value })} />
                            </Form.Group>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mb-3">
                        {this.state.Auxiliares.map((auxiliar, key) =>
                            <Card key={key} className="mb-1">
                                <Card.Header className="w-100 d-flex justify-content-between">
                                    Auxiliar {key + 1}
                                    <Button variant="danger" size="sm" onClick={() => this.setState(state => ({ Auxiliares: state.Auxiliares.filter(a => a !== auxiliar) }))}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group>
                                        <Form.Label className="required"><b>Encarregado Auxiliar</b></Form.Label>
                                        <ReactSelect
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                            options={this.state.usuarios}
                                            value={this.state.usuarios.find(option => option.value === auxiliar.UsuarioId)}
                                            onChange={option => this.setState(state => ({ Auxiliares: state.Auxiliares.map(a => a === auxiliar ? { ...a, UsuarioId: option.value ?? null } : a) }))}
                                            placeholder="Selecione um encarregado..."
                                            noOptionsMessage={() => "Sem opções"}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="required"><b>Função</b></Form.Label>
                                        <ReactSelect
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                            options={this.state.funcoes}
                                            value={this.state.funcoes.find(option => option.value === auxiliar.FuncaoId)}
                                            onChange={option => this.setState(state => ({ Auxiliares: state.Auxiliares.map(a => a === auxiliar ? { ...a, FuncaoId: option.value ?? null } : a) }))}
                                            placeholder="Selecione uma função..."
                                            noOptionsMessage={() => "Sem opções"}
                                            required
                                        />
                                    </Form.Group>
                                </Card.Body>
                            </Card>)}
                        <Button className="form-control" variant="success" onClick={() => this.setState(state => ({ Auxiliares: [...state.Auxiliares, { UsuarioId: null, FuncaoId: null }] }))}>
                            <FontAwesomeIcon icon={faPlus} /> Adicionar Auxiliar
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3 d-flex justify-content-between">
                        <Button variant="secondary" onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={faFastBackward} /> Voltar
                        </Button>
                        {this.context.usuario.Recursos.find(r => r === "Atualizar Atribuição") &&
                            <Button variant="primary" type="submit">
                                <FontAwesomeIcon icon={faSave} /> Gravar
                            </Button>}
                    </Col>
                </Row>
            </Form>
        </Container>
    }
}

export default AtribuicaoForm;