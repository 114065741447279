import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import ConfiguracaoForm from "../forms/ConfiguracaoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";

class ConfiguracaoList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/configuracoes/" + action.id).then(configuracao =>
                    this.context.setContent(<ConfiguracaoForm configuracao={configuracao} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/configuracoes/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faCogs} /> Pesquisa de Configurações</div>}
            filters={[
                { name: "descricao", label: "Configuração", span: 8 },
                { name: "chave", label: "Chave", span: 4 },
                { name: "valor", label: "Valor", span: 4 }
            ]}
            endpoint="/configuracoes"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<ConfiguracaoForm />)} />;
    }
}

export default ConfiguracaoList;