import { Component } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import moment from 'moment';
import AppContext from "../AppContext";
import AppCalendar from "./componentes/AppCalendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode, faDownload, faExclamationTriangle, faFolderOpen, faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import ProtocoloForm from "./protocolos/ProtocoloForm";
import ProtocoloList from "./protocolos/ProtocoloList";
import CadastroList from "./cadastros/CadastroList";
import AtribuicaoExecucaoForm from "./atribuicoes/AtribuicaoExecucaoForm";

class HomePage extends Component {

    static contextType = AppContext;

    state = {
        avisos: [],
        boletos: [],
        certificados: [],
        atribuicoes: [],
        classe: this.context.usuario?.classe,
        urls: []
    }

    componentDidMount() {
        this.context.request("GET", "/home").then(({ avisos, boletos, certificados, atribuicoes }) => this.setState({ avisos, boletos, certificados, atribuicoes }));
    }

    componentWillUnmount() {
        this.state.urls.forEach(url => URL.revokeObjectURL(url));
    }

    downloadBoleto(boleto) {
        this.context.request("GET", `/boletos/${boleto.id}/download`)
            .then(blob => this.download(blob, `Boleto-${boleto.id}.pdf`));
    }

    downloadCertificado(certificado) {
        this.context.request("GET", `/certificados/${certificado.id}/download`)
            .then(blob => this.download(blob, `Certificado-${certificado.id}.pdf`));
    }

    download(blob, filename) {
        return new Promise(resolve => {
            const url = URL.createObjectURL(blob);
            this.setState(state => ({ urls: [...state.urls, url] }), () => {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                resolve();
            });
        });
    }

    novoLicenciamento() {
        this.context.request("GET", `/cadastros/${this.context.usuario.id}`).then(cadastro => {
            const protocolo = {
                tipo: "Licenciamento",
                Cadastro: cadastro
            }
            this.context.setContent(<ProtocoloForm
                protocolo={protocolo}
                onCancel={() => this.context.setContent(<HomePage />)}
                onSave={protocolo => this.context.setContent(<ProtocoloList cadastro={cadastro} ProtocoloId={protocolo.id} />)}
            />);
        })
    }

    novoCredenciamento() {
        this.context.request("GET", `/cadastros/${this.context.usuario.id}`).then(cadastro => {
            const protocolo = {
                tipo: "Credenciamento",
                Cadastro: cadastro
            }
            this.context.setContent(<ProtocoloForm
                protocolo={protocolo}
                onCancel={() => this.context.setContent(<HomePage />)}
                onSave={protocolo => this.context.setContent(<ProtocoloList cadastro={cadastro} ProtocoloId={protocolo.id} />)}
            />);
        })
    }

    novaAnotacao() {
        this.context.request("GET", `/cadastros/${this.context.usuario.id}`).then(cadastro => {
            const protocolo = {
                tipo: "Anotação",
                Cadastro: cadastro
            }
            this.context.setContent(<ProtocoloForm
                protocolo={protocolo}
                onCancel={() => this.context.setContent(<HomePage />)}
                onSave={protocolo => this.context.setContent(<ProtocoloList cadastro={cadastro} ProtocoloId={protocolo.id} />)}
            />);
        })
    }

    novaRenovacao() {
        this.context.addToast({
            header: "Orientações",
            body: <>
                Para renovar um Licenciamento/Credenciamento, selecione um protocolo e clique na opção <Button size="sm" variant="outline-success"><FontAwesomeIcon icon={faRefresh} /></Button> correspondente.
            </>,
            autohide: false
        })
        this.context.setContent(<ProtocoloList />);
    }

    executarAtribuicao(atribuicao) {
        this.context.closeModal();
        this.context.request("GET", `/atribuicoes/${atribuicao.id}/execucao`).then(atribuicao =>
            this.context.setContent(<AtribuicaoExecucaoForm atribuicao={atribuicao}
                onExit={() => this.context.setContent(<HomePage />)}
            />));
    }

    download(blob, filename) {
        return new Promise(resolve => {
            const url = URL.createObjectURL(blob);
            this.setState(state => ({ urls: [...state.urls, url] }), () => {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                resolve();
            });
        });
    }

    // Operações em boletos
    downloadBoleto(boleto) {
        this.context.request("GET", `/boletos/${boleto.id}/download`)
            .then(blob => this.download(blob, `Boleto-${boleto.id}.pdf`));
    }

    downloadCertificado(certificado){
        this.context.request("GET", `/certificados/${certificado.id}/download`)
        .then(blob => this.download(blob, `Certificado-CBMPA-${certificado.id.toString().padStart(6, '0')}.pdf`));                
    }

    render() {

        const events = [
            ...this.state.boletos.filter(boleto => !boleto.data_pagamento).map(boleto => ({
                date: boleto.data_vencimento,
                text: `Vencimento de Boleto no valor de R$ ${boleto.valor.toFixed(2).toString().replace('.', ',')}`,
                onClick: () => this.downloadBoleto(boleto)
            })),

            ...this.state.certificados.filter(certificado => certificado.data_validade).map(certificado => ({
                date: certificado.data_validade,
                text: `Vencimento de Certificado de ${certificado.tipo} de ${certificado.Cadastro?.nome_razaosocial}`,
                onClick: () => this.downloadCertificado(certificado)
            })),

            ...this.state.atribuicoes.map(atribuicao => ({
                date: atribuicao.datahora_agendamento,
                text: `Agendamento de Atribuição de ${atribuicao.Funcao?.descricao} para ${moment(atribuicao.datahora_agendamento).format("HH:mm")} - Protocolo ${atribuicao.ProtocoloId?.toString().padStart(6, "0")}`,
                status: atribuicao.status,
                onClick: () => this.executarAtribuicao(atribuicao)
            })),
        ];

        const debito = this.state.boletos.reduce((result, boleto) => result || (boleto.Taxa?.descricao.includes("MULTA") && !boleto.data_pagamento), false);

        return <Container>
            <Row>
                <Col lg={8}>
                    <Row className="flex-grow-1">
                        {this.context.usuario.classe === "usuario" && <Col className="mb-3">
                            <h3>Avisos</h3>
                            <hr />
                            <div className="d-flex flex-column">
                                {this.state.avisos.map((aviso, key) =>
                                    <div key={key} className="d-flex flex-column mb-3 app-aviso">
                                        <h5>{aviso.titulo}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: aviso.texto }}></div>
                                        <div className="mt-2 small d-flex justify-content-end"><i>Publicado por {aviso.Usuario?.nome}, em {moment(aviso.data_atualizacao).format("DD/MM/YYYY")}</i></div>
                                    </div>)}
                            </div>
                        </Col>}
                        {this.context.usuario.classe === "cadastro" && <Col className="mb-3">
                            <h3>Bem vindo ao SISGAT</h3>
                            <hr />
                            <div className="card card-body bg-light mb-3">
                                Neste ambiente você pode realizar a solicitação dos seguintes serviços:
                                <ul className="mt-2">
                                    {this.context.usuario.tipoPessoa === "Pessoa Jurídica" && <>
                                        <li><b>Licenciamento:</b> Consiste na regularização de um novo estabelecimento, ou que nunca tenha sido
                                            cadastrado no Corpo de Bombeiros. Utilize o menu Cadastro para encontrar o estabelecimeto vinculado ao
                                            seu usuário e solicitar o serviço correspondente.
                                        </li>
                                        <li><b>Anotação:</b> Consiste na emissão da Anotação de Responsabilidade Profissional (ARP), a qual certifica a regularidade
                                            na formação de turmas de cursos de combate a incêndio por instrutores e empresas credenciadas.</li>
                                    </>}
                                    <li><b>Credenciamento:</b> Serviço de habilitação de empresas e profissionais para atuarem no ramo de segurança contra incêndio,
                                        quer seja na formação técnica, execução e instalação de sistemas de segurança, elaboração de projetos, comercialização de produtos,
                                        entre outras atividades.</li>
                                    <li><b>Renovação:</b> Consiste na emissão da taxa anual de regularização do empreendimento,
                                        para aqueles que já possuem cadastro no SISGAT. Utilize o menu Protocolo e selecione a opção Renovar no Licenciamento
                                        ou Credenciamento correspondente.</li>
                                </ul>
                                {!debito && <>
                                    <Row>
                                        {this.context.usuario.tipoPessoa === "Pessoa Jurídica" && <>
                                            <Col lg="3" className="mb-3">
                                                <Button variant="success" className="form-control" size="sm" onClick={() => this.novoLicenciamento()}>
                                                    <FontAwesomeIcon icon={faPlus} /> Licenciamento
                                                </Button>
                                            </Col>
                                            <Col lg="3" className="mb-3">
                                                <Button variant="success" className="form-control" size="sm" onClick={() => this.novaAnotacao()}>
                                                    <FontAwesomeIcon icon={faPlus} /> Anotação
                                                </Button>
                                            </Col>
                                        </>}
                                        <Col lg="3" className="mb-3">
                                            <Button variant="success" className="form-control" size="sm" onClick={() => this.novoCredenciamento()}>
                                                <FontAwesomeIcon icon={faPlus} /> Credenciamento
                                            </Button>
                                        </Col>
                                        <Col lg="3" className="mb-3">
                                            <Button variant="success" className="form-control" size="sm" onClick={() => this.novaRenovacao()}>
                                                <FontAwesomeIcon icon={faPlus} /> Renovação
                                            </Button>
                                        </Col>
                                    </Row>

                                    Serviços para cadastros vinculados podem ser solicitados no menu:
                                    <Button variant="primary" size="sm" onClick={() => this.context.setContent(<CadastroList />)}>
                                        <FontAwesomeIcon icon={faFolderOpen} /> Cadastros
                                    </Button>
                                </>}
                                {debito && <div className="card-body bg-danger text-white p-3 my-3" style={{ borderRadius: 5, border: 'solid 1px gray' }}>
                                    <h3 className="text-center"><FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;Atenção!</h3>
                                    <h6 className="text-center">Um ou mais cadastros viculados possuem débitos pendentes relacionados a Multa. Regularize estas pendências para prosseguir com a solicitação de novos protocolos para o cadastro autuado.</h6>
                                </div>}

                            </div>
                            <h3>Taxas Emitidas</h3>
                            <hr />
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Taxa</th>
                                        <th>Protocolo</th>
                                        <th>Valor</th>
                                        <th>Vencimento</th>
                                        <th className="text-center">Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.boletos.map((boleto, key) =>
                                        <tr key={key}>
                                            <td className="valign-middle">{boleto.Taxa?.descricao}</td>
                                            <td className="valign-middle">{boleto.ProtocoloId?.toString().padStart(6, 0)}</td>
                                            <td className="valign-middle text-nowrap">R$ {boleto.valor?.toFixed(2).toString().replace('.', ',')}</td>
                                            <td className="valign-middle">{moment(boleto.data_vencimento).format("DD/MM/YYYY")}</td>
                                            <td className="valign-middle text-center">
                                                {boleto.data_pagamento ? "Pago" :
                                                    <Button title="Download do DAE" size="sm" className="primary" onClick={() => this.downloadBoleto(boleto)}>
                                                        <FontAwesomeIcon icon={faBarcode} />
                                                    </Button>}
                                            </td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        </Col>}
                        {this.context.usuario.classe === "cadastro" && <Col className="mb-3">
                            <h3>Certificados Emitidos</h3>
                            <hr />
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Tipo</th>
                                        <th>Protocolo</th>
                                        <th>Nome/Estabelecimento</th>
                                        <th>Validade</th>
                                        <th className="text-center">Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.certificados.map((certificado, key) =>
                                        <tr key={key}>
                                            <td className="valign-middle">{certificado.id}</td>
                                            <td className="valign-middle">{certificado.tipo}</td>
                                            <td className="valign-middle">{certificado.ProtocoloEmissaoId?.toString().padStart(6, 0)}</td>
                                            <td className="valign-middle">{certificado.ProtocoloEmissao?.nome_estabelecimento}</td>
                                            <td className="valign-middle">{moment(certificado.data_validade).format("DD/MM/YYYY")}</td>
                                            <td className="valign-middle text-center">
                                                <Button title="Download do Certificado" size="sm" className="primary" onClick={() => this.downloadCertificado(certificado)}>
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Button>
                                            </td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        </Col>}
                    </Row>
                </Col>
                <Col lg={4} className="d-flex flex-column">
                    <Row>
                        <Col className="mb-3">
                            <h3>Agenda</h3>
                            <hr />
                            <div className="d-flex justify-content-center">
                                <AppCalendar events={events} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>;
    }
}
export default HomePage;
