import React, { createRef, useEffect, } from 'react';
import { MapContainer, Marker, TileLayer, ZoomControl, useMap, useMapEvents } from 'react-leaflet';
import AppContext from '../../AppContext';
import { Icon } from "leaflet";
import iconUrl from "leaflet/dist/images/marker-icon.png";

const ControlLayer = ({latitude, longitude, onChange}) => {

    const map = useMap();

    useMapEvents({
        click: event => onChange ? onChange(event.latlng.lat, event.latlng.lng, true) : null,
    });

    useEffect(() => {
        map.flyTo({lat: latitude, lng: longitude});
    }, [map, latitude, longitude]);
};

const markerIcon = new Icon({
    iconUrl,
    iconAnchor: [12, 40]
});

class AppMap extends React.Component {

    static contextType = AppContext;

    markerRef = createRef();
    mapRef = createRef();

    render() {
        return (
            <MapContainer zoomControl={false} ref={this.mapRef} center={[this.props.latitude ?? 0,  this.props.longitude ?? 0]} zoom={14} style={{ width: '100%', height: '100%', minHeight: 400}} >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <ControlLayer onChange={this.props.onChange} latitude={this.props.latitude} longitude={this.props.longitude}/>
                <ZoomControl position='topright' />
                <Marker
                    ref={this.markerRef}
                    icon={markerIcon}
                    position={{ lat: this.props.latitude ?? 0, lng: this.props.longitude ?? 0 }}
                    draggable={this.props.onChange}
                    eventHandlers={this.props.onChange? {
                        drag: () => {
                            const { lat, lng } = this.markerRef.current.getLatLng();
                            if (this.props.onChange) this.props.onChange(lat, lng, true);
                        },
                    } : undefined} />
            </MapContainer>
        );
    }
};

export default AppMap;
