import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import ModalidadeForm from "../forms/ModalidadeForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";

class ModalidadeList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/modalidades/" + action.id).then(modalidade =>
                    this.context.setContent(<ModalidadeForm modalidade={modalidade} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/modalidades/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
        title={<div><FontAwesomeIcon icon={faIdCard} /> Pesquisa de Modalidades de Credenciamento</div>}
            filters={[
                { name: "descricao", label: "Modalidade de Credenciamento", span: 12 },
                { name: "tipo", label: "Tipo de Pessoa", options: ['Pessoa Física', 'Pessoa Jurídica'], span: 4 },
            ]}
            endpoint="/modalidades"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<ModalidadeForm />)} />;
    }
}

export default ModalidadeList;