import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import RevendaglpForm from "../forms/RevendaglpForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFireFlameCurved } from "@fortawesome/free-solid-svg-icons";

class RevendaglpList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/revendaglps/" + action.id).then(revendaglp =>
                    this.context.setContent(<RevendaglpForm revendaglp={revendaglp} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/revendaglps/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable 
        title={<div><FontAwesomeIcon icon={faFireFlameCurved} /> Pesquisa de Revenda de GLP</div>}
            filters={[
                {name: "descricao", label: "Revenda", span: 8},    
            ]}
            endpoint="/revendaglps"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<RevendaglpForm />)}/>;
    }
}

export default RevendaglpList;