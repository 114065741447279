import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import CursoForm from "../forms/CursoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

class CursoList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/cursos/" + action.id).then(curso =>
                    this.context.setContent(<CursoForm curso={curso} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/cursos/" + action.id).finally(callback);
                break;
            default:
                break;

        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faBook} /> Pesquisa de Cursos e Treinamentos</div>}
            filters={[
                { name: "descricao", label: "Curso/Treinamento", span: 8 },
            ]}
            endpoint="/cursos"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<CursoForm />)} />;
    }
}

export default CursoList;