import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PerguntaList from "../lists/PerguntaList";

class PerguntaForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.pergunta?.id ?? null,
        texto: this.props.pergunta?.texto ?? "",
        criterio: this.props.pergunta?.criterio ?? "",
        codigo_regin: this.props.pergunta?.codigo_regin ?? "",
        resposta_nao: this.props.pergunta?.resposta_nao ?? "",
    }

    submit(event) {

        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/perguntas/${this.state.id ?? ''}`;

        const {
            id,
            texto,
            criterio,
            codigo_regin,
            resposta_nao
        } = this.state;

        this.context.request(method, url, {
            id,
            texto,
            criterio,
            codigo_regin,
            resposta_nao
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<PerguntaList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Pergunta do Questionário
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<PerguntaList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Texto</b></Form.Label>
                            <Form.Control as="textarea" required value={this.state.texto} onChange={e => this.setState({ texto: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Critério</b></Form.Label>
                            <Form.Select required value={this.state.criterio} onChange={e => this.setState({ criterio: e.target.value })}>
                                <option value="">Selecione...</option>
                                <option>Dispensa</option>
                                <option>Análise</option>
                                <option>Vistoria</option>
                                <option>ACPS</option>
                                <option>Desativado</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Código REGIN</b></Form.Label>
                            <Form.Control value={this.state.codigo_regin} onChange={e => this.setState({ codigo_regin: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Resposta "Não"</b></Form.Label>
                            <Form.Control value={this.state.resposta_nao} onChange={e => this.setState({ resposta_nao: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default PerguntaForm;
