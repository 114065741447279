import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import PerguntaForm from "../forms/PerguntaForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

class PerguntaList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/perguntas/" + action.id).then(pergunta =>
                    this.context.setContent(<PerguntaForm pergunta={pergunta} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/perguntas/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable 
        title={<div><FontAwesomeIcon icon={faQuestion} /> Pesquisa de Perguntas do Questionário</div>}
            filters={[
                {name: "texto", label: "Pergunta", span: 8},
                {name: "criterio", label: "Critério", span: 4, options: ["Análise", "Vistoria", "Isenção"]},                              
                {name: "codigo_regin", label: "Código REGIN", span: 8},      
            ]}
            endpoint="/perguntas"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<PerguntaForm />)}/>;
    }
}

export default PerguntaList;