import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InfracaoList from "../lists/InfracaoList";

class TipoInfracaoForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.infracao?.id ?? null,
        descricao: this.props.infracao?.descricao ?? "",
        codigo: this.props.infracao?.codigo ?? "",
        peso: this.props.infracao?.peso ?? "",
        penalidade: this.props.infracao?.penalidade ?? "",
        gravidade: this.props.infracao?.gravidade ?? ""
    }

    submit(event) {

        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/infracoes/${this.state.id ?? ''}`;

        const {
            id,
            descricao,
            codigo,
            peso,
            penalidade,
            gravidade
        } = this.state;

        this.context.request(method, url, {
            id,
            descricao,
            peso,
            codigo,
            penalidade,
            gravidade
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<InfracaoList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Infração
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<InfracaoList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={9} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Descrição</b></Form.Label>
                            <Form.Control required value={this.state.descricao} onChange={e => this.setState({ descricao: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Código</b></Form.Label>
                            <Form.Control required value={this.state.codigo} onChange={e => this.setState({ codigo: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Gravidade</b></Form.Label>
                            <Form.Select required value={this.state.gravidade} onChange={e => this.setState({ gravidade: e.target.value })}>
                                <option value="">Selecione...</option>
                                <option>Leve</option>
                                <option>Média</option>
                                <option>Grave</option>
                                <option>Gravíssima</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Penalidade</b></Form.Label>
                            <Form.Select required value={this.state.penalidade} onChange={e => this.setState({ penalidade: e.target.value })}>
                                <option value="">Selecione...</option>
                                <option>Advertência</option>
                                <option>Multa</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Peso</b></Form.Label>
                            <Form.Control required type="number" min="0" step="0.01" value={this.state.peso} onChange={e => this.setState({ peso: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default TipoInfracaoForm;