import React from 'react';
import AppContext from '../../AppContext';
import {Editor} from "@tinymce/tinymce-react";

class AppEditor extends React.Component {

    static contextType = AppContext;

    render() {
        return (
            <Editor
                apiKey={this.context.configuracoes.TINYMCE_APIKEY}
                value={this.props.value}
                onEditorChange={value => this.props.onChange(value)}
                init={{
                    language: "pt_BR",
                    height: 300,
                    plugins: 'print preview paste searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image imagetools link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                    menubar: 'file edit view insert format tools table',
                    toolbar: 'undo redo | bold italic underline | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat emoticons | fullscreen  preview save print | insertfile image media link anchor',
                    paste_data_images: true,
                    automatic_uploads: false,
                    images_upload_handler: (blobInfo, success, failure) => success(),
                }}
            />
        );
    }
};

export default AppEditor;