import { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../AppContext";
import moment from 'moment';

class LoteForm extends Component {

    static contextType = AppContext;

    render() {
        return <Row>
            <Col>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Identificador</strong></Form.Label>
                    <Form.Control disabled value={this.props.lote.id} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Quantidade</strong></Form.Label>
                    <Form.Control disabled type="number" value={this.props.lote.quantidade} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Valor Total</strong></Form.Label>
                    <Form.Control disabled type="number" value={this.props.lote.valorTotal} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Período Mínimo</strong></Form.Label>
                    <Form.Control disabled type="date" value={this.props.lote.periodoMinimo} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Período Máximo</strong></Form.Label>
                    <Form.Control disabled type="date" value={this.props.lote.periodoMaximo} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Data/Hora</strong></Form.Label>
                    <Form.Control disabled type="datetime-local" value={this.props.lote.datahora? moment(this.props.lote.datahora).format("YYYY-MM-DDTHH:mm") : ""} />                    
                </Form.Group>
                <div className="d-flex justify-content-between mt-3">
                    <Button variant="secondary" onClick={() => this.context.onExit()}>
                        <FontAwesomeIcon icon={faFastBackward} /> Voltar
                    </Button>
                </div>
            </Col>
        </Row>;
    }
}

export default LoteForm;
