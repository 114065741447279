import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import InfracaoForm from "../forms/InfracaoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";

class TipoInfracaoList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/infracoes/" + action.id).then(infracao =>
                    this.context.setContent(<InfracaoForm infracao={infracao} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/infracoes/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faBullhorn} /> Pesquisa de Infrações</div>}
            filters={[
                { name: "descricao", label: "Infração", span: 8 },
                { name: "penalidade", label: "Penalidade", span: 4, options: ["Advertência", "Multa"] },
                { name: "gravidade", label: "Gravidade", span: 4, options: ["Leve", "Média", "Grave", "Gravíssima"] },
                { name: "codigo", label: "Código", span: 4 },
                { name: "peso", label: "Peso", span: 4 },
            ]}
            endpoint="/infracoes"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<InfracaoForm />)} />;
    }
}

export default TipoInfracaoList;
