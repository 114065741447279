import React, { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Card, Col, Container, Form, InputGroup, ListGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faCheck, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import moment from "moment";


class ActionForm extends Component {

    static contextType = AppContext;

    state = {
        file: null,

        UsuarioId: null,
        FuncaoId: null,
        datahora_agendamento: null,
        extraordinaria: false,
        Auxiliares: [],
        observacao: "",
        quantidade: 1,

        TaxaId: null,
        Certificados: [],
        Boletos: [],

        taxas: [],
        funcoes: [],
        usuarios: [],
        boletos: [],
        certificados: [],

        submitting: false
    }

    uploadRef = React.createRef();

    componentDidMount() {
        if (this.props.action.atribuicao) {
            this.context.request("GET", "/funcoes/options").then(funcoes => this.setState({ funcoes }));
            this.context.request("GET", "/usuarios/options").then(usuarios => this.setState({ usuarios }));
        } else if (this.props.action.inscricao) {
            this.context.request("GET", `/protocolos/${this.props.protocolo.id}/boletos?status=Vencido`).then(boletos => this.setState({ boletos }));
        } else if (this.props.action.cassacao) {
            this.context.request("GET", `/cadastros/${this.props.protocolo.CadastroId}/certificados`).then(certificados => this.setState({ certificados }));
        } else if (this.props.action.taxa) {
            this.context.request("GET", `/taxas/options?tipo=Outros Serviços&quantidade=1&risco=Nulo&grupo_f=Inclusivo&ModalidadeId=`).then(taxas => this.setState({ taxas }));
        }
    }


    calcular() {
        this.context.request("GET", `/taxas/${this.state.TaxaId}/valor?quantidade=${this.state.quantidade}`)
            .then(({ valor }) => this.setState({ valor })).catch(() => this.setState({valor: null}));
    }

    onUpload(event) {
        const { files } = event.target;
        this.setState({ file: files.length ? files[0] : null });
    }

    onSubmit(e) {
        e.preventDefault();

        this.setState({ submitting: true }, () => {

            const { action } = this.props;

            const callback = () => this.setState({ submitting: false });

            if (action.anexo) {
                const payload = new FormData();
                payload.append("arquivo", this.state.file);
                this.props.onConfirm(payload, callback);
            } else if (action.atribuicao) {
                const { UsuarioId, FuncaoId, extraordinaria, datahora_agendamento, Auxiliares, observacao } = this.state;
                this.props.onConfirm({ UsuarioId, FuncaoId, extraordinaria, datahora_agendamento, Auxiliares, observacao }, callback);
            } else if (action.taxa) {
                const { TaxaId, quantidade } = this.state;
                this.props.onConfirm({ TaxaId, quantidade }, callback);
            } else if (action.inscricao) {
                const { Boletos } = this.state;
                this.props.onConfirm({ Boletos }, callback);
            } else if (action.cassacao) {
                const { Certificados } = this.state
                this.props.onConfirm({ Certificados }, callback);
            } else if (action.taxa) {
                const { TaxaId } = this.state;
                this.props.onConfirm({ TaxaId }, callback);
            } else {
                this.props.onConfirm(callback);
            }
        })

    }


    render() {
        return <Container>
            <Form onSubmit={e => this.onSubmit(e)}>
                <Row>
                    <Col className="mb-3">{this.props.action.info}</Col>
                </Row>
                {this.props.action.taxa &&
                    <Row>
                        <Col lg={12} className="mb-3">
                            <Form.Group>
                                <Form.Label className="required"><b>Taxa do Serviço</b></Form.Label>
                                <ReactSelect
                                    options={this.state.taxas}
                                    value={this.state.taxas.find(option => option.value === this.state.TaxaId)}
                                    onChange={option => this.setState({TaxaId: option?.value ?? null})}
                                    placeholder="Selecione uma taxa para emitir..."
                                    noOptionsMessage={() => "Sem opções"}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <Form.Group>
                                <Form.Label className="required"><b>Quantidade (em unidades do serviço)</b></Form.Label>
                                <Form.Control type="number" required min={1} step={0.01} value={this.state.quantidade} onChange={e => this.setState({ quantidade: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Form.Group>
                                <Form.Label className="required"><b>Valor Total</b></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>R$</InputGroup.Text>
                                    <Form.Control type="number" step={0.01} value={this.state.valor?.toFixed(2)} readOnly/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-3 d-flex align-items-end">
                            <Button variant="primary" className="form-control" onClick={() => this.calcular()} disabled={!this.state.TaxaId || !this.state.quantidade}>
                                <FontAwesomeIcon icon={faCalculator} /> Calcular
                            </Button>
                        </Col>
                    </Row>
                }
                {this.props.action.cassacao &&
                    <Row>
                        <Col lg={12} className="mb-3">
                            Selecione abaixo os Certificados de Licenciamento estabelecimento que serão cassados:
                        </Col>
                        <Col lg={12}>
                            <ListGroup className="mb-3">
                                {this.state.certificados.map((certificado, key) =>
                                    <ListGroup.Item key={key}
                                        active={this.state.Certificados.find(id => id === certificado.id)}
                                        onClick={() => this.setState(state => ({ Certificados: state.Certificados.indexOf(certificado.id) > -1 ? state.Certificados.filter(id => id !== certificado.id) : [...state.Certificados, certificado.id] }))}>
                                        <ul>
                                            <li>Protocolo: {certificado.ProtocoloEmissaoId.toString().padStart(6, '0')}</li>
                                            <li>Nº Certificado: {certificado.id.toString().padStart(6, '0')}</li>
                                            <li>Tipo: {certificado.tipo}</li>
                                            <li>Emissão: {moment(certificado.data_emissao).format("DD/MM/YYYY")}</li>
                                            <li>Validade: {certificado.data_validade ? moment(certificado.data_validade).format("DD/MM/YYYY") : "Indeterminada"}</li>
                                            <li>Estabelecimento: {certificado.ProtocoloEmissao?.nome_estabelecimento}</li>
                                        </ul>
                                    </ListGroup.Item>)}
                            </ListGroup>
                        </Col>
                    </Row>
                }
                {this.props.action.inscricao &&
                    <Row>
                        <Col lg={12} className="mb-3">
                            Selecione abaixo os Boletos que serão inscritos em dívida ativa:
                        </Col>
                        <Col lg={12} className="mb-3">
                        {this.state.boletos.length?
                            <ListGroup>
                                {this.state.boletos.map((boleto, key) =>
                                    <ListGroup.Item key={key}>
                                        <label className="d-flex align-items-center" role="button">
                                            <Form.Check className="me-3" checked={this.state.Boletos.find(id => id === boleto.id)} onChange={(event) => this.setState(state => ({ Boletos: event.target.checked ? [...state.Boletos, boleto.id] : state.Boletos.filter(id => id !== boleto.id) }))} />
                                            Emissão: {moment(boleto.data_emissao).format("DD/MM/YYYY")}, Vencimento: {moment(boleto.data_vencimento).format("DD/MM/YYYY")}, Valor: {boleto.valor}
                                        </label>
                                    </ListGroup.Item>)}
                            </ListGroup>:
                            <p>Nenhum boleto vencido.</p>}
                        </Col>
                    </Row>
                }
                {this.props.action.anexo &&
                    <Row>
                        <Col className="mb-3">
                            <Button variant="primary" className="form-control" onClick={() => this.uploadRef.current.click()}>
                                {this.state.file ? this.state.file.name : <>
                                    <FontAwesomeIcon icon={faPlus} /> Selecione um arquivo
                                </>}
                            </Button>
                            <input onInvalid={() => window.alert("Selecione um arquivo.")} required type="file" className="d-none" ref={this.uploadRef} onChange={e => this.onUpload(e)} />
                        </Col>
                    </Row>
                }
                {this.props.action.atribuicao && <>
                    <Row>
                        <Col>
                            <Col lg={12} className="mb-3">
                                <Form.Group>
                                    <Form.Label className="required"><b>Encarregado Principal</b></Form.Label>
                                    <ReactSelect
                                        options={this.state.usuarios}
                                        value={this.state.usuarios.find(option => option.value === this.state.UsuarioId)}
                                        onChange={option => this.setState({ UsuarioId: option.value ?? null })}
                                        placeholder="Selecione um encarregado..."
                                        noOptionsMessage={() => "Sem opções"}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12} className="mb-3">
                                <Form.Group>
                                    <Form.Label className="required"><b>Função</b></Form.Label>
                                    <ReactSelect
                                        options={this.state.funcoes}
                                        value={this.state.funcoes.find(option => option.value === this.state.FuncaoId)}
                                        onChange={option => this.setState({ FuncaoId: option.value ?? null })}
                                        placeholder="Selecione uma função..."
                                        noOptionsMessage={() => "Sem opções"}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12} className="mb-3">
                                <Form.Group>
                                    <label className="d-flex justify-content-between">
                                        <b>Atribuição Extraordinária</b>
                                        <Form.Check type="switch" onChange={e => this.setState({ extraordinaria: e.target.checked })} checked={this.state.extraordinaria} />
                                    </label>
                                </Form.Group>
                            </Col>
                            <Col lg={12} className="mb-3">
                                <Form.Group>
                                    <Form.Label><b>Agendamento</b></Form.Label>
                                    <Form.Control type="datetime-local" required
                                        value={this.state.datahora_agendamento?.format("YYYY-MM-DDTHH:mm")}
                                        onChange={e => this.setState({ datahora_agendamento: e.target.value ? moment(e.target.value) : null })} />
                                </Form.Group>
                            </Col>
                            <Col lg={12} className="mb-3">
                                <Form.Group>
                                    <Form.Label><b>Observação</b></Form.Label>
                                    <Form.Control as="textarea"
                                        value={this.state.observacao}
                                        onChange={e => this.setState({ observacao: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className="mb-3">
                            {this.state.Auxiliares.map((auxiliar, key) =>
                                <Card key={key} className="mb-1">
                                    <Card.Header className="w-100 d-flex justify-content-between">
                                        Auxiliar {key + 1}
                                        <Button variant="danger" size="sm" onClick={() => this.setState(state => ({ Auxiliares: state.Auxiliares.filter(a => a !== auxiliar) }))}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group>
                                            <Form.Label className="required"><b>Encarregado Auxiliar</b></Form.Label>
                                            <ReactSelect
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                                options={this.state.usuarios}
                                                value={this.state.usuarios.find(option => option.value === auxiliar.UsuarioId)}
                                                onChange={option => this.setState(state => ({ Auxiliares: state.Auxiliares.map(a => a === auxiliar ? { ...a, UsuarioId: option.value ?? null } : a) }))}
                                                placeholder="Selecione um encarregado..."
                                                noOptionsMessage={() => "Sem opções"}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className="required"><b>Função</b></Form.Label>
                                            <ReactSelect
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                                options={this.state.funcoes}
                                                value={this.state.funcoes.find(option => option.value === auxiliar.FuncaoId)}
                                                onChange={option => this.setState(state => ({ Auxiliares: state.Auxiliares.map(a => a === auxiliar ? { ...a, FuncaoId: option.value ?? null } : a) }))}
                                                placeholder="Selecione uma função..."
                                                noOptionsMessage={() => "Sem opções"}
                                                required
                                            />
                                        </Form.Group>
                                    </Card.Body>
                                </Card>)}
                            <Button className="form-control" variant="info" onClick={() => this.setState(state => ({ Auxiliares: [...state.Auxiliares, { UsuarioId: null, FuncaoId: null }] }))}>
                                <FontAwesomeIcon icon={faPlus} /> Adicionar Auxiliar
                            </Button>
                        </Col>
                    </Row>
                </>}
                <Row>
                    <Col className="mb-3" lg={{ offset: 4, span: 4 }}>
                        <Button className="form-control" variant="light" onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={faTimes} /> Cancelar
                        </Button>
                    </Col>
                    <Col className="mb-3" lg={4}>
                        <Button disabled={this.state.submitting} className="form-control" variant="success" type="submit">
                            <FontAwesomeIcon icon={faCheck} /> Prosseguir
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    }
}

export default ActionForm;