import { Component } from "react";
import AppContext from "../AppContext";
import { Modal } from "react-bootstrap";

class AppModal extends Component {

    static contextType = AppContext;

    render() {
        return <Modal show={this.context.modal.show} onHide={() => this.context.closeModal()} {...this.context.modal.params}>
        <Modal.Header closeButton>
          <Modal.Title>{this.context.modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.context.modal.body}
        </Modal.Body>
      </Modal>;
    }
}

export default AppModal;