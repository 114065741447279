import { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinoculars, faCertificate, faCubes, faIdCard, faPencilAlt } from "@fortawesome/free-solid-svg-icons";


class TipoProtocoloSelect extends Component {
    static contextType = AppContext;

    onSelect(tipo){
        this.context.closeModal();
        this.props.onSelect(tipo);
    }


    render() {
        return <Container>
            {this.props.tipo === "Pessoa Jurídica" &&
            <Button className="form-control mb-3" onClick={() => this.onSelect("Licenciamento")}>
                <FontAwesomeIcon icon={faCertificate} /> Novo Licenciamento
            </Button>}
            <Button className="form-control mb-3" onClick={() => this.onSelect("Credenciamento")}>
                <FontAwesomeIcon icon={faIdCard} /> Novo Credenciamento
            </Button>
            {this.props.tipo === "Pessoa Jurídica" &&
            <Button className="form-control mb-3" onClick={() => this.onSelect("Anotação")}>
                <FontAwesomeIcon icon={faPencilAlt} /> Nova Anotação
            </Button>}
            {this.props.tipo === "Pessoa Jurídica" && this.context.usuario.Recursos.find(r => r === "Adicionar Fiscalização") &&
            <Button className="form-control mb-3" onClick={() => this.onSelect("Fiscalização")}>
                <FontAwesomeIcon icon={faBinoculars} /> Nova Fiscalização
            </Button>}
            {this.context.usuario.classe === "usuario" &&
            <Button className="form-control mb-3" variant="dark" onClick={() => this.onSelect("Outros Serviços")}>
                <FontAwesomeIcon icon={faCubes} /> Outros Serviços
            </Button>}
        </Container >;
    }
}

export default TipoProtocoloSelect;