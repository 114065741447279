import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import AtividadeList from "../lists/AtividadeList";

class AtividadeForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.atividade?.id ?? null,
        descricao: this.props.atividade?.descricao ?? "",
        risco: this.props.atividade?.risco ?? "",
        cnae: this.props.atividade?.cnae ?? "",
        OcupacaoId: this.props.atividade?.OcupacaoId ?? "",

        ocupacoes: [],
    }

    componentDidMount() {
        this.context.request("GET", "/ocupacoes/options").then(ocupacoes => this.setState({ ocupacoes }));
    }

    submit(event) {

        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/atividades/${this.state.id ?? ''}`;

        const {
            id,
            descricao,
            risco,
            cnae,
            OcupacaoId
        } = this.state;

        this.context.request(method, url, {
            id,
            descricao,
            risco,
            cnae,
            OcupacaoId: OcupacaoId ? OcupacaoId : null
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<AtividadeList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Atividade Econômica
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<AtividadeList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={8} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Descrição</b></Form.Label>
                            <Form.Control required value={this.state.descricao} onChange={e => this.setState({ descricao: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Risco</b></Form.Label>
                            <Form.Select required value={this.state.risco} onChange={e => this.setState({ risco: e.target.value })}>
                                <option value="">Selecione...</option>
                                <option value="Nulo">Nulo</option>
                                <option value="Baixo">Baixo</option>
                                <option value="Médio">Médio</option>
                                <option value="Alto">Alto</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={8} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Ocupação</b></Form.Label>
                            <Form.Select required value={this.state.OcupacaoId} onChange={e => this.setState({ OcupacaoId: e.target.value })}>
                                <option value="">Selecione...</option>
                                <option value="0">Selecione...</option>
                                {this.state.ocupacoes.map((ocupacao, key) => <option key={key} value={ocupacao.value}>{ocupacao.label}</option>)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>CNAE</b></Form.Label>
                            <Form.Control required value={this.state.cnae} onChange={e => this.setState({ cnae: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default AtividadeForm;