import { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../AppContext";

class PagamentoForm extends Component {

    static contextType = AppContext;

    render() {
        return <Row>
            <Col>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Identificador</strong></Form.Label>
                    <Form.Control disabled value={this.props.pagamento.id} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Lote</strong></Form.Label>
                    <Form.Control disabled value={this.props.pagamento.LoteId} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Banco</strong></Form.Label>
                    <Form.Control disabled value={this.props.pagamento.banco} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Agência</strong></Form.Label>
                    <Form.Control disabled value={this.props.pagamento.agencia} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Cód. Município</strong></Form.Label>
                    <Form.Control disabled value={this.props.pagamento.codigoMunicipio} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Tipo de Pagamento</strong></Form.Label>
                    <Form.Control disabled value={['Definitivo', 'Provisório'][this.props.pagamento.tipoPagamento-1]} />                    
                </Form.Group>                
                <Form.Group className="mb-2">
                    <Form.Label><strong>Nº de Autenticidade</strong></Form.Label>
                    <Form.Control disabled value={this.props.pagamento.numeroAutenticidade} />                    
                </Form.Group>                
                <Form.Group className="mb-2">
                    <Form.Label><strong>Cód. Barra</strong></Form.Label>
                    <Form.Control disabled value={this.props.pagamento.codigoBArra} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Data de Pagamento</strong></Form.Label>
                    <Form.Control disabled type="date" value={this.props.pagamento.dataPagamento} />                    
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label><strong>Valor Pago</strong></Form.Label>
                    <Form.Control disabled value={this.props.pagamento.valor} />                    
                </Form.Group>
                <div className="d-flex justify-content-between mt-3">
                    <Button variant="secondary" onClick={() => this.context.onExit()}>
                        <FontAwesomeIcon icon={faFastBackward} /> Voltar
                    </Button>
                </div>
            </Col>
        </Row>;
    }
}

export default PagamentoForm;
