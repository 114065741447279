import { Component } from "react";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../AppContext";

class PerfilForm extends Component {

    static contextType = AppContext;

    state = {
        id: this.props.perfil?.id ?? null,
        nome: this.props.perfil?.nome ?? "",
        Recursos: this.props.perfil?.Recursos?.map(recurso => recurso.id) ?? [],

        recursos: []
    }

    componentDidMount() {
        this.context.request("GET", "/recursos/options").then(recursos => this.setState({ recursos }));
    }

    submit(event) {
        event.preventDefault();

        const { Recursos, id, nome } = this.state;

        const method = id? "PUT" : "POST";


        this.context.request(method, `/perfis/${id? id :``}`, { nome, Recursos }).then(() => {
            this.context.addToast({header: "Sucesso", body: "Dados gravados com sucesso."});
            this.props.onSave();
        });

    }

    render() {
        return <Form onSubmit={event => this.submit(event)}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Nome</strong></Form.Label>
                        <Form.Control required value={this.state.nome} onChange={event => this.setState({nome: event.target.value})} />                        
                    </Form.Group>
                    <FormGroup>
                        <h5>Recursos Atribuídos</h5>
                        {this.state.recursos.map((option, key) =>
                            <Form.Check
                                key={key}
                                label={option.label}
                                checked={this.state.Recursos.indexOf(option.value) > -1}
                                onChange={event => this.setState(state => ({
                                    Recursos: event.target.checked ?
                                        [...state.Recursos, option.value] :
                                        state.Recursos.filter(p => p !== option.value)
                                }))}
                            />)}
                    </FormGroup>
                    <div className="d-flex justify-content-between mt-3">
                        <Button variant="secondary" onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={faFastBackward} /> Voltar
                        </Button>
                        <Button variant="primary" type="submit">
                            <FontAwesomeIcon icon={faFloppyDisk} /> Gravar
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>;
    }
}

export default PerfilForm;
