import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import LoteForm from "../forms/LoteForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";

class LoteList extends Component {
    static contextType = AppContext;

    onAction(action) {
        switch (action.name) {
            case "Ver Detalhes":
                this.context.request("GET", "/webservices/sefa/lotes/" + action.id).then(lote =>
                    this.context.openModal({title: "Lote de Pagamento", body: <LoteForm lote={lote} onExit={() => this.context.closeModal()}/>})
                )
                break;
            default:
                break;
        }
    }

    receberPagamentos(){
        this.context.request("GET", "/webservices/sefa/receberPagamentos").then(resultado => {
            this.context.openModal({
                title: "Sincronização Completa",
                body: resultado? <Container>
                    {resultado.lotesTotal} Lotes no Total,
                    {resultado.lotesConfirmados} Lotes confirmados,
                    {resultado.pagamentosConfirmados} pagamentos confirmados.
                </Container> : "Nenhum lote pendente."
            });
        });
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faLayerGroup} /> Pesquisa de Lotes de Pagamento</div>}
            filters={[
                { name: "id", label: "Identificador", span: 6 },
                { name: "valorTotal", label: "Valor Mín.", type: "number", op: "gte", span: 3 },
                { name: "valorTotal", label: "Valor Máx.", type: "number", op: "lte", span: 3 },
                { name: "quantidade", label: "Quantidade Mín.", type: "number", op: "gte", span: 2 },
                { name: "quantidade", label: "Quantidade Máx.", type: "number", op: "lte", span: 2 },
                { name: "datahora", label: "Confirmação Inicial", span: 2, type: "datetime-local", op: "gte" },
                { name: "datahora", label: "Confirmação Final", span: 2, type: "datetime-local", op: "lte" },                
            ]}
            endpoint="/webservices/sefa/lotes"
            onAction={(action) => this.onAction(action)}
            buttonLabel={<><FontAwesomeIcon icon={faRefresh} /> Receber Pagamentos</>}
            onAdd={() => this.receberPagamentos()}/>;
    }
}

export default LoteList;