import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import TaxaForm from "../forms/TaxaForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";

class TaxaList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/taxas/" + action.id).then(taxa =>
                    this.context.setContent(<TaxaForm taxa={taxa} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/taxas/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faHandHoldingDollar} /> Pesquisa de Taxas</div>}
            filters={[
                { name: "descricao", label: "Taxa", span: 12 },
                { name: "tipo", label: "Tipo", span: 8 },
                { name: "risco", label: "Risco", span: 4 },
                { name: "codigo_receita", label: "Cód. Receita", span: 4 },
                { name: "codigo_orgao", label: "Cód. Órgão", span: 6 },
            ]}
            endpoint="/taxas"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<TaxaForm />)} />;
    }
}

export default TaxaList;