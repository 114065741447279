import { Component } from "react";
import AppContext from "../../AppContext";
import Datatable from "../componentes/Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";

class CertificadoList extends Component {
    static contextType = AppContext;

    state = {
        urls: []
    }

    download(blob, filename) {
        return new Promise(resolve => {
            const url = URL.createObjectURL(blob);
            this.setState(state => ({ urls: [...state.urls, url] }), () => {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                resolve();
            });
        });
    }

    componentWillUnmount() {
        this.state.urls.forEach(url => URL.revokeObjectURL(url));
    }

    onAction(action, callback) {
        switch (action.name) {
            case "Baixar":
                this.context.request("GET", `/certificados/${action.id}/download`)
                .then(blob => this.download(blob, `Certificado-CBMPA-${action.id.toString().padStart(6, '0')}.pdf`));                
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faCertificate} /> Pesquisa de Certificados</div>}
            filters={[
                { name: "$ProtocoloEmissao.nome_estabelecimento$", label: "Nome/Estabelecimento", span: 9 },
                { name: "$Cadastro.cnpj_cpf$", label: "CNPJ/CPF", span: 3 },
                { name: "ProtocoloEmissaoId", label: "Protocolo", span: 3 },
                { name: "id", label: "Nº Certificado", span: 3 },
                { name: "tipo", label: "Tipo", span: 3 },
                { name: "$Modalidade.descricao$", label: "Modalidade", span: 3 },
                { name: "data_emissao", label: "Emissão Inicial", span: 2, type: "date", op: "gte" },
                { name: "data_emissao", label: "Emissão Final", span: 2, type: "date", op: "lte" },
                { name: "data_validade", label: "Validade Inicial", span: 2, type: "date", op: "gte" },
                { name: "data_validade", label: "Validade Final", span: 2, type: "date", op: "lte" },
                { name: "data_cassacao", label: "Cassação Inicial", span: 2, type: "date", op: "gte" },
                { name: "data_cassacao", label: "Cassação Final", span: 2, type: "date", op: "lte" },
            ]}
            endpoint="/certificados"
            onAction={(action, callback) => this.onAction(action, callback)} />;
    }
}

export default CertificadoList;