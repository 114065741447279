import { faClipboardCheck, faDollar, faFileInvoiceDollar, faLink, faGlobeAmericas, faLandmark, faStar, faUsers, faChartColumn, faFileLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import AppContext from "../../AppContext";
import SecaoReport from "./SecaoReport";
import ServicoReport from "./ServicoReport";
import FinanceiroReport from "./FinanceiroReport";
import UsuarioReport from "./UsuarioReport";
import RegiaoReport from "./RegiaoReport";
import CertificadoReport from "./CertificadoReport";
import AutuacaoReport from "./AutuacaoReport";
import AtribuicaoReport from "./AtribuicaoReport";
import ExtraordinariaReport from "./ExtraordinariaReport";

class RelatoriosPage extends Component {
    static contextType = AppContext;

    render() {
        return <Container>
            <h2>
                <FontAwesomeIcon icon={faChartColumn} /> Listas de Relatórios
            </h2>
            <Row>
                <Col lg={4} className="mt-3">
                    <Card className="card-item" onClick={() => this.context.setContent(<SecaoReport />)}>
                        <Card.Body className="d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faLandmark} size="3x" />
                            Seções
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item" onClick={() => this.context.setContent(<ServicoReport />)}>
                        <Card.Body className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faClipboardCheck} size="3x" />
                            Serviços
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item" onClick={() => this.context.setContent(<FinanceiroReport />)}>
                        <Card.Body className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faDollar} size="3x" />
                            Financeiro
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={4} className="mt-3">
                    <Card className="card-item" onClick={() => this.context.setContent(<UsuarioReport />)}>
                        <Card.Body className="d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faUsers} size="3x" />
                            Usuários
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item" onClick={() => this.context.setContent(<RegiaoReport />)}>
                        <Card.Body className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faGlobeAmericas} size="3x" />
                            Regiões
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item" onClick={() => this.context.setContent(<CertificadoReport />)}>
                        <Card.Body className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faLink} size="3x" />
                            Certificados
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item" onClick={() => this.context.setContent(<AutuacaoReport />)}>
                        <Card.Body className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faFileInvoiceDollar} size="3x" />
                            Autuações
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item" onClick={() => this.context.setContent(<AtribuicaoReport />)}>
                        <Card.Body className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faStar} size="3x" />
                            Atribuições
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item" onClick={() => this.context.setContent(<ExtraordinariaReport />)}>
                        <Card.Body className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faFileLines} size="3x" />
                            Extraordinárias
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }
}

export default RelatoriosPage;