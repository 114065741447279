import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MedidaList from "../lists/MedidaList";

class MedidaForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.medida?.id ?? null,
        descricao: this.props.medida?.descricao ?? "",       
    }

    submit(event) {
        
        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/medidas/${this.state.id ?? ''}`;

        const {
            id,
            descricao
        } = this.state;

        this.context.request(method, url, {
            id,
            descricao
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<MedidaList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Medida de Proteção
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<MedidaList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Descrição</b></Form.Label>
                            <Form.Control required value={this.state.descricao} onChange={e => this.setState({ descricao: e.target.value })} />
                        </Form.Group>
                    </Col>                
                </Row>
            </Form >
        </Container >;
    }
}

export default MedidaForm;