import { faFloppyDisk, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Button } from "react-bootstrap";

class SubmitButton extends Component {
    render(){
        return <Button className={this.props.className} type="submit" hidden={this.props.hidden} variant="primary" disabled={this.props.hidden || this.props.disabled || this.props.submitting}>
            {this.props.submitting? <>
                <FontAwesomeIcon icon={faSpinner} spin /> Aguarde
            </> :
            <>
                <FontAwesomeIcon icon={faFloppyDisk} /> Enviar
            </>}
        </Button>
    }
}

export default SubmitButton;