import { Component } from "react";
import AppContext from "../AppContext";
import { Button, Col, Form, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faTimes } from "@fortawesome/free-solid-svg-icons";

class UsuarioPage extends Component {

    static contextType = AppContext;

    state = {
        dias: "",
        pontos: "",
        extras: ""
    }

    componentDidMount() {
        if (this.context.usuario.classe === "usuario")
            this.context.request("GET", "/relatorios/extraordinaria/usuario").then(resultados => {
                if (resultados.length)
                    this.setState({
                        ...resultados[0]
                    });
            });
    }

    render() {
        return <Row>
            <Col>
                <Form.Group>
                    <Form.Label><strong>Nome</strong></Form.Label>
                    <Form.Control disabled value={this.context.usuario.nome} />
                    {this.context.usuario.Unidade ? <>
                        <Form.Label className="mt-3"><strong>Unidade</strong></Form.Label>
                        <Form.Control disabled value={this.context.usuario.Unidade.nome} /></> : null}
                    <Form.Label className="mt-3"><strong>Email</strong></Form.Label>
                    <Form.Control disabled value={this.context.usuario.email} />
                    {this.context.usuario.classe === "usuario" ? <>
                        <Form.Label className="mt-3"><strong>Pontuação do Mês</strong></Form.Label>
                        <Form.Control disabled value={this.state.pontos} />
                        <Form.Label className="mt-3"><strong>Dias Trabalhados</strong></Form.Label>
                        <Form.Control disabled value={this.state.dias} />
                        <Form.Label className="mt-3"><strong>Jornadas Computadas</strong></Form.Label>
                        <Form.Control disabled value={this.state.extras} />
                    </> : null}
                    {this.context.usuario.Recursos.length ? <>
                        <Form.Label className="mt-3"><strong>Recursos</strong></Form.Label>
                        <ListGroup>
                            {this.context.usuario.Recursos.map((recurso, key) => <ListGroupItem>{recurso}</ListGroupItem>)}
                        </ListGroup>
                    </> : null}
                    {this.context.usuario.Vinculantes.length ? <>
                        <Form.Label className="mt-3"><strong>Vinculantes</strong></Form.Label>
                        <ListGroup>
                            {this.context.usuario.Vinculantes.map((vinculante, key) => <ListGroupItem>{vinculante.nome_razaosocial} - {vinculante.cnpj_cpf}</ListGroupItem>)}
                        </ListGroup>
                    </> : null}
                </Form.Group>
                <div className="d-flex justify-content-between mt-3">
                    <Button variant="secondary" onClick={() => this.context.closeModal()}>
                        <FontAwesomeIcon icon={faTimes} /> Fechar
                    </Button>
                    <Button variant="outline-danger" onClick={() => this.context.logoff()}>
                        <FontAwesomeIcon icon={faSignOut} /> Sair
                    </Button>
                </div>
            </Col>
        </Row>;
    }
}

export default UsuarioPage;
