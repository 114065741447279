import React, { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";


class BoletoConfirmacaoForm extends Component {

    static contextType = AppContext;

    state = {
        file: null,
        motivo: ""
    }

    uploadRef = React.createRef();

    onUpload(event) {
        const { files } = event.target;
        this.setState({ file: files.length ? files[0] : null });
    }

    onSubmit(e) {
        e.preventDefault();
        const data = new FormData();
        data.append("arquivo", this.state.file);
        data.append("motivo", this.state.motivo);
        this.props.onConfirm(data);
    }

    render() {
        return <Container>
            <Form onSubmit={e => this.onSubmit(e)}>
                <Row>
                    <Col lg={12} className="mb-3 text-justify">
                        Ao realizar esta operação, o usuário confirma que o documento
                        anexado abaixo comprova o pagamento do valor correspondente de maneira definitiva,
                        arcando com as devidas responsabilidades civis e penais em caso de falsidade ideológica ou fraude,
                        por prejuízo ao erário.
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Motivo</b></Form.Label>
                            <textarea required className="form-control" value={this.state.motivo} onChange={e => this.setState({ motivo: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <Button variant="primary" className="form-control" onClick={() => this.uploadRef.current.click()}>
                            {this.state.file ? this.state.file.name : <>
                                <FontAwesomeIcon icon={faPlus} /> Selecione um arquivo
                            </>}
                        </Button>
                        <input required onInvalid={() => window.alert("Selecione uma arquivo para envio.")} type="file" className="d-none" ref={this.uploadRef} onChange={e => this.onUpload(e)} />
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3" lg={{ offset: 4, span: 4 }}>
                        <Button className="form-control" variant="light" onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={faTimes} /> Cancelar
                        </Button>
                    </Col>
                    <Col className="mb-3" lg={4}>
                        <Button className="form-control" variant="success" type="submit">
                            <FontAwesomeIcon icon={faCheck} /> Prosseguir
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    }
}

export default BoletoConfirmacaoForm;