import React, { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";


class BoletoDescontoForm extends Component {

    static contextType = AppContext;

    state = {
        file: null,
        desconto: false
    }

    uploadRef = React.createRef();

    onUpload(event) {
        const { files } = event.target;
        this.setState({ file: files.length ? files[0] : null });
    }

    onSubmit(e) {
        e.preventDefault();
        const data = new FormData();
        data.append("arquivo", this.state.file);
        data.append("descricao", "Comprovante de Direito a Desconto");
        data.append("desconto", this.state.desconto);
        this.props.onConfirm(data);
    }

    render() {
        return <Container>
            <Form onSubmit={e => this.onSubmit(e)}>
                <Row>
                    <Col lg={12} className="mb-3 text-justify">
                        Ao realizar esta operação, o usuário confirma que o solicitante possui direito ao desconto previsto
                        em legislação, comprovado pela documentação anexa abaixo:
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mb-3 text-justify">
                        <ListGroup>
                        <ListGroup.Item><Form.Check required name="desconto" onClick={() => this.setState({desconto: "pmcmv1"})} checked={this.state.desconto==="pmcmv1"} type="radio" label={<b>Empreendimento do FAR/FDS integrante do Programa Minha Casa Minha Vida - 75%</b>} /></ListGroup.Item>
                        <ListGroup.Item><Form.Check required name="desconto" onClick={() => this.setState({desconto: "pmcmv2"})} checked={this.state.desconto==="pmcmv2"} type="radio" label={<b>Outros empreendimentos do Programa Minha Casa Minha Vida - 50%</b>} /></ListGroup.Item>
                        <ListGroup.Item><Form.Check required name="desconto" onClick={() => this.setState({desconto: "sprinkler"})} checked={this.state.desconto==="sprinkler"} type="radio" label={<b>Empreendimento optante pela instalação voluntária de Sistema de Proteção por Sprinklers - 75%</b>} /></ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <Button variant="primary" className="form-control" onClick={() => this.uploadRef.current.click()}>
                            {this.state.file ? this.state.file.name : <>
                                <FontAwesomeIcon icon={faPlus} /> Selecione um arquivo
                            </>}
                        </Button>
                        <input required onInvalid={() => window.alert("Selecione uma arquivo para envio.")} type="file" className="d-none" ref={this.uploadRef} onChange={e => this.onUpload(e)} />
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3" lg={{ offset: 4, span: 4 }}>
                        <Button className="form-control" variant="light" onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={faTimes} /> Cancelar
                        </Button>
                    </Col>
                    <Col className="mb-3" lg={4}>
                        <Button className="form-control" variant="success" type="submit">
                            <FontAwesomeIcon icon={faCheck} /> Prosseguir
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    }
}

export default BoletoDescontoForm;