import { Component } from "react";
import AppContext from "../../AppContext";
import Datatable from "../componentes/Datatable";
import AtribuicaoForm from "./AtribuicaoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import AtribuicaoExecucaoForm from "./AtribuicaoExecucaoForm";

class AtribuicaoList extends Component {
    static contextType = AppContext;
    onAction(action, callback) {
        switch (action.name) {
            case "Ver Detalhes":
                this.context.request("GET", `/atribuicoes/${action.id}/execucao`).then(atribuicao =>
                    this.context.setContent(<AtribuicaoExecucaoForm atribuicao={atribuicao}
                        onExit={() => this.context.setContent(<AtribuicaoList />)}
                    />));
                break;
            case "Editar":
                this.context.openModal({
                    title: "Ver Detalhes",
                    body: <AtribuicaoForm AtribuicaoId={action.id}
                        onCancel={() => this.context.closeModal()}
                        onConfirm={atribuicao => this.context.request("PUT", `/atribuicoes/${action.id}`, atribuicao).then(() => {
                            this.context.closeModal();
                            callback();
                        })}
                    />
                })
                break;
                case "Preencher Relatório":
                    this.context.openModal({
                        title: "Preencher Relatório",
                        body: <AtribuicaoForm AtribuicaoId={action.id}
                            onCancel={() => this.context.closeModal()}
                            onConfirm={atribuicao => this.context.request("PUT", `/atribuicoes/${action.id}`, atribuicao).then(() => {
                                this.context.closeModal();
                                callback();
                            })}
                        />
                    })
                    break;                
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/atribuicoes/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faStar} /> Pesquisa de Atribuições</div>}
            filters={[
                { name: "ProtocoloId", label: "Protocolo", span: 3 },
                { name: "$Usuario.nome$", label: "Usuário", span: 6 },
                { name: "$Funcao.descricao$", label: "Função", span: 3 },
                { name: "datahora_agendamento", label: "Agendamento Inicial", span: 3, type: "date", op: "gte" },
                { name: "datahora_agendamento", label: "Agendamento Final", span: 3, type: "date", op: "lte" },
                { name: "datahora_execucao", label: "Execução Inicial", span: 3, type: "date", op: "gte" },
                { name: "datahora_execucao", label: "Execução Final", span: 3, type: "date", op: "lte" },
            ]}
            endpoint="/atribuicoes"
            onAction={(action, callback) => this.onAction(action, callback)} />;
    }
}

export default AtribuicaoList;