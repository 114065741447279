import { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingShield, faChartColumn, faCog, faFile, faLink, faFolderOpen, faSignOut, faStar, faCertificate } from '@fortawesome/free-solid-svg-icons';
import AppContext from "../AppContext";
import CadastroList from "./cadastros/CadastroList";
import HomePage from "./HomePage";
import RelatoriosPage from "./relatorios/RelatoriosPage";
import AdministracaoPage from "./administracao/AdministracaoPage";
import ProtocoloList from "./protocolos/ProtocoloList";
import VinculoList from "./vinculos/VinculoList";
import AtribuicaoList from "./atribuicoes/AtribuicaoList";
import CertificadoList from "./certificados/CertificadoList";

class AppMenu extends Component {

    static contextType = AppContext;

    render() {
        return <div id="app-menu" className={"bg-dark text-white d-flex flex-column d-print-none" + (this.context.menuClosed ? 'closed' : '')}>
            <h2 role="button" id="menu-header" className="bg-black" onClick={() => this.context.setContent(<HomePage />)}>
                <FontAwesomeIcon icon={faBuildingShield} className="menu-icon" />
                <div className="hideOnClosed">SISGAT</div>
            </h2>
            <div id="menu-body" className="d-flex flex-column flex-fill">
                <div className="d-flex align-items-center justify-content-start menu-item" onClick={() => this.context.setContent(<CadastroList />)} title="Cadastros">
                    <FontAwesomeIcon className="menu-icon" icon={faFolderOpen} /><div className="hideOnClosed">Cadastros</div>
                </div>
                <div className="d-flex align-items-center justify-content-start menu-item" onClick={() => this.context.setContent(<ProtocoloList />)} title="Protocolos">
                    <FontAwesomeIcon className="menu-icon" icon={faFile} /><div className="hideOnClosed">Protocolos</div>
                </div>
                {this.context.usuario.Recursos.find(r => r === "Listar Atribuições") && <div className="d-flex align-items-center justify-content-start menu-item" onClick={() => this.context.setContent(<AtribuicaoList />)} title="Atribuições">
                    <FontAwesomeIcon className="menu-icon" icon={faStar} /><div className="hideOnClosed">Atribuições</div>
                </div>}
                <div className="d-flex align-items-center justify-content-start menu-item" onClick={() => this.context.setContent(<CertificadoList />)} title="Certificados">
                    <FontAwesomeIcon className="menu-icon" icon={faCertificate} /><div className="hideOnClosed">Certificados</div>
                </div>
                {(this.context.usuario.classe === "cadastro" || this.context.usuario.Recursos.find(r => r === "Listar Vínculos")) && <div className="d-flex align-items-center justify-content-start menu-item" onClick={() => this.context.setContent(<VinculoList />)} title="Vínculos">
                    <FontAwesomeIcon className="menu-icon" icon={faLink} /><div className="hideOnClosed">Vínculos</div>
                </div>}
                {this.context.usuario.Recursos.find(r => r === "Listar Relatórios") && <div className="d-flex align-items-center justify-content-start menu-item" onClick={() => this.context.setContent(<RelatoriosPage />)} title="Relatórios">
                    <FontAwesomeIcon className="menu-icon" icon={faChartColumn} /><div className="hideOnClosed">Relatórios</div>
                </div>}
                {this.context.usuario.Recursos.find(r => r === "Administrar Sistema") && <div className="d-flex align-items-center justify-content-start menu-item" onClick={() => this.context.setContent(<AdministracaoPage />)} title="Administração">
                    <FontAwesomeIcon className="menu-icon" icon={faCog} /><div className="hideOnClosed">Administração</div>
                </div>}
                <div className="d-flex align-items-center justify-content-start menu-item text-danger" title="Sair" onClick={() => this.context.logoff()}>
                    <FontAwesomeIcon className="menu-icon" icon={faSignOut} /><div className="hideOnClosed">Sair</div>
                </div>
            </div>
        </div>;
    }
}

export default AppMenu;