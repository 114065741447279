import { Component } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import AppContext from "../../AppContext";
import { faFastBackward, faFileCsv, faFileLines, faFilePdf, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from 'react-select';
import RelatoriosPage from "./RelatoriosPage";


class ExtraordinariaReport extends Component {

    static contextType = AppContext;

    state = {
        data_inicial: "",
        data_final: "",
        unidades: [],
        unidadesSelecionadas: [],
        resultados: [],
        resultadosSelecionados: []
    }

    componentDidMount() {
        this.context.request("GET", "/unidades/options").then(unidades => this.setState({ unidades }));
    }

    download(blob, filename) {
        console.log(blob);
        return new Promise(resolve => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            resolve();
        });
    }

    dowloadCSV() {
        this.context.request("POST", `/relatorios/csv`, { data: this.state.resultadosSelecionados }).then(blob => this.download(blob, "Relatório.csv"));
    }

    dowloadPDF() {
        this.context.request("POST", `/relatorios/pdf`, { 
            title: "Relatório de Extraordinárias", 
            rows: this.state.resultadosSelecionados,
            unidades: this.state.unidadesSelecionadas,
            data_inicial: this.state.data_inicial,
            data_final: this.state.data_final 
        }).then(blob => this.download(blob, "Relatório.pdf"));
    }

    onSubmit(e) {
        e.preventDefault();
        const { data_inicial, data_final, unidadesSelecionadas } = this.state;
        this.context.request("GET", `/relatorios/extraordinaria?data_inicial=${data_inicial}&data_final=${data_final}&${unidadesSelecionadas.map(option => `unidades=${option.value}`).join(`&`)}`).then(resultados => this.setState({ resultados, resultadosSelecionados: resultados }))
    }

    render() {

        const moeda = valor => new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(valor);

        return <Container>
            <h2><FontAwesomeIcon icon={faFileLines} /> Relatório de Extraordinárias</h2>
            <Form onSubmit={e => this.onSubmit(e)}>
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label><b>Unidades</b></Form.Label>
                            <ReactSelect
                                options={this.state.unidades.filter(option => this.context.usuario.Recursos?.find(r => r === "Administrar Unidades") || option.value === this.context.usuario.UnidadeId)}
                                value={this.state.unidadesSelecionadas}
                                onChange={options => this.setState(({ unidadesSelecionadas: options }))}
                                placeholder="(Todas as Unidades)"
                                isClearable={true}
                                isMulti={true}
                                noOptionsMessage={() => "Sem opções"}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mb-3">
                        <Form.Group>
                            <Form.Label className="required"><b>Data Inicial</b></Form.Label>
                            <Form.Control type="date" required
                                value={this.state.data_inicial}
                                onChange={e => this.setState({ data_inicial: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mb-3">
                        <Form.Group>
                            <Form.Label className="required"><b>Data Final</b></Form.Label>
                            <Form.Control type="date" required
                                value={this.state.data_final}
                                onChange={e => this.setState({ data_final: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group>
                            <Form.Label><b>&nbsp;</b></Form.Label>
                            <Button className="form-control" variant="primary" type="submit">
                                <FontAwesomeIcon icon={faSearch} /> Pesquisar
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Table responsive>
                <thead>
                    <tr>
                        <th style={{ width: "1%" }}><Form.Check onChange={e => this.setState(state => ({ resultadosSelecionados: state.resultados.filter(() => e.target.checked) }))} /></th>
                        <th>Nome</th>
                        <th>Matrícula</th>
                        <th style={{ width: "1%" }}>Unidade</th>
                        <th style={{ width: "1%" }}>Referência</th>
                        <th style={{ width: "1%" }}>Pontos</th>
                        <th style={{ width: "1%" }}>Dias</th>
                        <th style={{ width: "1%" }}>Extras</th>
                        <th style={{ width: "1%" }}>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.resultados.length ? this.state.resultados.map((resultado, key) =>
                        <tr key={key}>
                            <td><Form.Check checked={this.state.resultadosSelecionados.indexOf(resultado) > -1} onChange={e => this.setState(state => ({ resultadosSelecionados: this.state.resultados.filter(r => r === resultado ? e.target.checked : state.resultadosSelecionados.indexOf(r) > -1) }))} /></td>
                            <td>{resultado.usuario_nome}</td>
                            <td>{resultado.usuario_matricula}</td>
                            <td className="text-nowrap">{resultado.unidade_sigla}</td>
                            <td className="text-nowrap">{resultado.referencia}</td>
                            <td className="text-nowrap">{resultado.pontos}</td>
                            <td className="text-nowrap">{resultado.dias.split(', ').filter((_, i) => i < resultado.extras).join(', ')}</td>
                            <td className="text-nowrap">{resultado.extras}</td>
                            <td className="text-nowrap">{moeda(resultado.valor)}</td>
                        </tr>) : <tr><td colSpan={9} className="text-center">Nenhum resultado encontrado</td></tr>}
                </tbody>
            </Table>
            <Row>
                <Col lg={3}>
                    <Form.Group>
                        <Form.Label><b>&nbsp;</b></Form.Label>
                        <Button className="form-control" variant="secondary" onClick={() => this.context.setContent(<RelatoriosPage />)}>
                            <FontAwesomeIcon icon={faFastBackward} /> Voltar
                        </Button>
                    </Form.Group>
                </Col>
                <Col lg={{ span: 3, offset: 3 }}>
                    <Form.Group>
                        <Form.Label><b>&nbsp;</b></Form.Label>
                        <Button className="form-control" variant="primary" onClick={() => this.dowloadPDF()}>
                            <FontAwesomeIcon icon={faFilePdf} /> Baixar PDF
                        </Button>
                    </Form.Group>
                </Col>
                <Col lg={3}>
                    <Form.Group>
                        <Form.Label><b>&nbsp;</b></Form.Label>
                        <Button className="form-control" variant="primary" onClick={() => this.dowloadCSV()}>
                            <FontAwesomeIcon icon={faFileCsv} /> Baixar CSV
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </Container>;
    }
}

export default ExtraordinariaReport;