import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import AtividadeForm from "../forms/AtividadeForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

class AtividadeList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/atividades/" + action.id).then(atividade =>
                    this.context.setContent(<AtividadeForm atividade={atividade} />)
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/atividades/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faBriefcase} /> Pesquisa de Atividades Econômicas</div>}
            filters={[
                { name: "descricao", label: "Atividade", span: 8 },
                { name: "risco", label: "Risco", span: 4, options: ["Nulo", "Baixo", "Médio", "Alto"] },
                { name: "Ocupacao.descricao", label: "Ocupação", span: 4 },
                { name: "cnae", label: "CNAE", span: 4 },
            ]}
            endpoint="/atividades"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<AtividadeForm />)} />;
    }
}

export default AtividadeList;