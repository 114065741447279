import { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFastBackward, faMessage, faPaperPlane, faUser } from "@fortawesome/free-solid-svg-icons";

class RecoverPassword extends Component {

    static contextType = AppContext;

    state = {
        email: "",
        cnpj_cpf: "",
        submitting: false
    }



    submit(event) {
        event.preventDefault();
        this.setState({ submitting: true }, async () => {
            const captcha = await this.context.getCaptcha();
            const { email, cnpj_cpf } = this.state;
            this.context.request("POST", "/cadastros/startRecover", { email, cnpj_cpf, captcha }, () => this.setState({ submitting: false }))
                .then(() => {
                    this.context.addToast({ header: "Sucesso", body: "Dados enviados.  Caso a informação esteja correta, você receberá um email com as instruções para recuperação da senha." });
                    this.props.onExit();
                })
        });

    }

    render() {
        return <Form onSubmit={event => this.submit(event)}>
            <div className="card card-body bg-light mb-3">
                Digite abaixo o seu CNPJ/CPF e o endereço de email cadastrado.
                Caso a informação esteja correta, você receberá
                um email com as instruções para recuperação da senha.
            </div>
            <Form.Group className="mb-3">
                <Form.Label><b>CNPJ/CPF</b></Form.Label>
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                    <Form.Control required value={this.state.cnpj_cpf} onChange={event => this.setState({ cnpj_cpf: event.target.value })} placeholder="Digite o CNPJ/CPF" />
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label><b>Email</b></Form.Label>
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                    <Form.Control required type="email" value={this.state.email} onChange={event => this.setState({ email: event.target.value })} placeholder="Digite o email" />
                </InputGroup>
            </Form.Group>
            <div className="d-flex justify-content-between">
                <Button variant="secondary">
                    <FontAwesomeIcon icon={faFastBackward} onClick={() => this.props.onExit()} /> Voltar
                </Button>
                <Button disabled={this.state.submitting} variant="primary" type="submit">
                    <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                </Button>
            </div>
        </Form>;
    }
}

export default RecoverPassword;