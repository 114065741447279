import { faBars, faBell, faEnvelope, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Badge, Button, Form, Navbar } from "react-bootstrap";
import AppContext from "../AppContext";
import UsuarioPage from "./UsuarioPage";
import MessagesPage from "./MessagesPage";
import NotificationsPage from "./NotificationsPage";

class AppHeader extends Component {
    static contextType = AppContext;

    render() {
        return <Navbar sticky id="app-header" className="bg-white d-print-none sticky-top">
            <Form className="w-100 d-flex align-items-center">
                <Button className="me-2 d-none d-lg-block" variant="outline-primary" onClick={() => this.context.toggleMenu()}>
                    <FontAwesomeIcon icon={faBars} />
                </Button>                
                <div className="ms-auto d-flex align-items-center text-primary">
                    {this.context.usuario.classe === "usuario" && <>
                    <div role="button" title="Minhas Atribuições" className="d-flex align-items-center me-2" onClick={() => this.context.setContent(<NotificationsPage />)}>
                        <FontAwesomeIcon icon={faBell} size="2x" />
                    </div>
                    <div role="button" title="Mensagens" className="d-flex align-items-center me-2" onClick={() => this.context.setContent(<MessagesPage />)}>
                        <FontAwesomeIcon icon={faEnvelope} size="2x" />
                    </div>
                    </>}
                    <Badge pill role="button" className="p-2 d-flex align-items-center" onClick={() => this.context.openModal({title: "Dados do Usuário", body: <UsuarioPage />})}>
                        <FontAwesomeIcon size="2x" icon={faUserCircle} />
                        <span className="d-none d-lg-inline ms-3">{this.context.usuario.nome}</span>
                    </Badge>
                </div>
            </Form>
        </Navbar>;
    }
}

export default AppHeader;