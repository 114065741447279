
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Card, Collapse } from "react-bootstrap";

class CardCollapse extends Component {
    render() {
        return <Card className="app-card mb-3 sticky-top" style={{top: 60}}>
            <Card.Header className="mb-1 d-flex justify-content-between align-items-center" onClick={() => this.props.onToggle(!this.props.open)}>
                {this.props.title ?? <i />}
                <FontAwesomeIcon icon={faChevronRight} style={{ transition: "transform 500ms" }} rotation={this.props.open ? 90 : 0} />
            </Card.Header>
            <Collapse in={this.props.open}>
                <div>
                    <Card.Body>{this.props.children}</Card.Body>
                </div>
            </Collapse>
        </Card>;
    }
}

export default CardCollapse;
