import { Component } from "react";
import { Badge, Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../AppContext";
import UnidadeList from "../lists/UnidadeList";
import HomePage from "../../HomePage";
import AdministracaoPage from "../AdministracaoPage";

class UnidadeForm extends Component {

    static contextType = AppContext;

    state = {
        id: this.props.unidade.id ?? null,
        nome: this.props.unidade?.nome ?? "",
        sigla: this.props.unidade?.sigla ?? "",
        Bairros: this.props.unidade?.Bairros ?? [],
        cidade: null,
        cidades: [],
        bairros: []
    }

    componentDidMount() {
        this.context.request("GET", "/cidades/options").then(cidades => this.setState({ cidades }));
    }

    submit(e) {
        e.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/unidades/${this.state.id ?? ''}`;
        const { Bairros } = this.state;
        this.context.request(method, url, { Bairros })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<UnidadeList />, [{ title: "Início", content: <HomePage /> }, { title: "Administração", content: <AdministracaoPage /> }, { title: "Unidades" }]);
            })
            .catch(error => {
                this.context.addToast({ header: "Erro", body: "Houve um erro na operação: " + error.toString() });
            });
    }

    setCidade(cidade) {
        this.context.request("GET", "/cidades/" + cidade.value + "/bairros/options").then(bairros => this.setState({ cidade, bairros }));
    }

    checkAll(cidade) {
        this.context.request("GET", "/cidades/" + cidade.value + "/bairros/options").then(bairros => {
            this.setState(state => {
                const Bairros = [...state.Bairros];
                bairros.forEach(bairro => {
                    if (!Bairros.find(b => b.id === bairro.value)) {
                        Bairros.push({ id: bairro.value, CidadeId: bairro.CidadeId });
                    }
                })
                return { bairros, Bairros };
            })
        });
    }

    uncheckAll(cidade) {
        this.context.request("GET", "/cidades/" + cidade.value + "/bairros/options").then(bairros => {
            this.setState(state => {
                const Bairros = state.Bairros.filter(b => b.CidadeId !== cidade.value);
                return { bairros, Bairros };
            })
        });
    }

    toggle(bairro, checked) {
        this.setState(state => ({
            Bairros: checked ?
                [...state.Bairros, { id: bairro.value, CidadeId: bairro.CidadeId }] :
                state.Bairros.filter(b => b.id !== bairro.value)
        }));
    }

    render() {
        return <Container>
            <Form className="app-form mt-3" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Unidade
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<UnidadeList />, [{ title: "Início", content: <HomePage /> }, { title: "Administração", content: <AdministracaoPage /> }, { title: "Unidades" }])}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={9} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Nome</b></Form.Label>
                            <Form.Control disabled value={this.state.nome} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Sigla</b></Form.Label>
                            <Form.Control disabled value={this.state.sigla} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <h4>Região de Atendimento</h4>
                    </Col>
                    <Col lg={6} className="mt-3">
                        <h5>Cidades</h5>
                        <div className="mt-3" style={{ maxHeight: 400, overflowY: "auto" }}>
                            <ListGroup>
                                {this.state.cidades.map((cidade, key) =>
                                    <ListGroup.Item key={key} className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {cidade.label}
                                            &nbsp;
                                            <Badge>
                                                {this.state.Bairros.filter(b => b.CidadeId === cidade.value).length}
                                            </Badge>
                                        </div>
                                        <div className="text-nowrap">
                                            <Button size="sm" variant="outline-info" onClick={() => this.setCidade(cidade)}>
                                                <FontAwesomeIcon icon={faSearch} />
                                            </Button>
                                            &nbsp;
                                            <Button size="sm" variant="outline-success" onClick={() => this.checkAll(cidade)}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </Button>
                                            &nbsp;
                                            <Button size="sm" variant="outline-danger" onClick={() => this.uncheckAll(cidade)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </Button>
                                        </div>
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </div>
                    </Col>
                    <Col lg={6} className="mt-3">
                        <h5>Bairros {this.state.cidade ? " de " + this.state.cidade.label : ""}</h5>
                        <div className="mt-3" style={{ maxHeight: 400, overflowY: "auto" }}>
                            <ListGroup>
                                {this.state.bairros.map((bairro, key) =>
                                    <ListGroup.Item key={key} className="d-flex justify-content-between align-items-center">
                                        <input type="checkbox" checked={this.state.Bairros.find(b => b.id === bairro.value)} onChange={e => this.toggle(bairro, e.target.checked)} /> {bairro.label}
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </div>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default UnidadeForm;
