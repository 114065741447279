import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import RevendaglpList from "../lists/RevendaglpList";

class RevendaglpForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.revendaglp?.id ?? null,
        descricao: this.props.revendaglp?.descricao ?? "",
        classe: this.props.revendaglp?.classe ?? "0",
        min: this.props.revendaglp?.min ?? "0",
        max: this.props.revendaglp?.max ?? "0",
    }

    submit(e) {
        e.preventDefault();
        const method = this.state.id ? "PUT" : "POST";
        const url = `/revendaglps/${this.state.id ?? ''}`;
        const data = this.state;
        this.context.request(method, url, data)
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<RevendaglpList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form mt-3" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Revenda de GLP
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<RevendaglpList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={9} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Descrição</b></Form.Label>
                            <Form.Control required value={this.state.descricao} onChange={e => this.setState({descricao: e.target.value})}/>
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Classe</b></Form.Label>
                            <Form.Control type="number" min="0" step="1" required value={this.state.classe} onChange={e => this.setState({classe: e.target.value})}/>
                        </Form.Group>
                    </Col>                    
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Mínimo (Kg)</b></Form.Label>
                            <Form.Control type="number" min="0" step="1" required value={this.state.min} onChange={e => this.setState({min: e.target.value})}/>
                        </Form.Group>
                    </Col>                    
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Máximo (Kg)</b></Form.Label>
                            <Form.Control type="number" min="0" step="1" required value={this.state.max} onChange={e => this.setState({max: e.target.value})}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default RevendaglpForm;