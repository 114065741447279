import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import PagamentoForm from "../forms/PagamentoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

class PagamentoList extends Component {
    static contextType = AppContext;

    onAction(action) {
        switch (action.name) {
            case "Ver Detalhes":
                this.context.request("GET", "/webservices/sefa/pagamentos/" + action.id).then(pagamento =>
                    this.context.openModal({title: "Pagamento", body: <PagamentoForm pagamento={pagamento} onExit={() => this.context.closeModal()}/>})
                )
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faLayerGroup} /> Pesquisa de Pagamentos</div>}
            filters={[
                { name: "Nº DAE", label: "idDocumento", span: 4 },
                { name: "numeroAutenticidade", label: "Nº Autenticidade", span: 4 },            
                { name: "valorPagamento", label: "Valor Mín.", type: "number", op: "gte", span: 2 },
                { name: "valorPagamento", label: "Valor Máx.", type: "number", op: "lte", span: 2 },
                { name: "dataPagamento", label: "Data Inicial", span: 2, type: "date", op: "gte" },
                { name: "dataPagamento", label: "Data Final", span: 2, type: "date", op: "lte" },
                { name: "codigoBarra", label: "Cód. Barra", span: 4 },                
            ]}
            endpoint="/webservices/sefa/pagamentos"
            onAction={(action) => this.onAction(action)}/>
    }
}

export default PagamentoList;