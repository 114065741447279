import { Component } from "react";
import AppContext from "../../AppContext";
import Datatable from "../componentes/Datatable";
import VinculoForm from "./VinculoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faLink } from "@fortawesome/free-solid-svg-icons";

class VinculoList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Ver Detalhes":
                this.context.request("GET", "/vinculos/" + action.id).then(vinculo =>
                    this.context.setContent(<VinculoForm vinculo={vinculo} />)
                )
                break;
            case "Confirmar Vínculo":
                if (window.confirm("Deseja realmente confirmar a assinatura do vínculo com ID " + action.id + "?"))
                    this.context.request("PATCH", "/vinculos/" + action.id + "/confirm").then(() => {
                window.alert("Confirmação efetuada com sucesso. Para recarregar as permissões, faça um novo logon no sistema.")
                }).finally(callback);
                break;
            case "Revogar Vínculo":
                if (window.confirm("Deseja realmente revogar a assinatura do vínculo com ID " + action.id + "?"))
                    this.context.request("PATCH", "/vinculos/" + action.id + "/revoke").then(() => {
                        window.alert("Revogação efetuada com sucesso. Você será desconectado para recarregamento das permissões. Por favor, realize um novo acesso.");
                        this.context.logoff();
                        })
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/vinculos/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faLink} /> Pesquisa de Vínculos</div>}
            filters={[
                { name: "$Vinculante.nome_razaosocial$", label: "Vinculante", span: 4 },
                { name: "$Vinculado.nome_razaosocial$", label: "Vinculado", span: 4 },
                { name: "$Modalidade.descricao$", label: "Modalidade", span: 4 },
                { name: "confirmado", label: "Confirmado", options: [{ value: "1", label: "Sim" }, { value: "0", label: "Não" }], span: 3 },
                { name: "revogado", label: "Revogado", options: [{ value: "1", label: "Sim" }, { value: "0", label: "Não" }], span: 3 }
            ]}
            endpoint="/vinculos"
            onAction={(action, callback) => this.onAction(action, callback)}
            buttonLabel={<><FontAwesomeIcon icon={faAdd} /> Adicionar Vínculo</>}
            onAdd={
                this.context.usuario.classe === "cadastro" ||
                    this.context.usuario.Recursos.find(r => r === "Adicionar Vínculo") ?
                    () => this.context.setContent(<VinculoForm />) :
                    false
                }
        />;
    }
}

export default VinculoList;