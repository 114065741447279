import { Component } from "react";
import AppContext from "../../AppContext";
import Datatable from "../componentes/Datatable";
import CadastroForm from "./CadastroForm";
import ProtocoloList from "../protocolos/ProtocoloList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faPlus } from "@fortawesome/free-solid-svg-icons";
import TipoProtocoloSelect from "../protocolos/TipoProtocoloSelect";
import ProtocoloForm from "../protocolos/ProtocoloForm";

class CadastroList extends Component {

    static contextType = AppContext;

    onAdd(callback) {
        this.context.openModal({
            title: "Adicionar Cadastro",
            body: <CadastroForm onExit={() => { this.context.closeModal(); callback() }} onCancel={() => this.context.closeModal()} />,
            params: { size: "xl" }
        });
    }

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/cadastros/" + action.id).then(cadastro =>
                    this.context.openModal({
                        title: "Editar Cadastro", body: <CadastroForm cadastro={cadastro} onExit={() => {
                            this.context.closeModal();
                            this.context.setContent(<CadastroList />)
                        }} onCancel={() => this.context.closeModal()} />, params: { size: 'xl' }
                    })
                )
                break;
            case "Novo Protocolo":
                this.context.request("GET", `/cadastros/${action.id}/debito`).then(({ cadastro, debito, protocolos }) => {
                    console.log({ cadastro, debito, protocolos });
                    if (debito) {
                        window.alert("O cadastro possui débitos pendentes relacionados a multa. Regularize as pendências antes de prosseguir com um novo protocolo.")
                    } else {
                        this.context.openModal({
                            title: "Selecione o Tipo de Protocolo",
                            body: <TipoProtocoloSelect
                                tipo={cadastro.tipo}
                                onSelect={tipo => {
                                    const protocolo = {
                                        tipo,
                                        Cadastro: cadastro
                                    }
                                    this.context.setContent(<ProtocoloForm
                                        protocolo={protocolo}
                                        onCancel={() => this.context.setContent(<CadastroList />)}
                                        onSave={protocolo => this.context.setContent(<ProtocoloList cadastro={cadastro} ProtocoloId={protocolo.id} />)}
                                    />);
                                }} />
                        });
                    }
                });
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/cadastros/" + action.id).finally(callback);
                break;
            case "Resetar Senha":
                if (window.confirm("Deseja realmente resetar a senha para o padrão CNPJ/CPF (somente dígitos)?"))
                    this.context.request("PATCH", "/cadastros/" + action.id + "/reset").finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<>
                <span><FontAwesomeIcon icon={faFolderOpen} /> Pesquisa de Cadastros {this.context.usuario.classe === "cadastro" ? "Vinculados" : ""}</span>
            </>}
            filters={[
                { name: "nome_razaosocial", label: "Nome/Razão Social", span: 6 },
                { name: "nome_fantasia", label: "Nome Fantasia", span: 3 },
                { name: "cnpj_cpf", label: "CPF/CNPJ", span: 3 },
                { name: "endereco", label: "Endereço", span: 4 },
                { name: "$Atividade.cnae$", label: "CNAE", span: 4 },
            ]}
            endpoint="/cadastros"
            buttonLabel={<>
                <FontAwesomeIcon icon={faPlus} /> Adicionar Cadastro
            </>}
            onAdd={this.context.usuario.Recursos.find(r => r === "Adicionar Cadastro") ? callback => this.onAdd(callback) : false}
            onAction={(action, callback) => this.onAction(action, callback)} />;
    }
}

export default CadastroList;