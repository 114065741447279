import { Component } from "react";
import { Container } from "react-bootstrap";
import AppContext from "../../AppContext";

class UsuarioReport extends Component {
    static contextType = AppContext;

    render() {
        return <Container>
            <h2>Relatório de Usuários</h2>
            
        </Container>;
    }
}

export default UsuarioReport;