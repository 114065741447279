import React, { Component } from "react";
import AppContext from "../../AppContext";
import { Button, Col, Container, Form, Image, Row, Table } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import ConfirmacaoModal from "./ConfirmacaoModal";
import SigningPad from "../componentes/SigningPad";


class AtribuicaoRelatorioForm extends Component {
    static contextType = AppContext;

    uploadRef = React.createRef();

    state = {

        id: this.props.atribuicao.id,
        datahora_agendamento: moment(this.props.atribuicao.datahora_agendamento).format("DD/MM/YYYY HH:mm"),
        datahora_execucao: moment(this.props.atribuicao.datahora_execucao).format("DD/MM/YYYY HH:mm"),
        datahora_lancamento: moment(this.props.atribuicao.datahora_lancamento).format("DD/MM/YYYY HH:mm"),

        responsavel_nome: this.props.atribuicao.responsavel_nome,
        responsavel_rg: this.props.atribuicao.responsavel_rg,
        responsavel_contato: this.props.atribuicao.responsavel_contato,
        responsavel_funcao: this.props.atribuicao.responsavel_funcao,

        Protocolo: this.props.atribuicao.Protocolo,
        Usuario: this.props.atribuicao?.Usuario ?? {},
        Funcao: this.props.atribuicao?.Funcao ?? {},

        ProtocoloId: this.props.atribuicao?.ProtocoloId ?? "",
        Pendencias: this.props.atribuicao?.Pendencias ?? [],

        objeto: "",
        justificativa: "",
        referencia: "",
        observacao_medidas: "",
        conclusao: "",
        medidas: [],
        historico: [],
        anexos: [],
        assinatura_responsavel: null,
        assinatura_encarregado: null
    }

    blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result); // remove o prefixo "data:*/*;base64,"
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    async onSign(blob) {
        this.setState({ assinado: true, assinatura_encarregado: await this.blobToBase64(blob) });
    }


    componentDidMount() {

        const { id, ProtocoloId } = this.state;

        this.context.load([
            new Promise((resolve, reject) => this.context.request("GET", `/configuracoes/padroes`).then(padroes => {
                this.setState({
                    objeto: padroes.PADRAO_OBJETO ?? "",
                    justificativa: padroes.PADRAO_JUSTIFICATIVA ?? "",
                    referencia: padroes.PADRAO_REFERENCIA ?? "",
                    observacao_medidas: padroes.PADRAO_OBSERVACAO ?? "",
                    conclusao: padroes.PADRAO_CONCLUSAO ?? "",
                    medidas: padroes.PADRAO_MEDIDAS?.split("\n").map(descricao => ({
                        descricao,
                        exigida: false,
                        existente: false,
                        conforme: false
                    })) ?? []
                }, resolve)
            }).catch(reject)),
            new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${ProtocoloId}/historico`).then(historico => this.setState({ historico }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request(`GET`, `/atribuicoes/${id}/anexos`).then(async anexos => {
                this.setState({ anexos: await Promise.all(anexos.map(async anexo => ({ ...anexo, url: await this.context.request("GET", `/anexos/${anexo.id}/download`).then(blob => URL.createObjectURL(blob)) }))) }, resolve);
            }).catch(reject)),
        ]);
    }

    sendData(acao) {
        const {
            id,
            objeto,
            justificativa,
            referencia,
            observacao_medidas,
            conclusao,
            medidas,
            historico,
            assinatura_encarregado,
            anexos
        } = this.state;

        this.context.request("POST", `/atribuicoes/${id}/relatorio/${acao}`, {
            id,
            objeto,
            justificativa,
            referencia,
            observacao_medidas,
            conclusao,
            medidas,
            historico,
            assinatura_encarregado,
            anexos: anexos.map(a => ({id: a.id, nome: a.nome, descricao: a.descricao}))
        })
            .then(blob => {
                this.download(blob, "Relatório-" + id.toString().padStart(6, '0') + ".pdf");
                if (acao === "salvar") {
                    this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                    this.props.onExit();
                }
            })
            .catch(error => {
                this.context.addToast({ header: "Erro", body: "Falha na gravação dos dados: " + error.toString() });
            })
    }


    submit(acao) { // salvar ou previsualizar
        if (acao === "salvar") {
            this.context.openModal({
                title: "Confirmação",
                body: <ConfirmacaoModal
                    onCancel={() => this.context.closeModal()}
                    onConfirm={() => {
                        this.context.closeModal();
                        this.sendData(acao);
                    }} />
            });
        } else {
            this.sendData(acao);
        }
    }

    download(blob, filename) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    <FontAwesomeIcon icon={Icons.faFile} />&nbsp;Relatório de Atribuição
                </h2>
                <hr />
                <h4 className="mb-3">
                    1. Dados Gerais
                </h4>
                <Row>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Nº do Protocolo</b></Form.Label>
                            <Form.Control disabled value={this.state.ProtocoloId.toString().padStart(6, '0')} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Tipo de Protocolo</b></Form.Label>
                            <Form.Control disabled value={this.state.Protocolo.tipo} />

                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>CNPJ/CPF</b></Form.Label>
                            <Form.Control disabled value={this.state.Protocolo.Cadastro.cnpj_cpf} />
                        </Form.Group>
                    </Col>
                    <Col lg={8} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Nome do Estabelecimento (conforme certificado de licenciamento)</b></Form.Label>
                            <Form.Control disabled value={this.state.Protocolo.nome_estabelecimento} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Unidade de Atendimento</b></Form.Label>
                            <Form.Control disabled value={this.state.Protocolo.Unidade?.sigla} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Endereço Completo</b></Form.Label>
                            <Form.Control rows={4} as="textarea" disabled value={this.state.Protocolo.endereco} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Cidade</b></Form.Label>
                            <Form.Control disabled value={this.state.Protocolo.Cidade?.nome} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Bairro</b></Form.Label>
                            <Form.Control disabled value={this.state.Protocolo.bairro} />
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Latitude</b></Form.Label>
                            <Form.Control disabled type="number" step="0.000001" value={this.state.Protocolo.latitude} />
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Longitude</b></Form.Label>
                            <Form.Control disabled type="number" step="0.000001" value={this.state.Protocolo.longitude} />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    2. Objeto
                </h4>
                <Row>
                    <Col lg={12} className="mb-3">
                        <Form.Control as="textarea" rows={6} value={this.state.objeto} onChange={e => this.setState({ objeto: e.target.value })} />
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    3. Justificativa
                </h4>
                <Row>
                    <Col lg={12} className="mb-3">
                        <Form.Control as="textarea" rows={6} value={this.state.justificativa} onChange={e => this.setState({ justificativa: e.target.value })} />
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    4. Referência Legal
                </h4>
                <Row>
                    <Col lg={12} className="mb-3">
                        <Form.Control as="textarea" rows={6} value={this.state.referencia} onChange={e => this.setState({ referencia: e.target.value })} />
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    5. Medidas e Documentações Exigidas
                </h4>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Medida/Documentação</th>
                            <th style={{ width: "1%", textAlign: "center" }}>Exigida</th>
                            <th style={{ width: "1%", textAlign: "center" }}>Existente</th>
                            <th style={{ width: "1%", textAlign: "center" }}>Conforme</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.medidas.map((medida, key) =>
                            <tr key={key}>
                                <td>{medida.descricao}</td>
                                <td style={{ textAlign: "center" }}>
                                    <Form.Switch checked={medida.exigida} onChange={e => this.setState(state => ({ medidas: state.medidas.map(m => m === medida ? { ...m, exigida: e.target.checked } : m) }))} />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    <Form.Switch checked={medida.existente} onChange={e => this.setState(state => ({ medidas: state.medidas.map(m => m === medida ? { ...m, existente: e.target.checked } : m) }))} />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    <Form.Switch checked={medida.conforme} onChange={e => this.setState(state => ({ medidas: state.medidas.map(m => m === medida ? { ...m, conforme: e.target.checked } : m) }))} />
                                </td>
                            </tr>)}
                    </tbody>
                </Table>
                <Row>
                    <Col lg={12} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>5.1 Observação das Medidas/Documentações Exigidas</b></Form.Label>
                            <Form.Control as="textarea" rows={6} value={this.state.observacao_medidas} onChange={e => this.setState({ observacao_medidas: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    6. Histórico de Cumprimento de Exigências
                </h4>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Exigência</th>
                            {this.state.historico.atribuicoes?.map((atribuicao, key) =>
                                <th style={{ width: "1%", textAlign: "center" }} key={key}>{moment(atribuicao.datahora_execucao).format("DD/MM/YYYY")}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.historico.exigencias?.map((exigencia, key) =>
                            <tr key={key}>
                                <td>{exigencia.exigencia.descricao}</td>
                                {exigencia.atribuicoes?.map((atribuicao, key) =>
                                    <td className={atribuicao.pendente ? "text-danger" : "text-success"} style={{ textAlign: "center" }} key={key}>{atribuicao.pendente ? "Pendente" : "Cumprida"}</td>)}
                            </tr>)}
                    </tbody>
                </Table>
                <hr />
                <h4 className="mb-3">
                    7. Anexos da Atribuição
                </h4>
                <Row>
                    {this.state.anexos.filter(a => a.descricao !== "Assinatura do Responsável").map((anexo, key) =>
                        <Col lg={12} key={key} className="d-flex flex-column align-items-center mb-5">
                            {/\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(anexo.nome)?
                            <Image src={anexo.url} alt="" style={{ maxHeight: 200 }} /> :
                            <a href={anexo.url}>Link para download</a>}
                            <b>Arquivo {(key + 1).toString().padStart(2, '0')} - {anexo.nome}</b>
                            <Form.Control className="text-center" style={{width: "50%"}} placeholder="Descrição do Anexo" value={anexo.descricao} onChange={e => this.setState(state => ({anexos: state.anexos.map(a => a !== anexo? a : {...anexo, descricao: e.target.value})}))}/>
                        </Col>
                    )}
                </Row>
                <hr />
                <h4 className="mb-3">
                    8. Conclusão
                </h4>
                <Row>
                    <Col lg={12} className="mb-3">
                        <Form.Control as="textarea" rows={6} value={this.state.conclusao} onChange={e => this.setState({ conclusao: e.target.value })} />
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    9. Dados do Responsável
                </h4>
                <Row>
                    <Col lg={9} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Nome do Responsável</b></Form.Label>
                            <Form.Control disabled required value={this.state.responsavel_nome} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>RG do Responsável</b></Form.Label>
                            <Form.Control disabled required value={this.state.responsavel_rg} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Contato do Responsável</b></Form.Label>
                            <Form.Control disabled required value={this.state.responsavel_contato} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Função do Responsável</b></Form.Label>
                            <Form.Control disabled required value={this.state.responsavel_funcao} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Assinatura do Responsável</b></Form.Label>
                            <img src={this.state.responsavel_assinatura} />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    10. Dados da Atribuição
                </h4>
                <Row>
                    <Col lg={8} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Encarregado</b></Form.Label>
                            <Form.Control disabled value={this.state.Usuario.nome} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Função</b></Form.Label>
                            <Form.Control disabled value={this.state.Funcao.descricao} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Data/Hora do Agendamento</b></Form.Label>
                            <Form.Control readonly disabled type="text" value={this.state.datahora_agendamento} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Data/Hora da Execução</b></Form.Label>
                            <Form.Control readonly disabled type="text" value={this.state.datahora_execucao} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Data/Hora do Lançamento</b></Form.Label>
                            <Form.Control readonly disabled type="text" value={this.state.datahora_lancamento} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Label><b>Assinatura do Encarregado</b></Form.Label>
                        <SigningPad width={600} height={200} onConfirm={blob => this.onSign(blob)} />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col lg={12} className="d-flex align-items-end">
                        <Button variant="secondary" className="me-auto" onClick={() => this.props.onExit()}>
                            <FontAwesomeIcon icon={Icons.faFastBackward} /> Voltar
                        </Button>
                        <Button variant="dark" className="me-3" onClick={() => this.submit('previsualizar')}>
                            <FontAwesomeIcon icon={Icons.faEye} /> Pré-visualizar
                        </Button>
                        <Button variant="primary" onClick={() => this.submit('salvar')}>
                            <FontAwesomeIcon icon={Icons.faSave} /> Salvar
                        </Button>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default AtribuicaoRelatorioForm;