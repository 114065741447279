import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

class RegistroSaidaList extends Component {
    static contextType = AppContext;

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faArrowUp} /> Pesquisa de Registros de Saída do Regin</div>}
            filters={[
                { name: "WCE_PRA_PROTOCOLO", label: "Protocolo", span: 6 },
                { name: "WCE_STATUS", label: "Status", span: 3 },
                { name: "WCE_MENS_RETORNO", label: "Mensagem", span: 3 },
                { name: "WCE_FEC_INCORP", label: "Saída Inicial", span: 2, type: "datetime-local", op: "gte" },
                { name: "WCE_FEC_INCORP", label: "Saída Final", span: 2, type: "datetime-local", op: "lte" },                
                { name: "WCE_FEC_ATUAL", label: "Atualização Inicial", span: 2, type: "datetime-local", op: "gte" },
                { name: "WCE_FEC_ATUAL", label: "Atualização Final", span: 2, type: "datetime-local", op: "lte" },                
            ]}
            endpoint="/webservices/regin/saidas"/>;
    }
}

export default RegistroSaidaList;