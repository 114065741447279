import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import PrefeituraForm from "../forms/PrefeituraForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity } from "@fortawesome/free-solid-svg-icons";

class PrefeituraList extends Component {
    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.request("GET", "/prefeituras/" + action.id).then(prefeitura =>
                    this.context.setContent(<PrefeituraForm prefeitura={prefeitura} />)
                )
                break;
            case "Resetar":
                if (window.confirm("Deseja realmente resetar a chave da prefeitura com ID " + action.id + "?"))
                    this.context.request("PATCH", "/prefeituras/" + action.id + "/reset").finally(callback);
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/prefeituras/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faCity} /> Pesquisa de Prefeituras</div>}
            filters={[
                { name: "nome", label: "Prefeitura", span: 4 },
                { name: "api_key", label: "API KEY", span: 2 },
                { name: "$Cidade.nome$", label: "Cidade", span: 2 },
            ]}
            endpoint="/prefeituras"
            onAction={(action, callback) => this.onAction(action, callback)}
            onAdd={() => this.context.setContent(<PrefeituraForm />)} />;
    }
}

export default PrefeituraList;