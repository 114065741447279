import React from 'react';
import AppContext from '../AppContext';
import { Toast, ToastContainer } from 'react-bootstrap';

class AppToasts extends React.Component {
    static contextType = AppContext;
    render() {
        return <div
        aria-live="polite"
        aria-atomic="true"
        className="fixed-bottom"
        style={{ minHeight: '240px', pointerEvents: 'none', zIndex: 99999 }}
    >
        <ToastContainer position="bottom-end" className="m-2">
            {this.context.toasts.map((toast, key) =>
                <Toast bg="warning" key={key} onClose={() => this.context.hideToast(toast)} delay={toast.delay ?? 25000} autohide={toast.autohide ?? true}>
                    <Toast.Header className='bg-warning' closeButton>
                        <div className='me-auto'><b>{toast.header}</b></div>
                    </Toast.Header>
                    <Toast.Body>{toast.body}</Toast.Body>
                </Toast>
            )}
        </ToastContainer>
    </div>
    }
}

export default AppToasts;
