import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import CidadeList from "../lists/CidadeList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

class CidadeForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.cidade?.id ?? null,
        nome: this.props.cidade?.nome ?? "",
        uf: this.props.cidade?.uf ?? "",
        codigo_sefa: this.props.cidade?.codigo_sefa ?? "",
        codigo_ibge: this.props.cidade?.codigo_ibge ?? "",
        risp_numero: this.props.cidade?.risp_numero ?? "",
        risp_nome: this.props.cidade?.risp_nome ?? "",
        Bairros: this.props.cidade?.Bairros ?? []
    }

    submit(event) {

        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/cidades/${this.state.id ?? ''}`;

        const {
            nome,
            uf,
            codigo_sefa,
            codigo_ibge,
            risp_numero,
            risp_nome,
            Bairros
        } = this.state;

        this.context.request(method, url, {
            nome,
            uf,
            codigo_sefa,
            codigo_ibge,
            risp_numero,
            risp_nome,
            Bairros
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<CidadeList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Cidade
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<CidadeList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={8} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Nome</b></Form.Label>
                            <Form.Control required value={this.state.nome} onChange={e => this.setState({ nome: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>UF</b></Form.Label>
                            <Form.Control required value={this.state.uf} onChange={e => this.setState({ uf: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Código SEFA</b></Form.Label>
                            <Form.Control required value={this.state.codigo_sefa} onChange={e => this.setState({ codigo_sefa: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Código IBGE</b></Form.Label>
                            <Form.Control required value={this.state.codigo_ibge} onChange={e => this.setState({ codigo_ibge: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Nome da RISP</b></Form.Label>
                            <Form.Control required value={this.state.risp_nome} onChange={e => this.setState({ risp_nome: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Número da RISP</b></Form.Label>
                            <Form.Control type="number" min="0" step="1" required value={this.state.risp_numero} onChange={e => this.setState({ risp_numero: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <h5>Bairros</h5>
                <Table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th style={{width: "1%"}}>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.Bairros.map((bairro, key) =>
                        <tr>
                            <td>
                                <Form.Control required value={bairro.nome} onChange={e => this.setState(state => ({Bairros: state.Bairros.map(b => b === bairro? {...b, nome: e.target.value} : b)}))} />
                            </td>
                            <td>
                                <Button  className="form-control" variant="outline-danger" title="Excluir" onClick={() => this.setState(state => ({Bairros: state.Bairros.filter(b => b !== bairro)}))}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </Button>
                            </td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={2}>
                                <Button className="form-control" variant="outline-success" onClick={() => this.setState(state => ({Bairros: [...state.Bairros, {nome:""}]}))}>
                                    <FontAwesomeIcon icon={faPlus} /> Adicionar Bairro
                                </Button>
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </Form >
        </Container >;
    }
}

export default CidadeForm;