import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import TaxaList from "../lists/TaxaList";
import ReactSelect from "react-select";

class TaxaForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.taxa?.id ?? null,
        descricao: this.props.taxa?.descricao ?? "",
        codigo_receita: this.props.taxa?.codigo_receita ?? "",
        codigo_orgao: this.props.taxa?.codigo_orgao ?? "",
        tipo: this.props.taxa?.tipo ?? "",
        valor_inicial: this.props.taxa?.valor_inicial ?? "",
        valor_excedente: this.props.taxa?.valor_excedente ?? "",
        qtd_minima: this.props.taxa?.qtd_minima ?? "",
        qtd_maxima: this.props.taxa?.qtd_maxima ?? "",
        grupo_f: this.props.taxa?.grupo_f ?? "",
        risco: this.props.taxa?.risco ?? "",
        ModalidadeId: this.props.taxa?.ModalidadeId ?? null,

        modalidades: []
    }

    componentDidMount(){
        this.context.request("GET", "/modalidades/options").then(modalidades => this.setState({modalidades}));
    }

    submit(event) {

        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/taxas/${this.state.id ?? ''}`;

        const {
            descricao,
            codigo_receita,
            codigo_orgao,
            tipo,
            valor_inicial,
            valor_excedente,
            qtd_minima,
            qtd_maxima,
            grupo_f,
            risco,
            ModalidadeId
        } = this.state;

        this.context.request(method, url,{
            descricao,
            codigo_receita,
            codigo_orgao,
            tipo,
            valor_inicial,
            valor_excedente,
            qtd_minima,
            qtd_maxima,
            grupo_f,
            risco,
            ModalidadeId
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<TaxaList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Taxa
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<TaxaList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Descrição</b></Form.Label>
                            <Form.Control required value={this.state.descricao} onChange={e => this.setState({ descricao: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Código da Receita</b></Form.Label>
                            <Form.Control value={this.state.codigo_receita} onChange={e => this.setState({ codigo_receita: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Código do Órgão</b></Form.Label>
                            <Form.Control value={this.state.codigo_orgao} onChange={e => this.setState({ codigo_orgao: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Tipo de Taxa</b></Form.Label>
                            <Form.Select value={this.state.tipo} onChange={e => this.setState({ tipo: e.target.value })}>
                                <option value="">Selecione um tipo de taxa...</option>
                                <option value="Análise de Projeto">Análise de Projeto</option>
                                <option value="Renovação de Licenciamento">Renovação de Licenciamento</option>
                                <option value="Vistoria">Vistoria</option>
                                <option value="ACPS">ACPS</option>
                                <option value="Multa Simples">Multa Simples</option>
                                <option value="Multa de Pessoa Física">Multa de Pessoa Física</option>
                                <option value="Multa de Pessoa Jurídica">Multa de Pessoa Jurídica</option>
                                <option value="Multa Reduzida">Multa Reduzida</option>
                                <option value="Multa Complementar">Multa Complementar</option>
                                <option value="Multa de TAACB">Multa de TAACB</option>
                                <option value="Multa Dobrada">Multa Dobrada</option>
                                <option value="Multa por Descumprimento de TAACB">Multa por Descumprimento de TAACB</option>
                                <option value="Credenciamento de Pessoa Física">Credenciamento de Pessoa Física</option>
                                <option value="Credenciamento de Pessoa Jurídica">Credenciamento de Pessoa Jurídica</option>
                                <option value="Renovação de Credenciamento de Pessoa Física">Renovação de Credenciamento de Pessoa Física</option>
                                <option value="Renovação de Credenciamento de Pessoa Jurídica">Renovação de Credenciamento de Pessoa Jurídica</option>
                                <option value="Anotação">Anotação</option>
                                <option value="Outros Serviços">Outros Serviços</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Risco</b></Form.Label>
                            <Form.Select value={this.state.risco} onChange={e => this.setState({ risco: e.target.value })}>
                                <option value="">Selecione um risco...</option>
                                <option value="Nulo">Nulo</option>
                                <option value="Baixo">Baixo</option>
                                <option value="Médio">Médio</option>
                                <option value="Alto">Alto</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Grupo F</b></Form.Label>
                            <Form.Select value={this.state.grupo_f} onChange={e => this.setState({ grupo_f: e.target.value })}>
                                <option value="">Selecione uma opção...</option>
                                <option value="Inclusivo">Inclusivo</option>
                                <option value="Exclusivo">Exclusivo</option>
                                <option value="Proibido">Proibido</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Valor Inicial</b></Form.Label>
                            <Form.Control type="number" step="0.001" value={this.state.valor_inicial} onChange={e => this.setState({ valor_inicial: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Valor Excedente</b></Form.Label>
                            <Form.Control type="number" step="0.001" value={this.state.valor_excedente} onChange={e => this.setState({ valor_excedente: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Quantidade Mínima</b></Form.Label>
                            <Form.Control type="number" step="0.001" value={this.state.qtd_minima} onChange={e => this.setState({ qtd_minima: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Quantidade Máxima</b></Form.Label>
                            <Form.Control type="number" step="0.001" value={this.state.qtd_maxima} onChange={e => this.setState({ qtd_maxima: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Modalidade de Credenciamento</b></Form.Label>
                            <ReactSelect
                                options={this.state.modalidades}
                                value={this.state.modalidades.filter(option => this.state.ModalidadeId === option.value)}
                                onChange={option => this.setState({ ModalidadeId: option?.value ?? null })}
                                placeholder="Selecione uma modalidade ..."
                                noOptionsMessage={() => "Sem opções"}
                                isClearable
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default TaxaForm;