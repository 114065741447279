import React from 'react';
import AppContext from '../AppContext';
import AppMenu from './AppMenu';
import AppHeader from './AppHeader';
import AppToasts from './AppToasts';
import AppModal from './AppModal';
import AppLoading from './AppLoading';

class AppContainer extends React.Component {
    static contextType = AppContext;
    render() {
        return <div id="app-container">
            {this.context.usuario ? <AppMenu /> : null}
            <div className='flex-fill d-flex flex-column mb-5'>
                {this.context.usuario ? <AppHeader /> : null}
                <div id="app-content" className='my-3' key={this.context.contentKey}>
                    {this.context.content}
                </div>
            </div>
            <AppToasts />
            <AppModal />
            <AppLoading />
        </div>
    }
}

export default AppContainer;
