import React from 'react';
import AppContext from '../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class AppLoading extends React.Component {
    static contextType = AppContext;
    render() {
        return this.context.loading && <div style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            display: 'flex',
            flexDirection: 'column',
            background: "rgba(0,0,0,.5)",
            color: 'white',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <FontAwesomeIcon icon={faSpinner} spin size="5x" />
            Carregando...
        </div>;
    }
}

export default AppLoading;
