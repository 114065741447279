import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import OcupacaoList from "../lists/OcupacaoList";

class OcupacaoForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.ocupacao?.id ?? null,
        descricao: this.props.ocupacao?.descricao ?? "",
        divisao: this.props.ocupacao?.divisao ?? "",
        risco: this.props.ocupacao?.risco ?? "",
        carga: this.props.ocupacao?.carga ?? "0",
        densidade: this.props.ocupacao?.densidade ?? "0",
    }

    submit(e) {
        e.preventDefault();
        const method = this.state.id ? "PUT" : "POST";
        const url = `/ocupacoes/${this.state.id ?? ''}`;
        const data = this.state;
        this.context.request(method, url, data)
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<OcupacaoList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form mt-3" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Ocupação
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<OcupacaoList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={9} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Descrição</b></Form.Label>
                            <Form.Control required value={this.state.descricao} onChange={e => this.setState({descricao: e.target.value})}/>
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Divisão</b></Form.Label>
                            <Form.Control required value={this.state.divisao} onChange={e => this.setState({divisao: e.target.value})}/>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Risco</b></Form.Label>
                            <Form.Select required value={this.state.risco} onChange={e => this.setState({risco: e.target.value})}>
                                <option value="">Selecione...</option>
                                <option>Nulo</option>
                                <option>Baixo</option>
                                <option>Médio</option>
                                <option>Alto</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Carga Incêndio (MJ/m²)</b></Form.Label>
                            <Form.Control type="number" min="0" step="1" required value={this.state.carga} onChange={e => this.setState({carga: e.target.value})}/>
                        </Form.Group>
                    </Col>                    
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Densidade (pessoas/m²)</b></Form.Label>
                            <Form.Control type="number" min="0" step="1" required value={this.state.densidade} onChange={e => this.setState({densidade: e.target.value})}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Form >
        </Container >;
    }
}

export default OcupacaoForm;