import { Component } from "react";
import AppContext from "../../../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import FuncaoList from "../lists/FuncaoList";

class FuncaoForm extends Component {
    static contextType = AppContext;

    state = {
        id: this.props.funcao?.id ?? null,
        descricao: this.props.funcao?.descricao ?? "",  
        relatorio: this.props.funcao?.relatorio ?? false,     
        extraordinaria: this.props.funcao?.extraordinaria ?? false,
        credenciamento: this.props.funcao?.credenciamento ?? false,
        auxiliar: this.props.funcao?.auxiliar ?? false,
        tipo: this.props.funcao?.tipo ?? "",
        peso: this.props.funcao?.peso ?? "1"
    }

    submit(event) {
        
        event.preventDefault();

        const method = this.state.id ? "PUT" : "POST";
        const url = `/funcoes/${this.state.id ?? ''}`;

        const {
            id,
            descricao,
            relatorio,
            extraordinaria,
            credenciamento,
            auxiliar,
            tipo,
            peso,

        } = this.state;

        this.context.request(method, url, {
            id,
            descricao,
            relatorio,
            extraordinaria,
            credenciamento,
            auxiliar,
            tipo,
            peso
        })
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<FuncaoList />);
            });
    }

    render() {
        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex">
                    Cadastro de Função
                    <Button variant="secondary" className="ms-auto mx-2" onClick={() => this.context.setContent(<FuncaoList />)}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" className="me-2">
                        Gravar
                    </Button>
                </h2>
                <hr />
                <Row>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Descrição</b></Form.Label>
                            <Form.Control required value={this.state.descricao} onChange={e => this.setState({ descricao: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Tipo</b></Form.Label>
                            <Form.Control type="text" required value={this.state.tipo} onChange={e => this.setState({ tipo: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Peso</b></Form.Label>
                            <Form.Control type="number" min="0" step="0.01" required value={this.state.peso} onChange={e => this.setState({ peso: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Switch label={<b>Esta função será considerada como auxiliar?</b>} checked={this.state.auxiliar} onChange={e => this.setState({ auxiliar: e.target.checked })}>                                
                            </Form.Switch>                            
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Switch label={<b>Esta função exigirá a confecção do relatório?</b>} checked={this.state.relatorio} onChange={e => this.setState({ relatorio: e.target.checked })}>                                
                            </Form.Switch>                            
                        </Form.Group>
                    </Col> 
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Switch label={<b>Esta função será considerada no cálculo de extraordinárias?</b>} checked={this.state.extraordinaria} onChange={e => this.setState({ extraordinaria: e.target.checked })} />
                        </Form.Group>
                    </Col>                
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Switch label={<b>Esta função será considerada como credenciamento?</b>} checked={this.state.credenciamento} onChange={e => this.setState({ credenciamento: e.target.checked })} />
                        </Form.Group>
                    </Col>                
                </Row>
            </Form >
        </Container >;
    }
}

export default FuncaoForm;