import { faArrowDown, faArrowUp, faBoltLightning, faBook, faBoxesStacked, faBriefcase, faBullhorn, faCertificate, faCity, faCog, faCogs, faComputer, faCubesStacked, faDollarSign, faFileInvoice, faFireExtinguisher, faFireFlameCurved, faGlobeAmericas, faHandHoldingDollar, faHouseChimney, faIdCard, faLandmark, faLayerGroup, faMoneyBill1Wave, faQuestion, faStar, faTriangleExclamation, faUserCheck, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import AppContext from "../../AppContext";
import AvisoList from "./lists/AvisoList";
import UsuarioList from "./lists/UsuarioList";
import AtividadeList from "./lists/AtividadeList";
import UnidadeList from "./lists/UnidadeList";
import OcupacaoList from "./lists/OcupacaoList";
import FuncaoList from "./lists/FuncaoList";
import CidadeList from "./lists/CidadeList";
import ExigenciaList from "./lists/ExigenciaList";
import InfracaoList from "./lists/InfracaoList";
import ModalidadeList from "./lists/ModalidadeList";
import PerguntaList from "./lists/PerguntaList";
import RevendaglpList from "./lists/RevendaglpList";
import MedidaList from "./lists/MedidaList";
import TaxaList from "./lists/TaxaList";
import ModeloList from "./lists/ModeloList";
import CursoList from "./lists/CursoList";
import ConfiguracaoList from "./lists/ConfiguracaoList";
import PerfilList from "./lists/PerfilList";
import PagamentoList from "./lists/PagamentoList";
import LoteList from "./lists/LoteList";
import RegistroEntradaList from "./lists/RegistroEntradaList";
import RegistroSaidaList from "./lists/RegistroSaidaList";
import PrefeituraList from "./lists/PrefeituraList";

class AdministracaoPage extends Component {
    static contextType = AppContext;

    render() {
        return <Container>
            <h2><FontAwesomeIcon icon={faCog} /> Administração</h2>
            <Row>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<AvisoList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faTriangleExclamation} size="3x" />
                            Avisos
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<UsuarioList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faUsers} size="3x" />
                            Usuários
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<PerfilList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faUserCheck} size="3x" />
                            Perfis
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<UnidadeList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faLandmark} size="3x" />
                            Unidades
                        </Card.Body>
                    </Card>
                </Col>                
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<OcupacaoList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faHouseChimney} size="3x" />
                            Ocupações
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<AtividadeList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faBriefcase} size="3x" />
                            Atividades Econômicas
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<RevendaglpList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faFireFlameCurved} size="3x" />
                            Revenda de GLP
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<CidadeList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faGlobeAmericas} size="3x" />
                            Cidades e Bairros
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<TaxaList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faHandHoldingDollar} size="3x" />
                            Taxas
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<ModeloList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faCertificate} size="3x" />
                            Modelos de Certificados
                        </Card.Body>
                    </Card>
                </Col> 
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<PerguntaList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faQuestion} size="3x" />
                            Perguntas do Questionário
                        </Card.Body>
                    </Card>
                </Col>               
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<ExigenciaList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faBoltLightning} size="3x" />
                            Exigências
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<MedidaList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faFireExtinguisher} size="3x" />
                            Medidas de Proteção
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<FuncaoList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faStar} size="3x" />
                            Funções de Atribuição
                        </Card.Body>
                    </Card>
                </Col>                
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<InfracaoList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faBullhorn} size="3x" />
                            Infrações
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<ModalidadeList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faIdCard} size="3x" />
                            Modalidades de Credenciamento
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<CursoList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faBook} size="3x" />
                            Cursos e Treinamentos
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<LoteList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faLayerGroup} size="3x" />
                            Lotes de Pagamento
                        </Card.Body>
                    </Card>
                </Col>                
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<PagamentoList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faMoneyBill1Wave} size="3x" />
                            Pagamentos
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<RegistroEntradaList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faArrowDown} size="3x" />
                            Entradas do REGIN
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<RegistroSaidaList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faArrowUp} size="3x" />
                            Saídas do REGIN
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<PrefeituraList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faCity} size="3x" />
                            Integração Prefeituras
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="mt-3">
                    <Card className="card-item h-100" onClick={() => this.context.setContent(<ConfiguracaoList />)}>
                        <Card.Body className="justify-content-center text-center d-flex flex-column align-items-center">
                            <FontAwesomeIcon icon={faCogs} size="3x" />
                            Configurações
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }
}

export default AdministracaoPage;