import { Component } from "react";
import { Container } from "react-bootstrap";
import AppContext from "../../AppContext";

class RegiaoReport extends Component {
    static contextType = AppContext;

    render() {
        return <Container>
            <h2>Relatório de Regiões</h2>            
        </Container>;
    }
}

export default RegiaoReport;