import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import UnidadeForm from "../forms/UnidadeForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";

class UnidadeList extends Component {
    static contextType = AppContext;

    onAction(action) {

        switch (action.name) {
            case "Editar Regiões":
                this.context.request("GET", "/unidades/" + action.id).then(unidade =>
                    this.context.setContent(<UnidadeForm unidade={unidade} />)
                )
                break;
            default:
                break;
        }
        
    }

    render() {
        return <Datatable 
        title={<div><FontAwesomeIcon icon={faLandmark} /> Pesquisa de Unidades</div>}
            filters={[
                {name: "nome", label: "Unidade", span: 6},
                {name: "sigla", label: "Sigla", span: 2}                                            
            ]}
            endpoint="/unidades"
            onAction={action => this.onAction(action)}/>;
    }
}

export default UnidadeList;