import { Component } from "react";
import AppContext from "../../../AppContext";
import Datatable from "../../componentes/Datatable";
import PerfilForm from "../forms/PerfilForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";

class PerfilList extends Component {

    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "Ver Detalhes":
                this.context.request("GET", "/perfis/" + action.id).then(perfil =>
                    this.context.openModal({
                        title: "Perfil", body: <PerfilForm perfil={perfil} onCancel={() => this.context.closeModal()} onSave={() => {
                            this.context.closeModal();
                            callback();
                        }} />
                    })
                )
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com id " + action.id + "?")) {
                    this.context.request("DELETE", `/perfis/${action.id}`).then(() => {
                        this.context.addToast({ header: "Sucesso", body: "Dado excluído com sucesso!" });
                        callback();
                    })
                }
                break;
            default:
                break;
        }
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faUserCheck} /> Pesquisa de Perfis</div>}
            filters={[
                { name: "nome", label: "Nome", span: 8, order: "nome" },
            ]}
            endpoint="/perfis"
            onAdd={callback => this.context.openModal({
                title: "Adicionar Perfil", body: <PerfilForm  onCancel={() => this.context.closeModal()} onSave={() => {
                    this.context.closeModal();
                    callback();
                }} />
            })}
            onAction={(action, callback) => this.onAction(action, callback)}
        />;
    }
}

export default PerfilList;